<script lang="ts">
	import {
		push_screen,
		pop,
		yw_thread_id,
		ThreadId,
		yw_progress,
		yw_chain,
		yw_account,
yw_task,
	} from '#/app';

	import type {
		Account,
		Tag,
	} from '#/objects';

	import Address from '#/ui/Address.svelte';

	import {
		Screen,
		Field,
		Info,
		Tags,
		IconEditor,
	 } from '#/ui';
import { Tasks } from '#/component/Simulator.svelte';

	export let account: Account;

	let accountName = account.def.label || 'Account';
	let a_tags: Tag[] = [];

	let dm_finish: HTMLElement;

	 function finish() {
		Object.assign($yw_account.def, {
			label: accountName,
			tagRefs: a_tags.map(k => k.def.iri),
		});

		 $yw_progress = [0, 4];

		 $yw_thread_id = ThreadId.TOKENS;

		 if(Tasks.ACCOUNT === $yw_task) {
			 $yw_task = -$yw_task;
		 }
	 }
</script>

<Screen progress={[4, 4]} leaves form
	on:submit={() => dm_finish?.click()}
>
	<h3>
		Edit Account
	</h3>

	<Field key="profile-icon" name="Profile icon">
		<IconEditor intent='person' iconRef={$yw_account.def.iconRef} />
	</Field>

	<Field key="account-name" name="Name">
		<input id="account-name" type="text" bind:value={accountName}>
	</Field>

	<Field key="account-address" name="Public address">
		<Info key="account-address">
			<Address copyable address={account.address($yw_chain)} />
		</Info>
	</Field>

	<Field key="account-tags" name="Add tags">
		<Tags editable bind:tags={a_tags} />
	</Field>

	<div class="action-line">
		<button
			class="primary"
			disabled={!accountName.trim().length}
			bind:this={dm_finish}
			on:click={() => finish()}
		>
			Finish
		</button>
	</div>
</Screen>
