<script lang="ts">
	import { F_NOOP } from "#/util/belt";

	import {
		ThreadId,
		yw_menu_expanded, yw_thread_id, yw_vendor_menu,
	} from "#/app";

	import {
		Icon,
	} from "#/objects";

	import {
		Put,
	 } from '#/ui';

	import SX_CONTACTS from '@material-design-icons/svg/outlined/supervisor_account.svg?raw';
	import SX_CHAINS from '@material-design-icons/svg/outlined/mediation.svg?raw';
	import SX_ACCOUNTS from '@material-design-icons/svg/outlined/account_circle.svg?raw';
	import SX_TAGS from '@material-design-icons/svg/outlined/bookmarks.svg?raw';
	import SX_CONNECTIONS from '@material-design-icons/svg/outlined/account_tree.svg?raw';
	import SX_SETTINGS from '@material-design-icons/svg/outlined/settings.svg?raw';
	import SX_LOGOUT from '@material-design-icons/svg/outlined/sensor_door.svg?raw';
	import SX_CLOSE from '@material-design-icons/svg/outlined/close.svg?raw';

	import SX_TITLE from '#/asset/vendor/starshell-title.svg?raw';

	// $yw_menu_expanded

	interface Item {
		click: VoidFunction;
		label: string;
		icon: Icon;
	}

	// let s_latency = '120ms';

	let n_block = 2597300;
	let xt_when = 1647725760000;
	let xt_bt = 6410;

	n_block = n_block + Math.round((Date.now() - xt_when) / xt_bt);

	setInterval(() => {
		n_block += 1;
	}, xt_bt);

	setInterval(() => {
		xt_bt += (Math.random() * 260) - 130;
	}, xt_bt);

</script>

<style lang="less">
	@import '../style/util.less';

	:root {
		--bar-width: 78.8%;
		--animation-duration: 1s;
		--animation-easing: var(--ease-out-quick);
	}

	@keyframes fade {
		0% {
			background-color: transparent;
		}

		100% {
			background-color: rgba(0, 0, 0, 0.8);
		}
	}

	// @keyframes slide {
	// 	0% {
	// 		left: calc(0% - var(--bar-width));
	// 	}

	// 	100% {
	// 		left: 0%;
	// 	}
	// }	

	@keyframes opacity {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}


	@keyframes offscreen {
		0% {
			top: 0;
		}

		100% {
			top: var(--app-window-height);
		}
	}

	.absolute(@dims: '') {
		position: absolute;
		top: 0;
		left: 0;

		width: if('' = @dims, var(--app-window-width), @dims);
		height: if('' = @dims, var(--app-window-height), @dims);
	}

	.vendor-menu {
		--item-padding: 30px;

		.absolute();
		.font(regular);
		z-index: 1001;
		user-select: none;
		// color: var(--theme-color-black);

		>.backdrop {
			.absolute(100%);
			background-color: rgba(0, 0, 0, 0.8);
			transition: background-color var(--animation-duration) var(--ease-out-expo);
		}

		>.bar {
			position: absolute;
			top: 0;
			width: var(--bar-width);
			height: 100%;
			// background-color: var(--theme-color-primary);
			background-color: var(--theme-color-bg);
			left: 0%;
			opacity: 1;
			transition: left var(--animation-duration) var(--animation-easing),
				opacity calc(var(--animation-duration) / 3) ease-out;

			>.menu {
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				height: 100%;

				ul {
					margin: 0;
					padding: 0;

					>li {
						list-style: none;
						padding: 13px 0;
						padding-left: var(--item-padding);
						cursor: pointer;

						>* {
							vertical-align: middle;
						}

						>.icon {
							--icon-diameter: 24px;
							padding: 0;
							padding-right: calc(var(--item-padding) / 2);
						}
					}

					&.items {
						.icon {
							--icon-color: var(--theme-color-black);
						}
					}

					&.session {
						padding: calc(var(--item-padding) / 2) 0;

						.icon {
							--icon-color: var(--theme-color-text-med);
						}
					}
				}

				>.main {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					padding-top: 15%;

					padding-left: 1em;

					>* {
						border-bottom: 1px solid var(--theme-color-border);
					}

					>.app {
						margin-top: 25%;
						color: var(--theme-color-text-med);
						.font(tiny);
						padding: 16px 0;
					}

					>.info {
						padding: 16px 0;

						.name {
							color: var(--theme-color-text-med);
							.font(tiny);
						}

						.value {
							padding-top: 4px;
						}
					}
				}

				>.bottom {
					flex: 0;
				}
			}
		}

		&.collapsed {
			pointer-events: none;
			top: 0;
			animation: offscreen var(--animation-duration) steps(2, jump-none) both;
			
			>.backdrop {
				background-color: rgba(0, 0, 0, 0);
			}

			>.bar {
				left: calc(0% - var(--bar-width));
				opacity: 0.1;
			}
		}


		hr {
			margin: 0 var(--item-padding);
			border: none;
			border-top: 1px solid var(--theme-color-border);
		}

		// .close-dark {
		// 	position: absolute;
		// 	top: 0;
		// 	right: 0;
		// 	margin: 10px;
		// 	padding: 12px;
		// 	cursor: pointer;
		// 	--icon-diameter: 24px;
		// 	--icon-color: var(--theme-color-black);

		// 	outline: 1px solid var(--theme-color-border);
		// 	border-radius: 0px;
		// 	transition: border-radius 650ms var(--ease-out-expo);
		// 	pointer-events: all;

		// 	&::before {
		// 		--occlusion-thickness: 4px;

		// 		content: '';
		// 		position: absolute;
		// 		top: calc(var(--occlusion-thickness) / 2);
		// 		left: calc(var(--occlusion-thickness) / 2);
		// 		width: calc(100% - var(--occlusion-thickness));
		// 		height: calc(100% - var(--occlusion-thickness));
		// 		outline: var(--occlusion-thickness) solid var(--theme-color-primary);
		// 		box-sizing: border-box;
		// 		pointer-events: none;
		// 	}

		// 	&:hover {
		// 		border-radius: 22px;
		// 	}
		// }

		.close {
			position: absolute;
			top: 0;
			right: 0;
			margin: 10px;
			padding: 12px;
			cursor: pointer;
			--icon-diameter: 24px;
			--icon-color: var(--theme-color-primary);

			outline: 1px solid var(--theme-color-border);
			border-radius: 0px;
			transition: border-radius 650ms var(--ease-out-expo);
			pointer-events: all;

			&::before {
				--occlusion-thickness: 4px;

				content: '';
				position: absolute;
				top: calc(var(--occlusion-thickness) / 2);
				left: calc(var(--occlusion-thickness) / 2);
				width: calc(100% - var(--occlusion-thickness));
				height: calc(100% - var(--occlusion-thickness));
				outline: var(--occlusion-thickness) solid var(--theme-color-bg);
				box-sizing: border-box;
				pointer-events: none;
			}

			&:hover {
				border-radius: 22px;
			}
		}
		
	}
</style>

<div
	class="vendor-menu"
	class:collapsed={!$yw_vendor_menu}
>
	<div class="backdrop clickable"
		on:click={() => $yw_vendor_menu = false}
	/>

	<div class="bar">
		<div class="close icon clickable" on:click={() => $yw_vendor_menu = false}>
			{@html SX_CLOSE}
		</div>

		<div class="menu">
			<div class="main">
				<div class="app">
					<div>
						{@html SX_TITLE}
					</div>

					<div>
						v0.0.1
					</div>
				</div>

				<!-- <div>
					Current dApp
				</div> -->

				<!-- <div>
					domain: secretswap.io
				</div> -->

				<div class="info">
					<div class="name">
						Network Status
					</div>

					<div class="value">
						Online
					</div>
				</div>

				<div class="info">
					<div class="name">
						Current Block
					</div>

					<div class="value">
						#{n_block}
					</div>
				</div>

				<div class="info">
					<div class="name">
						Average Block Time
					</div>

					<div class="value">
						{(xt_bt / 1000).toFixed(2)} seconds
					</div>
				</div>

				<!-- <div class="info">
					<div class="name">
						Next block in
					</div>

					<div class="value">
						 seconds
					</div>
				</div> -->

			</div>
		</div>
	</div>
</div>