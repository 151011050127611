<script lang="ts">
	import type {
		Account,
		Contact,
		Icon,
	} from '#/objects';

	import { H_ICONS } from "#/sim/data";
	import Put from "./Put.svelte";

	export let contact: Contact | null = null;
	export let account: Account | null = null;

	export let iconRef: Icon.Ref | '' = contact?.def.iconRef || account?.def.iconRef || '';
	export let name = contact?.def.label || account?.def.label || '';

	const k_icon = H_ICONS[iconRef as Icon.Ref];

	export let circular = false;
	const s_class = circular? '': 'square';

	export let genStyle = '';

	export let rootStyle = '';
</script>

<style lang="less">
	@import '../style/util.less';

	.icon {
		&.default {
			// background-color: var(--theme-color-graysoft);
			background-color: var(--theme-color-bg);
			background: radial-gradient(ellipse farthest-side at bottom right, darken(@theme-color-black, 50%), var(--theme-color-bg));
			outline: 1px solid var(--theme-color-primary);
		}

		.icon-dom {
			color: var(--theme-color-text-light);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 30px;
		}
	}
</style>

<span class="pfp {s_class} icon" class:default={!k_icon} style={rootStyle}>
	{#if k_icon}
		<Put element={k_icon.render()} />
	{:else}
		<span class="icon-dom" style={genStyle}>
			{name[0] || ''}
		</span>
	{/if}
</span>