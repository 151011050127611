<script lang="ts">
	import StarSelect from "./StarSelect.svelte";

	import { oderaf } from "#/util/belt";
	import { H_CHAINS, H_TOKENS } from "#/sim/data";

	import type {
		Token,
	} from "#/objects";

	import { yw_chain } from "#/app";

	export let token: Token | null = null;
	const k_token: Token = token as Token;

	const si_chain = $yw_chain?.def.id || '*';

	const a_tokens = oderaf(H_TOKENS, (_, k_token) => {
		const k_chain = H_CHAINS[k_token.def.chainRef];

		if(si_chain === k_chain.def.id) {
			return [{
				value: k_token.def.iri,
				label: `${k_token.def.address} ${k_token.def.symbol} ${k_token.def.label}`,
				primary: k_token.def.symbol,
				secondary: k_token.def.label,
				token: k_token,
			}];
		}

		return [];
	});

	let g_token_select = k_token? a_tokens.find(g => g.value === k_token.def.iri): void 0;

	$: {
		token = g_token_select?.token || null;
	}

</script>

<style lang="less">
	@import '../style/util.less';

</style>

<div class="asset">
	<StarSelect id="asset-select"
		placeholder="Select asset"
		items={a_tokens}
		bind:value={g_token_select}
	/>
</div>