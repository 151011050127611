<script lang="ts">
	import { goto_screen, pop, push_screen, restart, ThreadId, yw_chain, yw_family, yw_task, yw_thread_id } from "#/app";

	import { microtask, ode } from '#/util/belt';

	import {
		Icon,
		Contact,
	} from '#/objects';

	import { H_ACCOUNTS, H_CHAINS, H_CONTACTS, H_FAMILIES, H_TAGS } from "#/sim/data";

	import {
		Screen,
		Header,
		Field,
		Tags,
		Info,
		IconEditor,
	} from "#/ui";

	import Contacts from "./Contacts.svelte";
	import ContactView from "./ContactView.svelte";
	import type { SvelteComponent } from "svelte";
import { Tasks } from "#/component/Simulator.svelte";


	export let contact: Contact | null = null;
	export const familyId = contact?.def.familyRef? H_FAMILIES[contact.def.familyRef].def.id: $yw_family.def.id;
	export const contactId = contact?.def.pubkey || '.new';

	let s_name = contact?.def.label || '';
	let s_pubkey = contact?.def.pubkey;
	let sa_addr = s_pubkey? ($yw_chain.def.bechPrefix+'1'+s_pubkey): '';
	let s_notes = '';

	let s_err_name = '';
	let s_err_address = '';

	const R_BECH = /^(\w+)1([a-zA-HJ-NP-Z0-9]{38})$/;

	function pubkey_from_addr(sa_address: string, b_show_err=false): string {
		const m_bech = R_BECH.exec(sa_address);
		if(!m_bech) {
			if(b_show_err) {
				s_err_address = 'Invalid Bech32 address';
			}
			return '';
		}

		const [, s_chain, s_pubkey_local] = m_bech;

		let k_chain_match = null;
		for(const [, k_chain] of ode(H_CHAINS)) {
			if(k_chain.def.bechPrefix === s_chain) {
				k_chain_match = k_chain;
				break;
			}
		}

		if(b_show_err) {
			if(!k_chain_match) {
				s_err_address = `No Cosmos SDK chains matched '${s_chain}'`;
			}
			else {
				s_err_address = '';
			}
		}

		return s_pubkey = s_pubkey_local;
	}

	$: b_form_valid = !!(s_name && pubkey_from_addr(sa_addr));
	let c_show_validations = 0;

	$: {
		if(c_show_validations) {
			s_err_name = s_name? '': 'Name must not be empty';
			pubkey_from_addr(sa_addr, true);
		}
	}

	function autofill_address() {
		if(!contact) {
			sa_addr = `secret10mtm48ul5mcgjj4hm0a4j3td4l5pt590erl${Object.values(H_CONTACTS).length.toString(16).padStart(3, '0')}`;
		}
	}

	let y_screen: SvelteComponent;
	function save() {
		if(!b_form_valid) {
			c_show_validations++;
		}
		else if(contact) {
			Object.assign(contact.def, {
				label: s_name,
				pubkey: s_pubkey,
				iconRef: p_icon,
				tagRefs: a_tags.map(k => k.def.iri),
			});

			restart();
			setTimeout(() => {
				try {
					y_screen.$destroy();
				} catch(e) {}
				contact = H_CONTACTS[contact!.def.iri];
				push_screen(ContactView, {
					contact,
				});
			}, 5);
		}
		else {
			const gd_contact = Contact.Def.fromConfig({
				label: s_name,
				familyRef: $yw_family.def.iri,
				pubkey: s_pubkey!,
				iconRef: p_icon,
				tagRefs: a_tags.map(k => k.def.iri),
			});

			const k_contact = H_CONTACTS[gd_contact.iri] = new Contact(gd_contact);

			restart();

			// immediately open new contact
			push_screen(ContactView, {
				contact: k_contact,
			});

			if(Tasks.NEW_CONTACT === $yw_task) {
				setTimeout(() => {
					$yw_task = -$yw_task;
				}, 1400);
			}
		}
	}

	let a_tags = contact?.def.tagRefs.map(p => H_TAGS[p]) || [];

	let p_icon: Icon.Ref = contact?.def.iconRef || '' as Icon.Ref;
</script>

<style lang="less">
	@import '../style/util.less';

	#chain-family {
		:global(&) {
			flex: 1;
			align-items: baseline;
			.font(tiny);
			color: var(--theme-color-text-med);

			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
</style>

<Screen bind:this={y_screen} leaves>
	<Header
		plain pops
		name="{contact? 'Edit': 'Add New'} Contact"
	/>

	<Field
		key="contact-pfp"
		name="Profile Icon"
	>
		<IconEditor intent='person' bind:iconRef={p_icon} bind:name={s_name} />
	</Field>

	<Field
		key="chain-family"
		name="Chain Family"
	>
		<Info key="chain-family">
			<style lang="less">
				@import '../style/util.less';

				.title {
					.font(regular);
					color: var(--theme-color-text-light);
				}

				.examples {
					margin-left: 0.5em;
				}
			</style>

			<span class="title">
				{$yw_family.def.label}
			</span>

			<span class="examples">
				({Object.values(H_CHAINS).filter(k => k.def.familyRef === $yw_family.def.iri).map(k => k.def.bechPrefix+'1').join(', ')})
			</span>
		</Info>
	</Field>


	<Field
		key="contact-name"
		name="Name"
	>
		<input class:invalid={s_err_name} type="text" spellcheck="false" bind:value={s_name} placeholder="Enter a name">

		{#if s_err_name}
			<span class="validation-message">
				{s_err_name}
			</span>
		{/if}
	</Field>

	<Field
		key="contact-address"
		name="Address"
	>
		<input spellcheck="false" class:invalid={s_err_address} class="address" type="text" bind:value={sa_addr} placeholder="{$yw_chain.def.bechPrefix}..."
			on:focus={() => autofill_address()}
		>

		{#if s_err_address}
			<span class="validation-message">
				{s_err_address}
			</span>
		{/if}
	</Field>

	<Field
		key="contact-notes"
		name="Secure Notes"
	>
		<textarea bind:value={s_notes} placeholder=""></textarea>
	</Field>

	<hr>

	<h3>
		{contact? 'Edit': 'Add'} Tags
	</h3>

	<Tags editable bind:tags={a_tags} />

	<div class="action-line">
		<button on:click={() => pop()}>
			Back
		</button>

		<button class="primary" on:click={() => save()} readonly={!b_form_valid}>
			{contact? 'Save': 'Add'}
		</button>
	</div>
</Screen>