<script lang="ts">
	import SX_CHECKED from '#/asset/nav/checked.svg?raw';
	import SX_UNCHECKED from '#/asset/nav/unchecked.svg?raw';

	export let id: string;
	export let containerClass = '';
	export let checked = false;
	export let disableHandler = false;
</script>

<style lang="less">
	fieldset {
		display: flex;
		gap: 14px;
		margin: 0;
		padding: 0;
		border: 0;

		.checkbox {

			.icon {
				--icon-diameter: 18px;
				--icon-color: var(--theme-color-primary);
				vertical-align: middle;
			}
		}
	}
</style>


<fieldset class="{containerClass} clickable" on:click={disableHandler? () => {}: () => checked = !checked}>
	<span class="checkbox">
		<input id={id} type="checkbox" hidden bind:checked={checked}>
		<span class="icon">
			{@html checked? SX_CHECKED: SX_UNCHECKED}
		</span>
	</span>

	{#if $$slots.default}
		<label class="clickable" for={id} on:click={(d_event) => d_event.stopImmediatePropagation()}>
			<slot />
		</label>
	{/if}
</fieldset>