import {
	Addressable,
	AddressType,
	ClassType,
	Definable,
} from './_core';

import {
	type Tag,
	type Chain,
	type Txn,
	Icon,
} from '#/objects';
import type { Family } from './family';

type ContactRef =  Family.MemberRef<ClassType.CONTACT>;

enum ContactType {
	UNKNOWN = 0,
	PERSON = 1,
	CONTRACT = 2,
}

type ContactPath = `${Family.Path}/contacts/${string}`;

interface ContactDef extends Icon.Refable<ContactPath> {
	class: ClassType.CONTACT,
	type: ContactType;
	familyRef: Family.Ref;
	pubkey: string;
	locked: boolean;
}

interface ContactConfig {
	label: string;
	pubkey: string;
	familyRef: Family.Ref;
	tagRefs?: Tag.Ref[],
	iconRef?: Icon.Ref;
	type?: ContactType;
	locked?: boolean;
}

class ContactDef {
	static fromConfig(gc_contact: ContactConfig): ContactDef {
		return {
			...gc_contact,
			iconRef: gc_contact.iconRef as Icon.Ref,
			//  || Icon.Def.BLANK.iri,
			tagRefs: gc_contact.tagRefs || [],
			iri: Contact.refFromFamilyPubkey(gc_contact.familyRef, gc_contact.pubkey),
			type: gc_contact.type || ContactType.UNKNOWN,
			class: ClassType.CONTACT,
			locked: gc_contact.locked || false,
		};
	}
}



export class Contact extends Addressable<ContactDef> {
	static refFromFamilyPubkey(p_family: Family.Ref, s_pubkey: string): ContactRef {
		return `${p_family}contacts/${s_pubkey}/`;
	}

	protected _a_history: Txn.Bankish[] = [];

	get history(): Txn.Bankish[] {
		return this._a_history;
	}

	address(k_chain: Chain): string {
		return `${k_chain.def.bechPrefix}1${this._g_def.pubkey}`;
	}

	get label(): string {
		return this._g_def.label;
	}

	get type(): AddressType {
		return AddressType.PERSON;
	}

	get familyRef(): Family.Ref {
		return this._g_def.familyRef;
	}
}

export namespace Contact {
	export type Ref = ContactRef;

	export type Def = ContactDef;
	export const Def = ContactDef;

	export type Path = ContactPath;

	export type Config = ContactConfig;

	export type Type = ContactType;
	export const Type = ContactType;
}
