
import {
	ClassType,
	Definable,
} from './_core';

import {
	parse_path_pair,
	wispr_path,
	type WisprRef,
	type WisprResUri,
} from '#/state/path';

import { Icon } from './icon';
import type { Plural } from '#/util/types';

type SiteRef = WisprResUri<ClassType.SITE>;

type SitePath = `sites/${string}`;

interface SiteDef extends Icon.Refable<SitePath> {
	class: ClassType.SITE,
	domain: string;
};


interface SiteConfig {
	domain: string;
	label: string;
	iconRef?: Icon.Ref;
}

class SiteDef {
	static fromConfig(gc_site: SiteConfig): SiteDef {
		return {
			class: ClassType.SITE,
			iri: Site.refFromDomain(gc_site.domain),
			domain: gc_site.domain,
			label: gc_site.label,
			iconRef: gc_site.iconRef || Icon.BLANK.def.iri,
			tagRefs: [],
		};
	}

	static parseRef(sr_ref: WisprRef): SiteParam {
		const [si_site, s_rest] = parse_path_pair(wispr_path(sr_ref), 'sites');

		return {
			familyId: si_site,
			rest: s_rest,
		};
	}
}


export class Site extends Definable<SiteDef> {
	static refFromDomain(p_domain: string): SiteRef {
		return `wispr://root/sites/${p_domain}/`;
	}
}

interface SiteParam {
	familyId: string;
	rest: string;
}

export namespace Site {
	export type Ref = SiteRef;
	export type Def = SiteDef;
	export const Def = SiteDef;
	export type Config = SiteConfig;

	export type Param = SiteParam;
	export type Path = SitePath;

	export type MemberRef<
		s_path extends string=string,
	> = `${SiteRef}${Plural<s_path>}/${string}/`;

	export interface Member<
		s_path extends string=string,
	> extends Icon.Refable<`${SitePath}/${s_path}`> {

	}
}
