
import {
	ClassType,
	Definable,
} from './_core';

import {
	parse_path_pair,
	wispr_path,
	type WisprRef,
	type WisprResUri,
} from '#/state/path';

import { Icon } from './icon';
import type { Plural } from '#/util/types';

type FamilyRef = WisprResUri<ClassType.FAMILY>;

type FamilyPath = `families/${string}`;

interface FamilyDef extends Icon.Refable<FamilyPath> {
	class: ClassType.FAMILY,
	id: string;
};


interface FamilyConfig {
	id: string;
	label: string;
	iconRef?: Icon.Ref;
}

class FamilyDef {
	static fromConfig(gc_family: FamilyConfig): FamilyDef {
		return {
			class: ClassType.FAMILY,
			iri: Family.refFromId(gc_family.id),
			id: gc_family.id,
			label: gc_family.label,
			iconRef: gc_family.iconRef || Icon.BLANK.def.iri,
			tagRefs: [],
		};
	}

	static parseRef(sr_ref: WisprRef): FamilyParam {
		const [si_family, s_rest] = parse_path_pair(wispr_path(sr_ref), 'families');

		return {
			familyId: si_family,
			rest: s_rest,
		};
	}
}


export class Family extends Definable<FamilyDef> {
	static refFromId(si_family: string): FamilyRef {
		return `wispr://root/families/${si_family}/`;
	}
}

interface FamilyParam {
	familyId: string;
	rest: string;
}

export namespace Family {
	export type Ref = FamilyRef;
	export type Def = FamilyDef;
	export const Def = FamilyDef;
	export type Config = FamilyConfig;

	export type Param = FamilyParam;
	export type Path = FamilyPath;

	export type MemberRef<
		s_path extends string=string,
	> = `${FamilyRef}${Plural<s_path>}/${string}/`;

	export interface Member<
		s_path extends string=string,
	> extends Icon.Refable<`${FamilyPath}/${s_path}`> {

	}
}
