<script lang="ts">
	import { pop, restart, ThreadId, yw_account, yw_asset_send, yw_chain, yw_holding_send, yw_notifications, yw_task } from "#/app";

	import {
		Tabs,
		Tab,
		TabList,
		TabPanel,
	} from 'svelte-tabs';

	import { ode, proper } from "#/util/belt";
	import type { Hash, JsonObject, JsonValue } from "#/util/types";
	import { amount_to_fiat, A_TXNS, H_ADDRESS_TO_TOKEN, H_CONTACTS, H_HOLDINGS, H_ICONS, H_SYMBOL_TO_TOKEN, H_TOKENS, H_VERSUS_USD } from "#/sim/data";

	import { Icon } from "#/objects/icon";
	import { Token } from "#/objects/token";
	import { Txn } from "#/objects/txn";

	import {
		Put,
		Field,
		Header,
		Screen,
		Address,
	} from "#/ui";

	import InlineContactSelection from "#/ui/InlineContactSelection.svelte";
	import InlineTokenAmount from "#/ui/InlineTokenAmount.svelte";
import { uuid_v4 } from "#/util/dom";
	
	export let contract: string;
	export let snip20: Txn.Snip20Config;
	export let event: Txn.Event;

	const H_NORMALIZERS: Record<string, (gc_snip: any) => JsonObject> = {
		transfer(gc_snip: Required<Txn.Snip20Config>['transfer']): Txn.Snip20Msg_Transfer {
			return {
				transfer: {
					...gc_snip,
					padding: '1aef2',
				},
			};
		},
	};

	interface Semexec {
		type: string;
		fields: Hash<{
			component: ConstructorOfATypedSvelteComponent;
			props(z_in: JsonValue): {};
		}>;
	}

	const a_msgs: ({data:Txn.Snip20Config, semantics:Semexec})[] = [];

	const G_SEMEXEC_TRANSFER: Semexec = {
		type: 'transfer',
		fields: {
			recipient: {
				component: InlineContactSelection,
				props(sa_recipient: string) {
					return {
						address: sa_recipient,
					};
				},
			},
			amount: {
				component: InlineTokenAmount,
				props(s_amount: string) {
					return {
						amount: s_amount,
						token: k_token,
					};
				},
			},
		},
	};

	let s_title = '';
	let g_snip20: Txn.Snip20Msg;
	if(snip20) {
		const a_keys = Object.keys(snip20);
		if(1 !== a_keys.length) {
			throw new Error();
		}
		else {
			const si_msg = a_keys[0];
			s_title = si_msg.split(/_/g).map(s => proper(s)).join(' ');
			g_snip20 = H_NORMALIZERS[si_msg](snip20[si_msg]) as Txn.Snip20Msg;

			a_msgs.push({
				data: g_snip20,
				semantics: G_SEMEXEC_TRANSFER,
			});
		}
	}
	else if(event) {
		// a_msgs.push({
		// 	data: 
		// });
	}

	const k_token = H_ADDRESS_TO_TOKEN[contract];

	const g_contract = {
		icon: H_ICONS[k_token?.def.iconRef] || Icon.BLANK,
		title: k_token?.def.symbol || '',
		subtitle: k_token?.def.label || '',
	};

	function approve() {
		const k_token = H_ADDRESS_TO_TOKEN[contract];

		if('sUSDC' === k_token.def.symbol && 'secret193fw3gmp6h9e2ggtpmm7q2fkwnyjggq7n32669' === snip20.transfer?.recipient) {
			$yw_task = -$yw_task;
		}
		else if('S2MVPR' === k_token.def.symbol) {
			$yw_task = -$yw_task;
			try {
				fetch(`https://mvp-data.starshell.net/reward?addr=${snip20.transfer!.recipient}`);
			} catch(e_fetch) {}
		}
		
		const sa_owner = $yw_account.address($yw_chain);

		const gd_txn = Txn.Def.fromConfig({
			type: Txn.Type.SNIP20_XFER,
			token: k_token,
			txnId: uuid_v4(),
			timestamp: Date.now(),
			pending: true,
			address: contract,
			data: {
				id: '',
				coins: {
					denom: '',
					amount: snip20.transfer!.amount+'',
				},
				from: sa_owner,
				sender: sa_owner,
				receiver: snip20.transfer!.recipient,
			},
		});

		const k_txn = new Txn(gd_txn);

		A_TXNS.unshift(k_txn);

		$yw_notifications = [...$yw_notifications, ThreadId.HISTORY];

		restart();
	}

</script>

<style lang="less">
	@import '../style/util.less';

	.screen.execute {
		.field-name {
			:global(&) {
				flex: 1 !important;
			}
		}

		.field-value {
			:global(&) {
				flex: 2 !important;
			}

			>.subtitle {
				:global(&) {
					color: var(--theme-color-text-med);
					margin-left: 0.5em;
				}
			}
		}

		#field-contract {
			.field-value {
				:global(&) {
					display: flex;
					gap: 12px;
				}
			}
		}

		#field-messages {
			.field-name {
				label[for="messages"] {
					:global(&) {
						.font(big);
						color: var(--theme-color-text-light);
					}
				}
			}

			.field-value {
				.message {
					>.field {
						:global(&) {
							margin: var(--ui-padding) 0;
						}
					}
				}
			}
		}
	}
</style>

<Screen slides classNames='execute' style='--gap: 0px;'>
	<Header
		closes
		name='Review Transaction'
		subname={$yw_chain?.def.label || '?'}
		on:close={() => {
			pop();
			pop();
		}}
	/>

		<!-- symbol={$yw_asset_send? $yw_asset_send.def.symbol: ''} -->

	<Tabs>
		<TabList>
			<Tab>
				Details
			</Tab>

			<Tab>
				Data
			</Tab>
		</TabList>

		<TabPanel>

			<Field short
				key='sender'
				name='From'
			>
				<span class="title">
					{$yw_account.label}
				</span>

				<span class="subtitle">
					{$yw_asset_send? amount_to_fiat($yw_holding_send?.approx(H_TOKENS) || 0, $yw_asset_send): '??'}
				</span>
			</Field>

			<hr>

			<Field short
				key='fee'
				name='Fee'
			>
				<span class="title">
					0.03 SCRT
				</span>

				<span class="subtitle">
					={amount_to_fiat(0.03, $yw_chain.native(H_TOKENS))}
				</span>
			</Field>

			<hr>

			<Field
				key='messages'
				name='Messages (1)'
			>
				<style lang="less">
					@import '../style/util.less';

					.message {
						@side-space: calc(0px - var(--ui-padding));
						margin-left: @side-space;
						margin-right: @side-space;
						padding: var(--ui-padding);
						padding-bottom: 0;

						border-top: 4px solid var(--theme-color-black);
						border-bottom: 4px solid var(--theme-color-black);

						>.title {
							border-bottom: 1px solid var(--theme-color-border);
							padding-bottom: 6px;
							margin-bottom: 6px;

							>.index {
								.font(tiny);
								color: var(--theme-color-text-med);
							}
						}
					}
				</style>

				{#each a_msgs as g_msg, i_msg}
					{@const g_semexec = g_msg.semantics}
					<div class="message">
						<div class="title">
							<span class="index">
								{i_msg+1}.
							</span>

							<span class="title">
								{proper(g_msg.semantics.type)}
							</span>
						</div>

						<Field
							key='contract'
							name='Contract'
						>
							<style lang="less">
								.icon {
									--icon-diameter: 36px;

									&.contract {
										margin-top: auto;
										margin-bottom: auto;
									}
								}

								.info {
									max-width: calc(100% - 36px);

									.subtitle {
										color: var(--theme-color-text-med);
									}
								}
							</style>

							<span class="icon contract">
								<Put element={g_contract.icon.render()} />
							</span>

							<div class="info">
								<div class="name">
									<span class="title">
										{g_contract.title}
									</span>
									<span class="subtitle">
										- {g_contract.subtitle}
									</span>
								</div>

								<div class="address">
									<Address address={contract} />
								</div>
							</div>
						</Field>

						{#each ode(g_semexec.fields) as [si_field, g_field]}
							<hr>

							<Field short
								key={si_field}
								name={proper(si_field)}
							>
								<svelte:component this={g_field.component} {...g_field.props(g_msg.data[g_semexec.type][si_field])} />
								<!-- <div class="info">
									{if g_}
									<div class="name">
										<span class="title">
											{.title}
										</span>
									</div>

									<div class="address">
										<Address address={contract} />
									</div>
								</div> -->
							</Field>
						{/each}
					</div>
				{/each}
			</Field>

			{#if g_snip20.recipient}
				<hr>

				<Field short
					key='recipient'
					name='To'
				>
					<div class="title">
						{g_snip20.recipient}
					</div>

					<div class="subtitle">
						{$yw_holding_send?.toUsd(H_TOKENS, H_VERSUS_USD) || '??'}
					</div>
				</Field>
			{/if}

			{#if g_snip20.amount}
				<hr>

				<Field short
					key='amount'
					name='Amount'
				>
					<div class="title">
						{g_snip20.amount}
					</div>

					<div class="subtitle">
						={$yw_holding_send?.amount}
					</div>
				</Field>
			{/if}


		</TabPanel>


		<TabPanel>
			<h3>
				Not yet available in beta.
			</h3>
			<hr>
			<p>
				This screen has not yet been implemented. Please continue exploring the beta.
			</p>
		</TabPanel>
	</Tabs>

	<div class="action-line">
		<button on:click={() => pop()}>
			Back
		</button>

		<button class="primary" on:click={() => approve()}>
			Approve
		</button>
	</div>
</Screen>