import { fold, ode, oderac, oderom } from "#/util/belt";

import {
	Account,
	Chain,
	Icon,
	Tag,
	Token,
	Holding,
	Contact,
	Txn,
	Family,
} from "#/objects";

import { Ibct } from "#/objects/ibct";
import type { Hash } from "#/util/types";
import type { WisprUri } from "#/state/path";
import type { ClassType, Searchable, Thing } from "#/objects/_core";
import { Nft } from "#/objects/nft";
import type { Has } from "ts-toolbelt/out/Union/Has";
import { Network } from "#/objects/network";
import { Contract } from "#/objects/contract";
import { uuid_v4 } from "#/util/dom";
import { Site } from "#/objects/site";

export interface SearchItem extends Thing {
	class: ClassType;
	iri: WisprUri<`${string}/`>;
	label: string;
	detail: string;
}

export const A_SEARCHABLE: SearchItem[] = [];

export const H_THINGS = {} as Record<WisprUri, SearchItem>;


export const H_ICONS: Record<Icon.Ref, Icon> = {
	[Icon.refFromFile('blank.svg')]: new Icon(Icon.Def.fromFile('blank.svg')),
};
//  = oderom({
// 	house: {
// 		'mars.png': {},
// 	},
// 	token: {
// 		// 'secret.svg': {},
// 		// 'luna.svg': {},
// 		// 'monero.svg': {},
// 		// 'tether.svg': {},
// 		// 'usd-coin': {},
// 		// 'wrapped-bitcoin.svg': {},
// 		// 'atom.svg': {},
// 		// 'bnb_binance.svg': {},
// 		// 'doge_binance.svg': {},
// 		// 'eth_ethereum.svg': {},
// 		// 'scrt.svg': {},
// 		// 'sefi.svg': {},
// 		// 'sienna.svg': {},
// 		// 'sscrt.svg': {},
// 		// 'usdc_binance.svg': {},
// 		// 'usdc_ethereum.svg': {},
// 		// 'akash.svg': {},
// 		// 'dvpn.png': {},
// 		// 'iris.svg': {},
// 		// 'juno.svg': {},
// 		// 'osmosis.svg': {},
// 		// 'regen.png': {},
// 		// 'shade.png': {},
// 	},
// }, (sr_folder: string, h_files: Hash<unknown>) => {
// 	return oderom(h_files, (sr_file: string) => {
// 		const gd_icon = Icon.Def.fromFile(`${sr_folder}/${sr_file}`);

// 		return {
// 			[gd_icon.iri]: new Icon(gd_icon),
// 		};
// 	})
// });


export const A_HOUSES = [
	'mars',
	'minerva',
	'apollo',
	'ceres',
	'diana',
];

for(const s_house of A_HOUSES) {
	const gd_icon = Icon.Def.fromFile(`house/${s_house}.png`);
	H_ICONS[gd_icon.iri] = new Icon(gd_icon);
}


export const H_ACCOUNTS: Record<Account.Ref, Account> = fold([
	{
		id: '*',
		label: 'All accounts',
		address: '*',
	},
	{
		id: '1',
		label: 'Mars',
		pubkey: '0mtm48ul5mcgjj4hm0a4j3td4l5pt590erl3k9',
		image: 'house/mars.png',
	},
	{
		id: '2',
		label: 'Minerva',
		pubkey: 'aqfw3gmp6h9e2ggtpmm7q245dwnyjggq7n32ag',
		image: 'house/minerva.png',
	},
	{
		id: '3',
		label: 'Apollo',
		pubkey: 'aqfw3gmp6h9e2ggtpmm7q245dwnyjggq7n32az',
		image: 'house/apollo.png',
	},
], (gc_account) => {
	const p_icon = gc_account.image? Icon.refFromFile(gc_account.image): '' as Icon.Ref;

	const gd_account = Account.Def.fromConfig({
		...gc_account as Account.Config,
		iconRef: p_icon,
	});

	A_SEARCHABLE.push({
		iri: gd_account.iri,
		class: gd_account.class,
		label: gd_account.label,
		detail: gd_account.pubkey,
	});

	return {
		[gd_account.iri]: new Account(gd_account),
	};
});

export const K_DEFAULT_ACCOUNT = Object.values(H_ACCOUNTS)[1];
export const S_ACCOUNT_PUBKEY = K_DEFAULT_ACCOUNT.def.pubkey;

export const H_FAMILIES = oderom({
	'cosmos-sdk': {
		label: 'Cosmos SDK',
	},
}, (si_family, gc_family) => {
	const gd_family = Family.Def.fromConfig({
		...gc_family,
		id: si_family,
	});

	return {
		[gd_family.iri]: new Family(gd_family),
	};
});

export const H_CHAINS: Record<Chain.Ref, Chain> = oderom({
	'cosmos-sdk': {
		secret: {
			iri: `wispr://root/chains/secret-4/`,
			label: 'Secret Network',
			id: 'secret-4',
			rpc: '',
			rest: '',
			tagRefs: [],
			icon: 'secret.svg',
		},
		cosmos: {
			iri: `wispr://root/chains/cosmos-4/`,
			label: 'Cosmos Hub',
			id: 'cosmos-4',
			rpc: '',
			rest: '',
			tagRefs: [],
			icon: 'atom.svg',
		},
		// terra: {
		// 	iri: `wispr://root/chains/columbus-5/`,
		// 	label: 'Terra',
		// 	id: 'columbus-5',
		// 	rpc: '',
		// 	rest: '',
		// 	tagRefs: [],
		// 	icon: 'luna.svg',
		// },
		// akash: {
		// 	iri: `wispr://root/chains/akash-5/`,
		// 	label: 'Sentinel',
		// 	id: 'sentinelhub-5',
		// 	rpc: '',
		// 	rest: '',
		// 	tagRefs: [],
		// 	icon: 'dvpn.png',
		// 	bechPrefix: 'sent',
		// },
		// osmosis: {
		// 	iri: `wispr://root/chains/osmosis-1/`,
		// 	label: 'Osmosis',
		// 	id: 'osmosis-5',
		// 	rpc: '',
		// 	rest: '',
		// 	tagRefs: [],
		// 	icon: 'osmosis.svg',
		// 	bechPrefix: 'osmo',
		// },
		// kava: {
		// 	iri: `wispr://root/chains/kava-2/`,
		// 	label: 'Kava',
		// 	id: 'kava-2',
		// 	rpc: '',
		// 	rest: '',
		// 	tagRefs: [],
		// 	icon: 'kava.svg',
		// },
		// juno: {
		// 	iri: `wispr://root/chains/juno-1/`,
		// 	label: 'Juno',
		// 	id: 'juno-1',
		// 	rpc: '',
		// 	rest: '',
		// 	tagRefs: [],
		// 	icon: 'juno.svg',
		// },
	},
}, (si_family, h_chains) => {
	const p_family = Family.refFromId(si_family);

	return oderom(h_chains, (si_alias, gc_chain) => {
		const gd_chain = Chain.Def.fromConfig({
			...gc_chain,
			bechPrefix: (gc_chain as {bechPrefix?:string}).bechPrefix || si_alias,
			familyRef: p_family,
			iconRef: gc_chain.icon? Icon.refFromFile(`token/${gc_chain.icon}`): Icon.BLANK.def.iri,
		});

		A_SEARCHABLE.push({
			iri: gd_chain.iri,
			class: gd_chain.class,
			label: gd_chain.label,
			detail: gd_chain.id,
		});

		return {
			[gd_chain.iri]: new Chain(gd_chain),
		};
	});
});


const H_FAMILY_NETWORKS = {
	'cosmos-sdk': {
		default: {
			label: 'Default Provider',
		},
	},
} as Hash<Hash<{label:string}>>;

export const H_NETWORKS = oderom({
	'cosmos-sdk': {
		'secret-4': {
			starshell: {
				label: 'StarShell Priority',
				// overlay: 'site/starshell.png',
			},
			figment: {
				label: 'Figment',
				// overlay: 'site/figment.png',
			},
		},
		'cosmos-4': {},
	},
}, (si_family, h_chains) => {
	const p_family = Family.refFromId(si_family);
	const h_inherits = H_FAMILY_NETWORKS[si_family];

	return oderom({
		...h_chains,
	}, (si_chain, h_networks) => {
		const p_chain = Chain.refFromFamilyId(p_family, si_chain);

		const f_mk_network = (si_network: string, gc_network: {label:string, overlay?:string}) => {
			let p_icon: Icon.Ref;
			if(gc_network.overlay) {
				const gd_icon_fg = Icon.Def.fromFile(gc_network.overlay);
				H_ICONS[gd_icon_fg.iri] = new Icon(gd_icon_fg);

				const gd_icon_comp = Icon.Def.fromComposite(H_CHAINS[p_chain].def.iconRef, gd_icon_fg.iri);
				p_icon = gd_icon_comp.iri;
				H_ICONS[p_icon] = new Icon(gd_icon_comp);
			}
			else {
				p_icon = H_CHAINS[p_chain].def.iconRef;
			}
			
			const gd_network = Network.Def.fromConfig({
				...gc_network,
				id: si_network,
				chainRef: p_chain,
				iconRef: p_icon,
			});

			return {
				[gd_network.iri]: new Network(gd_network),
			};
		};

		return {
			...oderom(h_inherits || {}, f_mk_network),
			...oderom(h_networks, f_mk_network),
		};
	});
});

export const K_DEFAULT_CHAIN = Object.values(H_CHAINS)[0];
export const SA_ACCOUNT_DEFAULT = K_DEFAULT_ACCOUNT.address(K_DEFAULT_CHAIN);

export const H_TAGS: Record<Tag.Ref, Tag> = oderom({
	// 1: {
	// 	label: 'Pink',
	// 	color: '#D500F9',
	// },
	1: {
		label: 'Speculative',
		color: '#D500F9',
	},
	// 2: {
	// 	label: 'Purple',
	// 	color: '#C51162',
	// },
	2: {
		label: 'Space Enthusiast',
		color: '#C51162',
	},
	// 3: {
	// 	label: 'Red',
	// 	color: '#FF4D21',
	// },
	3: {
		label: 'Meme Lord',
		color: '#FF4D21',
	},
	// 4: {
	// 	label: 'Orange',
	// 	color: '#FF8622',
	// },
	4: {
		label: 'Governance',
		color: '#FF8622',
	},
	// 5: {
	// 	label: 'Yellow',
	// 	color: '#EEB521',
	// },
	// 5: {
	// 	label: 'Crypto OG',
	// 	color: '#EEB521',
	// },
	5: {
		label: 'BSC',
		color: '#EEB521',
	},
	// 6: {
	// 	label: 'Grass',
	// 	color: '#7E9E24',
	// },
	6: {
		label: 'Sellable',
		color: '#7E9E24',
	},
	// 7: {
	// 	label: 'Green',
	// 	color: '#3A6F16',
	// },
	7: {
		label: 'DeFi',
		color: '#3A6F16',
	},
	// 8: {
	// 	label: 'Teal',
	// 	color: '#009688',
	// },
	8: {
		label: 'Stablecoin',
		color: '#009688',
	},
	// 9: {
	// 	label: 'Blue',
	// 	color: '#1976D2',
	// },
	9: {
		label: 'IBC',
		color: '#1976D2',
	},
	// 10: {
	// 	label: 'Violet',
	// 	color: '#6200EA',
	// },
	10: {
		label: 'Crypto OG',
		color: '#6200EA',
	},
	// 11: {
	// 	label: 'Gray',
	// 	color: '#607D8B',
	// },
	11: {
		label: 'Business',
		color: '#607D8B',
	},
	// 12: {
	// 	label: 'Brown',
	// 	color: '#795548',
	// },
	12: {
		label: 'Ethereum',
		color: '#795548',
	},
	13: {
		label: 'White',
		color: '#FFFFFF',
	},
}, (si_tag: string, gc_tag) => {
	const gd_tag = Tag.Def.fromConfig({
		...gc_tag,
		id: si_tag,
	});

	return {
		[gd_tag.iri]: new Tag(gd_tag),
	}
});

interface SimTokenConfig {
	label: string;
	address: string;
	icon: string;
	tags?: number[];
	decimals?: number;
	data?: Hash;
}

export const H_TOKENS: Record<Token.Ref, Token> = oderom({
	'cosmos-sdk': {
		'secret-4': {
			SCRT: {
				label: 'Secret',
				address: '.native',
				icon: 'secret.svg',
				tags: [],
				data: {
					coingecko_id: 'secret',
				},
			},
			sSCRT: {
				label: 'Secret Secret',
				address: 'secret1k0jntykt7e4g3y88ltc60czgjuqdy4c9e8fzek',
				icon: 'sscrt.svg',
				tags: [],
				allowances: [
					{
						spender: 'secret1drm0dwvewjyy0rhrrw485q4f5dnfm6j25zgfe5',
						amount: '340282366920938463463374607431768211454',
						expiration: null,
					},
				],
				data: {
					coingecko_id: 'secret',
				},
			},
			sUSDC: {
				label: 'Secret USD Coin',
				address: 'secret1h6z05y90gwm4sqxzhz4pkyp36cna9xtp7q0urv',
				icon: 'usdc_ethereum.svg',
				tags: [8, 11],
				data: {
					coingecko_id: 'usd-coin',
				},
				allowances: [
					{
						spender: 'secret14cxq26u8f9zpd09a72uznwz7kew9yc085d08hy',
						amount: '340282366920938463463374607431768211454',
						expiration: null,
					},
				],
			},
			SEFI: {
				label: 'Secret Finance',
				address: 'secret15l9cqgz5uezgydrglaak5ahfac69kmx2qpd6xt',
				icon: 'sefi.svg',
				tags: [4, 7],
				data: {
					coingecko_id: 'secret-finance',
				},
				allowances: [
					{
						spender: 'secret1p9nyn4qenwxxzg2j9c3nsqfr9dd09xkne55qjm',
						amount: '340282366920938463463374607431768211454',
						expiration: null,
					},
					{
						spender: 'secret14cxq26u8f9zpd09a72uznwz7kew9yc085d08hy',
						amount: '340282366920938463463374607431768211454',
						expiration: null,
					},
				],
			},
			SHD: {
				label: 'Shade Protocol',
				address: 'secret1qfql357amn448duf5gvp9gr48sxx9tsnhupu3d',
				icon: 'shade.png',
				tags: [4, 7],
				allowances: [
					{
						spender: 'secret1drm0dwvewjyy0rhrrw485q4f5dnfm6j25zgfe5',
						amount: '340282366920938463463374607431768211454',
						expiration: null,
					},
				],
				data: {
					coingecko_id: 'shade-protocol',
				}
			},
			SIENNA: {
				label: 'Sienna',
				address: 'secret1rgm2m5t530tdzyd99775n6vzumxa5luxcllml4',
				icon: 'sienna.svg',
				tags: [4, 7],
				data: {
					coingecko_id: 'sienna',
				},
			},
			sATOM: {
				label: 'Secret Cosmos',
				address: 'secret14mzwd0ps5q277l20ly2q3aetqe3ev4m4260gf4',
				icon: 'atom.svg',
				tags: [1, 9],
				data: {
					coingecko_id: 'cosmos',
				},
				allowances: [
					{
						spender: 'secret1p9nyn4qenwxxzg2j9c3nsqfr9dd09xkne55qjm',
						amount: '340282366920938463463374607431768211454',
						expiration: null,
					},
				],
			},
			'sBNB(BSC)': {
				label: 'Secret Binance Coin (BSC)',
				address: 'secret1tact8rxxrvynk4pwukydnle4l0pdmj0sq9j9d5',
				icon: 'bnb_binance.svg',
				tags: [5],
				data: {
					coingecko_id: 'bnb',
				},
			},
			sETH: {
				label: 'Secret Ether',
				address: 'secret1wuzzjsdhthpvuyeeyhfq2ftsn3mvwf9rxy6ykw',
				icon: 'eth_ethereum.svg',
				decimals: 18,
				tags: [1, 12],
				data: {
					coingecko_id: 'ethereum',
				},
			},
			'sDOGE(BSC)': {
				label: 'Secret Dogecoin',
				address: 'secret16nqax7x66z4efpu3y0kssdfnhg93va0h20yjre',
				icon: 'doge_binance.svg',
				tags: [1, 3, 5],
				data: {
					coingecko_id: 'dogecoin',
				},
			},
			sWBTC: {
				label: 'Secret Wrapped Bitcoin',
				address: 'secret1g7jfnxmxkjgqdts9wlmn238mrzxz5r92zwqv4a',
				icon: 'wrapped-bitcoin.svg',
				decimals: 8,
				tags: [10, 12],
				data: {
					coingecko_id: 'wrapped-bitcoin',
				},
			},
			XMR: {
				label: 'Secret Monero',
				address: 'secret19ungtd2c7srftqdwgq0dspwvrw63dhu79qxv88',
				icon: 'monero.svg',
				data: {
					coingecko_id: 'monero',
				},
			},
			sLUNA: {
				label: 'Secret Terra',
				address: 'secret1ra7avvjh9fhr7dtr3djutugwj59ptctsrakyyw',
				icon: 'luna.svg',
				tags: [1, 9],
				data: {
					coingecko_id: 'terra-luna',
				},
			},

			'SHD-SSCRT': {
				label: 'SHD-sSCRT Swap LP',
				address: 'secret1drm0dwvewjyy0rhrrw485q4f5dnfm6j25zgfe5',
				icon: '',
				tags: [7],
				data: {
					sienna_type: 'swap',
					defi_pair_1: 'secret1qfql357amn448duf5gvp9gr48sxx9tsnhupu3d',
					defi_pair_2: 'secret1k0jntykt7e4g3y88ltc60czgjuqdy4c9e8fzek',
				},
			},

			'LP-SEFI-SUSDC': {
				label: 'SEFI-sUSDC Swap LP',
				address: 'secret1mm7df4ygxwlfg0l70jrrkshlhtp8vv5n7hj9rr',
				icon: '',
				tags: [7],
				data: {
					secretswap_type: 'swap',
					defi_pair_1: 'secret15l9cqgz5uezgydrglaak5ahfac69kmx2qpd6xt',
					defi_pair_2: 'secret1h6z05y90gwm4sqxzhz4pkyp36cna9xtp7q0urv',
				},
			},

			'SPYSEFIUSDC-3': {
				label: 'SEFI-sUSDC Yield Pool',
				address: 'secret16ahwz30chht7wg926tfaj07563hkmemad4nnzm',
				icon: '',
				tags: [7],
				data: {
					secretswap_type: 'yield',
					defi_pair_1: 'secret15l9cqgz5uezgydrglaak5ahfac69kmx2qpd6xt',
					defi_pair_2: 'secret1h6z05y90gwm4sqxzhz4pkyp36cna9xtp7q0urv',
				},
			},

			'LP-SEFI-SATOM': {
				label: 'SEFI-sATOM Swap LP',
				address: 'secret1w8l6c4fgc4nj2nwxadpmza4kt44xzd72pjn29u',
				icon: '',
				tags: [7],
				data: {
					secretswap_type: 'swap',
					defi_pair_1: 'secret15l9cqgz5uezgydrglaak5ahfac69kmx2qpd6xt',
					defi_pair_2: 'secret14mzwd0ps5q277l20ly2q3aetqe3ev4m4260gf4',
				},
			},

			'SPYSEFIATOM-3': {
				label: 'SEFI-sATOM Yield Pool',
				address: 'secret1ga4yptznzm6ycrru3d02g6596cs7wgkugv9pmc',
				icon: '',
				tags: [7],
				data: {
					secretswap_type: 'yield',
					defi_pair_1: 'secret15l9cqgz5uezgydrglaak5ahfac69kmx2qpd6xt',
					defi_pair_2: 'secret14mzwd0ps5q277l20ly2q3aetqe3ev4m4260gf4',
				},
			},

			S2MVPR: {
				label: 'StarShell MVP Reward',
				address: 'secret1st4rsh311rew4rd70kenst4rsh311rew4rd70k',
				icon: 's2mvpr.png',
				tags: [],
				data: {},
				decimals: 1,
			},
		},

		'cosmos-4': {
			ATOM: {
				label: 'Cosmos',
				address: '.native',
				icon: 'atom.svg',
				data: {
					coingecko_id: 'cosmos',
				},
			},
		},

		// 'columbus-5': {
		// 	LUNA: {
		// 		label: 'Terra',
		// 		address: '.native',
		// 		icon: 'luna.svg',
		// 		data: {
		// 			coingecko_id: 'cosmos',
		// 		},
		// 	},
		// },

		// 'sentinelhub-5': {
		// 	DVPN: {
		// 		label: 'Sentinel',
		// 		address: '.native',
		// 		icon: 'dvpn.png',
		// 		tags: [],
		// 		data: {
		// 			coingecko_id: 'sentinel',
		// 		},
		// 	},
		// },
		
		// 'osmosis-5': {
		// 	OSMO: {
		// 		label: 'Osmosis',
		// 		address: '.native',
		// 		icon: 'osmosis.svg',
		// 		tags: [],
		// 		data: {
		// 			coingecko_id: 'osmosis',
		// 		},
		// 	},
		// },

		// 'kava-2': {
		// 	KAVA: {
		// 		label: 'Kava',
		// 		address: '.native',
		// 		icon: 'kava.svg',
		// 		tags: [],
		// 		data: {
		// 			coingecko_id: 'kava',
		// 		},
		// 	},
		// },
		
		// 'juno-1': {
		// 	JUNO: {
		// 		label: 'Juno',
		// 		address: '.native',
		// 		icon: 'juno.svg',
		// 		tags: [],
		// 		data: {
		// 			coingecko_id: 'juno-network',
		// 		},
		// 	},
		// },
	},
} as Hash<Hash<Hash<SimTokenConfig>>>, (si_family, h_chains) => {
	const p_family = Family.refFromId('cosmos-sdk');

	return oderom(h_chains, (si_chain, h_tokens) => {
		const p_chain = Chain.refFromFamilyId(p_family, si_chain);

		return oderom(h_tokens, (si_symbol, gc_token) => {
			let gd_icon; 
			
			if(gc_token.icon) {
				gd_icon = Icon.Def.fromFile(`token/${gc_token.icon}`);
			}
			else if(gc_token.data?.defi_pair_1) {
				gd_icon = Icon.Def.fromPair(gc_token.data.defi_pair_1, gc_token.data.defi_pair_2);
			}

			if(gd_icon) {
				H_ICONS[gd_icon.iri] = new Icon(gd_icon);
			}

			const gd_token = Token.Def.fromConfig({
				chainRef: p_chain,
				iconRef: gd_icon?.iri || Icon.Def.BLANK.iri,
				decimals: gc_token.decimals || 6,
				native: '.native' === gc_token.address,
				symbol: si_symbol,
				tagRefs: (gc_token.tags || []).map(i_tag => Tag.refFromId(i_tag+'')),
				...gc_token,
			});

			A_SEARCHABLE.push({
				iri: gd_token.iri,
				class: gd_token.class,
				label: gd_token.label,
				detail: gd_token.symbol,
			});

			return {
				[gd_token.iri]: new Token(gd_token),
			}
		});
	});
});


interface NftConfig {
	id: string;
	image: string;
	attributes: Hash;
	label?: string;
	tags?: number[];
}

interface CollectionConfig {
	title: string;
	address: string;
	owned: NftConfig[];
	plural?: string;
}

interface Collection {
	label: string;
	chainRef: Chain.Ref;
}

export const H_ADDR_TO_COLLECTION: Hash<Collection> = {};

export const H_OWNER_TO_COLLECTIONS: Record<Account.Ref, Hash<Collection>> = {};

export const H_NFTS = oderom({
	'cosmos-sdk': {
		'secret-4': {
			'1': {
				spawn: {
					title: 'Spawn',
					plural: 'Spawn',
					address: 'secret10mtm48ul5mcgjj4hm0a4j3td4l5pt590erl3k9',
					owned: [
						{
							label: 'Scorpiotyrannus',
							id: '17',
							image: 'spawn-1.png',
							attributes: {},
						},
						{
							label: 'Dracodon',
							id: '206',
							image: 'spawn-2.png',
							attributes: {},
							tags: [6],
						},
						{
							id: '311',
							image: 'spawn-3.png',
							attributes: {},
						},
					],
				},

				anons: {
					title: 'Anon',
					address: 'secret1xx4fp7qgkyxdk9elrzd8v5p7gj28lvxprwl9lw',
					owned: [
						{
							id: '69',
							image: 'anon-69.png',
							label: 'Party',
							attributes: {
								Background: 'Green',
								'Base Person': 'Beige',
								Head: 'Womens 2',
								Clothes: 'Hawaiin Shirt - Bananas',
								Ears: 'Earpiece',
								Mouth: 'Vape',
							},
							tags: [6],
						},
						{
							id: '420',
							image: 'anon-420.png',
							attributes: {
								Background: 'Green',
								'Base Person': 'Beige',
								Head: 'Womens 2',
								Clothes: 'Hawaiin Shirt - Bananas',
								Ears: 'Earpiece',
								Mouth: 'Vape',
							},
						},
						{
							id: '477',
							image: 'anon-477.png',
							label: 'Homie',
							attributes: {
								Background: 'Green',
								'Base Person': 'Beige',
								Head: 'Womens 2',
								Clothes: 'Hawaiin Shirt - Bananas',
								Ears: 'Earpiece',
								Mouth: 'Vape',
							},
						},
						{
							id: '551',
							label: 'Dad',
							image: 'anon-551.png',
							attributes: {
								Background: 'Green',
								'Base Person': 'Beige',
								Head: 'Womens 2',
								Clothes: 'Hawaiin Shirt - Bananas',
								Ears: 'Earpiece',
								Mouth: 'Vape',
							},
						},
						{
							id: '580',
							image: 'anon-580.png',
							attributes: {
								Background: 'Green',
								'Base Person': 'Beige',
								Head: 'Womens 2',
								Clothes: 'Hawaiin Shirt - Bananas',
								Ears: 'Earpiece',
								Mouth: 'Vape',
							},
							tags: [6],
						},
					],
				},

				kersoness: {
					title: 'kersoness art',
					plural: 'kersoness art',
					address: 'secret1gdu5ftzfp658l3p2xt5kjmyfj7qd3d6pn529y2',
					owned: [
						{
							label: '24 Full body',
							id: '1644282900826_6adc8',
							image: 'kersoness-anon-74.jpeg',
							attributes: {},
							tags: [6],
						},
						{
							label: '133 Full body',
							id: '1643776349128_e39c7',
							image: 'kersoness-anon-133.jpeg',
							attributes: {},
						},
						{
							label: '293 Full body',
							id: '1644282900826_6adc5',
							image: 'kersoness-anon-293.jpeg',
							attributes: {},
						},
					],
				},
			},
		},
	},
}, (si_family: string, h_chains: Hash<Hash<Hash<CollectionConfig>>>) => {
	const p_family = Family.refFromId(si_family);

	return oderom(h_chains, (si_chain: string, h_accounts: Hash<Hash<CollectionConfig>>) => {
		const p_chain = Chain.refFromFamilyId(p_family, si_chain);

		return oderom(h_accounts, (si_account, h_collections: Hash<CollectionConfig>) => {
			const p_account = Account.refFromId(si_account);

			const h_colls = H_OWNER_TO_COLLECTIONS[p_account] = {};

			return oderom(h_collections, (si_collection: string, g_collection: CollectionConfig) => {

				H_ADDR_TO_COLLECTION[g_collection.address] = {
					label: (g_collection as any).plural || g_collection.title+'s',
					chainRef: p_chain,
				};

				return fold(g_collection.owned, (gc_nft: NftConfig) => {
					const gd_icon = Icon.Def.fromFile(`nft/${gc_nft.image}`);

					H_ICONS[gd_icon.iri] = new Icon(gd_icon);

					const gd_nft = Nft.Def.fromConfig({
						id: gc_nft.id,
						image: `/asset/nft/${gc_nft.image}`,
						iconRef: gd_icon.iri,
						label: gc_nft.label || '',
						autoLabel: `${g_collection.title} #${gc_nft.id}`,
						address: g_collection.address,
						extension: gc_nft.attributes,
						chainRef: p_chain,
						tagRefs: (gc_nft.tags || []).map(i_tag => Tag.refFromId(i_tag+'')),
						owner: H_ACCOUNTS[p_account].address(H_CHAINS[p_chain]),
					});

					A_SEARCHABLE.push({
						iri: gd_nft.iri,
						class: gd_nft.class,
						label: gd_nft.label,
						detail: si_collection,
					});

					return {
						[gd_nft.iri]: new Nft(gd_nft),
					};
				});
			});
		});
	});
});

export const H_SYMBOL_TO_TOKEN = oderom(H_TOKENS, (p_token: string, k_token: Token) => ({
	[k_token.def.symbol]: p_token as Token.Ref,
}));

export const H_ADDRESS_TO_TOKEN = oderom(H_TOKENS, (p_token: string, k_token: Token) => {
	if(k_token.def.address !== '.native') {
		return {
			[k_token.def.address]: k_token,
		};
	}

	return {};
});

export const H_IBCTS: Record<Ibct.Ref, Ibct> = [
	{
		chainId: 'secret-4',
		colonies: {
			'secret-4': 'sSCRT',
		},
	},
	{
		chainId: 'cosmos-4',
		colonies: {
			'secret-4': 'sATOM',
		},
	},
	// {
	// 	chainId: 'columbus-5',
	// 	colonies: {
	// 		'secret-4': 'sLUNA',
	// 	},
	// },
].reduce((h_out, gc_ibct) => {
	const p_family = Family.refFromId('cosmos-sdk');
	const p_chain = Chain.refFromFamilyId(p_family, gc_ibct.chainId);
	const p_native = Token.refFromChainAddr(p_chain, '.native');

	const gd_ibct = Ibct.Def.fromConfig({
		homeTokenRef: p_native,
		colonies: oderom(gc_ibct.colonies, (si_chain, s_symbol) => ({
			[`wispr://root/chains/${si_chain}/`]: H_SYMBOL_TO_TOKEN[s_symbol],
		})),
	});

	const k_ibct = new Ibct(gd_ibct);

	for(const [, p_token] of ode(gd_ibct.colonies)) {
		if(p_token in H_TOKENS) {
			H_TOKENS[p_token].ibct = k_ibct;
		}
		else {
			debugger;
		}
	}

	return {
		...h_out,
		[gd_ibct.iri]: k_ibct,
	};
}, {});


// export const H_COLONIES = oderom(H_IBCTS, (p_ibct, k_ibct) => {
// 	const h_colonies = k_ibct.def.colonies;
// 	const h_out: Record<Token.Ref, Ibct> = {};
// 	debugger;
// 	for(const p_chain in h_colonies) {
// 		h_out[h_colonies[p_chain as Chain.Ref]] = k_ibct;
// 	}
// 	return h_out;
// });


export const H_HOLDINGS: Record<Token.Ref, Holding> = oderom({
	'1': {
		'cosmos-sdk': {
			'secret-4': {
				SCRT: 3_937_316_225n,
				sSCRT: 4_486_991_125n,
				sUSDC: 7_105_250_000n,
				SEFI: 251_626_134_221n,
				SHD: 11_519_015n,
				SIENNA: 127_450_300n,
				sATOM: 208_751_225n,
				'sBNB(BSC)': 9_200_125n,
				sETH: 17_215_325_221_004_889_213n,
				'sDOGE(BSC)': 69_420_000_000n,
				sWBTC: 1_211_452_66n,
				XMR: 39_450_627n,
				sLUNA: 10_801_150n,

				'LP-SEFI-SUSDC': 0n,
				'LP-SEFI-SATOM': 0n,
				'SPYSEFIUSDC-3': 16_065_546_394n,
				'SPYSEFIATOM-3': 2_422_013_726n,

				...'7' === localStorage.task && {
					'S2MVPR': 10n,
				},
			},
			// 'cosmos-4': {
			// 	ATOM: 117_541_220n,
			// },
			// 'columbus-5': {
			// 	LUNA: 1_256_000n,
			// },
			// 'sentinelhub-5': {
			// 	// dvpn
			// },
			// id: 'osmosis-5',
			// id: 'kava-2',
			// id: 'juno-1',
		},
	},
	'2': {
		'cosmos-sdk': {
			'secret-4': {
				SCRT: 200_000_000n,
			},
			'cosmos-4': {
				ATOM: 3_456_178_190n,
			},
		},
	},
}, (si_account, h_accounts: Hash<Hash<Hash<bigint>>>) => {
	const k_account = H_ACCOUNTS[Account.refFromId(si_account)];

	return oderom(h_accounts, (si_family, h_chains) => {
		const p_family = Family.refFromId(si_family);

		return oderom(h_chains, (si_chain, h_tokens) => {
			const p_chain = Chain.refFromFamilyId(p_family, si_chain);

			return oderom(h_tokens, (si_symbol: string, xg_holding: bigint) => {
				const gd_holding = Holding.Def.fromConfig({
					tokenRef: H_SYMBOL_TO_TOKEN[si_symbol],
					// chainRef: p_chain,
					// familyRef: p_family,
					holderAddr: k_account.address(H_CHAINS[p_chain]),
					balance: xg_holding,
				});

				const gd_all = Holding.Def.fromConfig({
					tokenRef: H_SYMBOL_TO_TOKEN[si_symbol],
					holderAddr: '*',
					balance: xg_holding,
				});
				
				return {
					[gd_holding.iri]: new Holding(gd_holding),
					[gd_all.iri]: new Holding(gd_all),
				};
			});
		})
	});
});

for(const [p_account, k_account] of ode(H_ACCOUNTS)) {
	for(const [p_chain, k_chain] of ode(H_CHAINS)) {
		const kt_native = k_chain.native(H_TOKENS);
		const p_token = kt_native.def.iri;
		const sa_holder = k_account.address(k_chain);

		const p_holding = Holding.refFromTokenAccount(p_token, sa_holder);

		if(!(p_holding in H_HOLDINGS)) {
			const gd_holding = Holding.Def.fromConfig({
				tokenRef: p_token,
				holderAddr: sa_holder,
				balance: 0n,
			});
		
			H_HOLDINGS[p_holding] = new Holding(gd_holding);
		}
	}
}


async function reload() {
	const h_cg_inv: Hash<Token[]> = {};
	const a_cg_ids: string[] = [];

	const h_ss_yields: Hash<Token> = {};
	const h_ss_swaps: Hash<Token> = {};

	Object.values(H_TOKENS).forEach((k_token) => {
		const {
			coingecko_id: si_coingecko,
			secretswap_type: si_secretswap,
			defi_pair_1: sa_pair_1,
			defi_pair_2: sa_pair_2,
		} = k_token.def.data;

		if(si_coingecko) {
			const a_tokens = h_cg_inv[si_coingecko] = h_cg_inv[si_coingecko] || [];
			a_tokens.push(k_token);
			a_cg_ids.push(si_coingecko);
		}
		else if('yield' === si_secretswap) {
			h_ss_yields[k_token.def.address] = k_token;
		}
		else if('swap' === si_secretswap) {
			h_ss_swaps[k_token.def.address] = k_token;
		}
	});

	// coingecko
	{
		const h_params = {
			ids: a_cg_ids.join(','),
			vs_currencies: 'usd',
		};

		const s_params = new URLSearchParams(ode(h_params));

		const d_fetch = await fetch(`https://api.coingecko.com/api/v3/simple/price?${s_params}`, {
			method: 'GET',
			mode: 'cors',
			// cache: 'no-store',
		});

		const h_prices = await d_fetch.json();

		for(const si_token in h_prices) {
			for(const k_token of h_cg_inv[si_token]) {
				const p_token = k_token.def.iri;
				const g_versus = H_VERSUS_USD[p_token] = H_VERSUS_USD[p_token] || {tokenRef:p_token};
				g_versus.value = h_prices[si_token].usd;
			}
		}
	}

	// secretswap
	{
		
		const d_fetch = await fetch(`https://api-bridge-mainnet.azurewebsites.net/rewards/?page=0&size=1000`, {
			method: 'GET',
			mode: 'cors',
			// cache: 'no-store',
		});

		const h_state = await d_fetch.json();

		for(const g_pool of h_state.pools) {
			const k_token_pool = h_ss_yields[g_pool.pool_address];
			if(k_token_pool) {
				const p_token = k_token_pool.def.iri;
				const g_versus = H_VERSUS_USD[p_token] = H_VERSUS_USD[p_token] || {tokenRef:p_token};
				g_versus.value = +g_pool.inc_token.price;
			}

			const k_token_swap = H_ADDRESS_TO_TOKEN[g_pool.inc_token.address];
			if(k_token_swap) {
				const p_token = k_token_swap.def.iri;
				const g_versus = H_VERSUS_USD[p_token] = H_VERSUS_USD[p_token] || {tokenRef:p_token};
				g_versus.value = +g_pool.inc_token.price;
			}
		}
	}
}

// usd prices
reload();

export const H_VERSUS_USD: Record<Token.Ref, {tokenRef:Token.Ref, value:number}> = oderom({
	SCRT: 5.34,
	sSCRT: 5.34,
	sUSDC: 1,
	SHD: 51.12,
	SEFI: 0.0358225,
	SIENNA: 7.18,
	sATOM: 27.43,
	'sBNB(BSC)': 378.26,
	sETH: 2833.75,
	'sDOGE(BSC)': 0.140679,
	sWBTC: 41628.69,
	XMR: 170.92,
	sLUNA: 57.6,
	'SPYSEFIUSDC-3': 0.404941980930085,
	'SPYSEFISATOM': 1.8008429208421266,
	'LP-SEFI-SUSDC': 0,
	'LP-SEFI-SATOM': 0,
	'S2MVPR': 0,
}, (si_symbol: string, x_usd: number) => ({
	[H_SYMBOL_TO_TOKEN[si_symbol]]: {
		tokenRef: H_SYMBOL_TO_TOKEN[si_symbol],
		value: x_usd,
	},
}));

// export enum TxnType {
// 	NONE = 0,
// 	SENT = 1,
// 	RECV = 2,
// }

// export interface Txn {
// 	type: TxnType;
// 	token: Token;
// 	amount: bigint;
// 	address: string;
// }


export const H_CONTACTS = fold([
	{
		label: 'Nick Szabo',
		pubkey: '4mtm48ul51fkjj4hm0a4j3d4l5pt590erl4jz1',
		tags: [10],
	},
	{
		label: 'Satoshi Nakamoto',
		pubkey: '93fw3gmp6h9e2ggtpmm7q2fkwnyjggq7n32669',
		image: 'satoshi-nakamoto.jpeg',
		tags: [10],
	},
	{
		label: 'Wei Dai',
		pubkey: 'z2h347n4v32r9sw07un6darjwnl2c7kqgcu215',
		tags: [10],
	},
	{
		label: 'Hal Finney',
		pubkey: 'd5223gmp6h9e2ggtpmm7q245wnyjggqx11798s',
		tags: [10],
	},
	{
		label: 'Elon Musk',
		pubkey: '72d1860jwy0zq686zcaejl0s0c3axwgxa7j1ff',
		image: 'elon-musk.jpeg',
		tags: [2, 3],
	},
	{
		label: 'Carl Sagan',
		pubkey: '89aa860jwy0zq686zcaejlx0c3axwgwgx192a7',
		image: 'carl-sagan.png',
		tags: [2],
	},
	{
		label: 'Edward Snowden',
		pubkey: 'ff1j7axgwxa3c0s0ljeacz686qz0ywj0681d27',
		tags: [],
	},
], (gc_contact) => {
	const p_family = Family.refFromId('cosmos-sdk');

	let p_icon = '' as Icon.Ref;
	if(gc_contact.image) {
		const gd_icon = Icon.Def.fromFile(`contact/${gc_contact.image}`);
		p_icon = gd_icon.iri;

		H_ICONS[p_icon] = new Icon(gd_icon);
	}

	const gd_contact = Contact.Def.fromConfig({
		...gc_contact,
		familyRef: p_family,
		tagRefs: gc_contact.tags.map(i_tag => Tag.refFromId(i_tag+'')),
		type: Contact.Type.PERSON,
		iconRef: p_icon,
	});


	A_SEARCHABLE.push({
		iri: gd_contact.iri,
		class: gd_contact.class,
		label: gd_contact.label,
		detail: gd_contact.pubkey,
	});

	return {
		[gd_contact.iri]: new Contact(gd_contact),
	};
});

export const H_CONTRACTS = oderom({
	'secret1drm0dwvewjyy0rhrrw485q4f5dnfm6j25zgfe5': {
		icon: 'sienna.png',
		data: {
			domain: 'app.sienna.network',
		},
	},

	'secret14cxq26u8f9zpd09a72uznwz7kew9yc085d08hy': {
		icon: 'secretswap.png',
		data: {
			domain: 'app.secretswap.net',
		},
	},

	'secret1p9nyn4qenwxxzg2j9c3nsqfr9dd09xkne55qjm': {
		icon: 'secretswap.png',
		data: {
			domain: 'app.secretswap.net',
		},
	},
}, (sa_contract, gc_contract) => {
	const p_family = Family.refFromId('cosmos-sdk');
	const p_chain = K_DEFAULT_CHAIN.def.iri;

	let p_icon = '' as Icon.Ref;
	if(gc_contract.icon) {
		const gd_icon = Icon.Def.fromFile(`site/${gc_contract.icon}`);
		p_icon = gd_icon.iri;

		H_ICONS[p_icon] = new Icon(gd_icon);
	}

	const gd_contract = Contract.Def.fromConfig({
		...gc_contract,
		label: gc_contract.data.domain,
		address: sa_contract,
		codeHash: '0',
		chainRef: p_chain,
		tagRefs: ((gc_contract as {tags?:number[]}).tags || []).map(i_tag => Tag.refFromId(i_tag+'')),
		iconRef: p_icon,
	});

	A_SEARCHABLE.push({
		iri: gd_contract.iri,
		class: gd_contract.class,
		label: gd_contract.label,
		detail: sa_contract,
	});

	return {
		[gd_contract.iri]: new Contract(gd_contract),
	};
});

export const H_ADDR_TO_CONTACT: Record<string, Contact> = oderom(H_CONTACTS, (_, k_contact) => oderom(H_CHAINS, (p_chain, k_chain) => {
	return {
		[k_contact.address(k_chain)]: k_contact,
	};
}));

export const H_ADDR_TO_CONTRACT: Record<string, Contract> = oderom(H_CONTRACTS, (_, k_contract) => ({
	[k_contract.def.address]: k_contract,
}));


export const H_SITES = oderom({
	'app.secretswap.net': {
		label: 'SecretSwap',
		icon: 'site/secretswap.png',
	},
	'app.sienna.network': {
		label: 'Sienna',
		icon: 'site/sienna.png',
	},
	'bridge.scrt.network': {
		label: 'Secret Network Bridge',
		icon: 'site/scrt.png',
	},
}, (p_domain, gc_site) => {
	const gd_icon = Icon.Def.fromFile(gc_site.icon);
	H_ICONS[gd_icon.iri] = new Icon(gd_icon);

	const gd_site = Site.Def.fromConfig({
		...gc_site,
		domain: p_domain,
		iconRef: gd_icon.iri,
	});
	

	A_SEARCHABLE.push({
		iri: gd_site.iri,
		class: gd_site.class,
		label: gd_site.label,
		detail: p_domain,
	});

	return {
		[gd_site.iri]: new Site(gd_site),
	};
});

// ({
// 	[k_contact.def.address]: k_contact,
// }));

const A_UNLISTED = [
	'secret10mtm48ul5mcgjj4hm0a4j3td4l5pt590erl3k9',
	'secret1aqfw3gmp6h9e2ggtpmm7q245dwnyjggq7n32ag',
	'secret1dfdv860jwy0zq686zcaejl0s0c3axwgxy6xcc0',
	'secret1w9h347n4v32r9sw07un6datrjwnl2c7kqgucm6',
];

function mulberry32(i_seed: number) {
	return {
		rand() {
			let x_t = i_seed += 0x6D2B79F5;
			x_t ^= x_t + Math.imul(x_t ^ x_t >>> 7, x_t | 61);
			x_t = Math.imul(x_t ^ x_t >>> 15, x_t | 1);
			return ((x_t ^ x_t >>> 14) >>> 0) / 4294967296;
		},
		range(x_lo: number, x_hi: number) {
			return (this.rand() * (x_hi - x_lo)) + x_lo;
		},
		irange(n_lo: number, n_hi: number) {
			return Math.round(this.range(n_lo, n_hi));
		}
	}
}


function hash_string(s_in: string) {
	if(!s_in) return 0;
	let xc_hash = 0;
	for(let i_chr=0, nl_chrs=s_in.length; i_chr<nl_chrs; i_chr++) {
		xc_hash = ((xc_hash << 5) - xc_hash) + s_in.codePointAt(i_chr)!;
		xc_hash |= 0;
	}
	return xc_hash;
}

const XT_ELDEST = (new Date(2022, 0, 2)).getTime();
const XT_SECONDS = 1e3;
const XT_MINUTES = 60 * XT_SECONDS;
const XT_HOURS = 60 * XT_MINUTES;
const XT_DAYS = 24 * XT_HOURS;

export const A_CONTACTS = Object.values(H_CONTACTS);

export const A_TXNS: Txn[] = [];

export function buildTxnHistory(k_token: Token, sa_owner: string): Txn[] {
	if(k_token.def.data?.defi_pair_1) return [];

	const i_seed = hash_string(H_CHAINS[k_token.def.chainRef].def.id+':'+k_token.def.address);
	const k_prng = mulberry32(i_seed);

	const nl_decimals = k_token.def.decimals;
	const k_holding = H_HOLDINGS[Holding.refFromTokenAccount(k_token.def.iri, sa_owner)];

	const xg_balance = k_holding?.def.balance || 0;
	const x_balance = Number(xg_balance);

	const a_txns = [];

	let xt_when = k_prng.range(XT_ELDEST, XT_ELDEST+5*XT_DAYS);

	const nl_txns = k_prng.irange(4, 12);
	for(let i_txn=0; i_txn<nl_txns; i_txn++) {
		const n_expo = Math.pow(10, -k_prng.irange(0, nl_decimals / 4));
		const xg_amount = BigInt(Math.round(x_balance * k_prng.rand() * n_expo));

		const b_send = k_prng.rand() > 0.5;

		const sa_other = k_prng.rand() < 0.7
			? A_UNLISTED[k_prng.irange(0, A_UNLISTED.length-1)]
			: A_CONTACTS[k_prng.irange(0, A_CONTACTS.length-1)].address(K_DEFAULT_CHAIN);

		if(k_token.def.native) {
			a_txns.unshift(new Txn(Txn.Def.fromConfig({
				chainRef: k_token.def.chainRef,
				type: b_send? Txn.Type.SEND: Txn.Type.RECV,
				// token: k_token,
				amount: xg_amount,
				address: sa_other,
				txnId: uuid_v4(),
				timestamp: xt_when,
			})));
		}
		else {
			a_txns.unshift(new Txn(Txn.Def.fromConfig({
				type: Txn.Type.SNIP20_XFER,
				token: k_token,
				data: {
					id: '',
					coins: {
						denom: '',
						amount: xg_amount+'',
					},
					...b_send
						? {
							from: sa_owner,
							sender: sa_owner,
							receiver: sa_other,
						}
						: {
							from: sa_other,
							sender: sa_other,
							receiver: sa_owner,
						},
				},
				address: sa_other,
				txnId: uuid_v4(),
				timestamp: xt_when,
			})));
		}

		xt_when += k_prng.range(XT_DAYS, 9*XT_DAYS);
	}

	return a_txns;
}

export const H_TXNS: Record<Token.Ref, Txn[]> = oderom(H_TOKENS, (p_token, k_token) => ({
	[p_token]: buildTxnHistory(k_token, SA_ACCOUNT_DEFAULT),
}));

for(const p_token in H_TXNS) {
	const a_txns = H_TXNS[p_token as Token.Ref];
	for(const k_txn of a_txns) {
		A_TXNS.push(k_txn);

		const g_bankish = k_txn.bankish(SA_ACCOUNT_DEFAULT);
		if(g_bankish) {
			const p_addr = g_bankish.address;

			// contact
			if(p_addr in H_ADDR_TO_CONTACT) {
				const k_contact = H_ADDR_TO_CONTACT[p_addr];
				k_contact.history.push(g_bankish);
			}
		}
	}
}


const D_INTL_USD = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency:'USD',
	currencyDisplay: 'symbol',
});

const D_INTL_USD_LT1 = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency:'USD',
	currencyDisplay: 'symbol',
	minimumFractionDigits: 2,
	maximumFractionDigits: 5,
});

const A_NUMERIC_GT1 = [
	{
		order: 1e21,
		suffix: 'sept',
		metric: 'yotta',
		m: 'Y',
	},
	{
		order: 1e21,
		suffix: 'sext',
		metric: 'zetta',
		m: 'Z',
	},
	{
		order: 1e18,
		suffix: 'quint',
		metric: 'exa',
		m: 'E',
	},
	{
		order: 1e15,
		suffix: 'quad',
		metric: 'peta',
		m: 'P',
	},
	{
		order: 1e12,
		suffix: 'tril',
		metric: 'terra',
		m: 'T',
	},
	{
		order: 1e9,
		suffix: 'bil',
		metric: 'giga',
		m: 'G',
	},
	{
		order: 1e6,
		suffix: 'mil',
		metric: 'mega',
		m: 'M',
	},
];
const A_NUMERIC_LT1 = [
	{
		order: 1e-24,
		suffix: 'septh',
		metric: 'yocto',
		m: 'y',
	},
	{
		order: 1e-21,
		suffix: 'sexth',
		metric: 'zepto',
		m: 'z',
	},
	{
		order: 1e-18,
		suffix: 'quinth',
		metric: 'atto',
		m: 'a',
	},
	{
		order: 1e-15,
		suffix: 'quadth',
		metric: 'femto',
		m: 'f',
	},
	{
		order: 1e-12,
		suffix: 'trilth',
		metric: 'pico',
		m: 'p',
	},
	{
		order: 1e-9,
		suffix: 'bilth',
		metric: 'nano',
		m: 'n',
	},
	{
		order: 1e-6,
		suffix: 'milth',
		metric: 'mirco',
		m: 'μ',
	},
	{
		order: 1e-3,
		suffix: 'thsth',
		metric: 'milli',
		m: 'm',
	},
];

const D_INTL_AMOUNT_LT1 = new Intl.NumberFormat('en-US', {
	notation: 'standard',
	maximumSignificantDigits: 6,
});

const D_INTL_AMOUNT_GT1 = new Intl.NumberFormat('en-US', {
	notation: 'standard',
	maximumFractionDigits: 3,
});

const D_INTL_AMOUNT_GT1E3 = new Intl.NumberFormat('en-US', {
	notation: 'standard',
	maximumSignificantDigits: 6,
});

const D_INTL_AMOUNT_I1E3 = new Intl.NumberFormat('en-US', {
	notation: 'standard',
	maximumSignificantDigits: 4,
});

export function format_amount(x_amount: number, b_shorter=false): string {
	// if(b_shorter) debugger;

	// zero
	if(0 === x_amount) return '0';

	// left side of deimcal
	if(x_amount >= 1e6) {
		for(const gc_abbr of A_NUMERIC_GT1) {
			if(x_amount >= gc_abbr.order) {
				return (x_amount / (gc_abbr.order / 1e3)).toPrecision(3)+' '+gc_abbr.suffix;
			}
		}
	}
	// right side of decimal
	else if(x_amount < 1) {
		for(const gc_abbr of A_NUMERIC_LT1) {
			if(x_amount <= gc_abbr.order) {
				return (x_amount * (gc_abbr.order * 1e3)).toPrecision(3)+' '+gc_abbr.metric;
			}
		}

		// less than 1
		return D_INTL_AMOUNT_LT1.format(x_amount);
	}
	
	// between 1k and 1M
	if(x_amount >= 1e3) {
		// make thousands shorter
		if(b_shorter) {
			return D_INTL_AMOUNT_I1E3.format(x_amount / 1e3)+' k';
		}

		return D_INTL_AMOUNT_GT1E3.format(x_amount)
	}

	// greater than 1
	return D_INTL_AMOUNT_GT1.format(x_amount);
}

export function format_fiat(x_amount: number, b_omit_sign=false, n_decimals=2): string {
	const s_formatted = x_amount < 1? D_INTL_USD_LT1.format(x_amount): D_INTL_USD.format(x_amount);

	return b_omit_sign? s_formatted.replace(/^[$]/, ''): s_formatted;
}

/**
 * returns the fiat equivalent of the given token amount
 */
export function amount_to_fiat(x_amount: number, k_token: Token, b_omit_sign=false): string {
	return format_fiat(H_VERSUS_USD[k_token.def.iri].value * x_amount, b_omit_sign);
}


console.log({
	H_ACCOUNTS,
	H_CHAINS,
	H_CONTACTS,
	H_FAMILIES,
	H_HOLDINGS,
	H_IBCTS,
	H_NETWORKS,
	H_NFTS,
	H_TAGS,
	H_TOKENS,
	H_TXNS,
});
