<script lang="ts">
	import {quintOut} from 'svelte/easing';
import { push_screen, yw_chain, yw_family } from "#/app";

import SX_DOTS from '#/asset/nav/more-vert.svg?raw';
import SX_EDIT from '@material-design-icons/svg/outlined/edit.svg?raw';
import SX_SEND from '@material-design-icons/svg/outlined/upload.svg?raw';
import SX_DELETE from '@material-design-icons/svg/outlined/delete.svg?raw';

import type { Contact } from "#/objects/contact";
import ContactView from "#/screen/ContactView.svelte";


	import SX_PERSONAL from '@material-design-icons/svg/outlined/account_box.svg?raw';
	import SX_CONTRACT from '@material-design-icons/svg/outlined/analytics.svg?raw';

	import { H_CONTACTS, H_ICONS, H_TAGS } from "#/sim/data";
import { ode } from "#/util/belt";
import { slide } from "svelte/transition";
import Address from "./Address.svelte";
import Row from "./Row.svelte";
import Put from "./Put.svelte";
import type { Hash, JsonPrimitive, JsonValue } from '#/util/types';
import { Icon } from '#/objects/icon';
import type { Addressable } from '#/objects/_core';
import Tags from './Tags.svelte';
import ContactEdit from '#/screen/ContactEdit.svelte';
import Send from '#/screen/Send.svelte';
import DeadEnd from '#/screen/DeadEnd.svelte';

	export let filter: (k_contact: Contact) => boolean = (k) => true;
	export let sort: (k_a: Contact, k_b: Contact) => number = (k_a, k_b) => k_a.def.label < k_b.def.label? -1: 1;
	export let append: Contact[] = [];

	const a_list = (Object.values(H_CONTACTS) as Contact[])
		.filter(k => k.def.familyRef === $yw_family.def.iri)
		.filter(filter).concat(append).sort(sort);

	const hm_events = new WeakMap<Event, Hash<JsonPrimitive>>();

	let si_overlay = '';
	function activate_overlay(k_contact: Contact) {
		return (d_event: MouseEvent) => {
			// prevent event from bubbling
			d_event.stopImmediatePropagation();

			// ref entry id
			const si_set = k_contact.def.iri;

			// overlay already set to this entry; hide it
			if(hm_events.get(d_event)?.cancelMenu === si_set) {
				si_overlay = '';
				return;
			}

			// set overlay to this entry
			si_overlay = si_set;

			// remove on click event
			window.addEventListener('click', (d_event: MouseEvent) => {
				hm_events.set(d_event, {
					cancelMenu: si_overlay,
				});
				si_overlay = '';
			}, {
				capture: true,
				once: true,
			});
		};
	}

	const a_overlay_actions: {
		label: string;
		icon: string;
		click(k_contact: Contact): void;
	}[] = [
		{
			label: 'Edit',
			icon: SX_EDIT,
			click(k_contact: Contact) {
				push_screen(ContactEdit, {
					contact: k_contact,
				});
			},
		},
		{
			label: 'Send',
			icon: SX_SEND,
			click(k_contact: Contact) {
				push_screen(Send, {
					to: k_contact.address($yw_chain),
				});
			},
		},
		{
			label: 'Delete',
			icon: SX_DELETE,
			click(k_contact: Contact) {
				push_screen(DeadEnd);
			},
		},
	];
</script>

<style lang="less">
	@import '../style/util.less';

	.rows {
		margin-left: calc(0px - var(--ui-padding));
		margin-right: calc(0px - var(--ui-padding));

		.row {
			.status {
				:global(&) {
					position: relative;
				}

				.icon.more-menu {
					:global(&) {
						padding-top: 50%;
						padding-bottom: 50%;
						--icon-diameter: 24px;
						--icon-color: var(--theme-color-primary);
						outline: 1px solid transparent;
						transition: 350ms outline-color var(--ease-out-cubic);
					}

					:global(&:hover) {
						outline-color: var(--theme-color-border);
					}

					:global(&:active), :global(&.active) {
						outline-color: var(--theme-color-primary);
					}
				}

				.overlay {
					:global(&) {
						position: absolute;
						padding: 10px 14px;
						background-color: rgba(0, 0, 0, 0.8);
						border-radius: 8px;
						right: 26px;
						top: -18px;
						min-width: 120px;
						z-index: 100;
					}

					>.action {
						:global(&) {
							display: flex;
							padding: 10px 8px;
						}

						>.text {
							:global(&) {
								padding-left: 10px;
							}
						}
					}

					.icon {
						:global(&) {
							--icon-diameter: 24px;
							--icon-color: var(--theme-color-primary);
						}
					}
				}
			}
		}
	}

	.icon.contact-type {
		--icon-diameter: 16px;
		--icon-color: var(--theme-color-text-med);
	}

	.pfp-gen {
		.font(huge);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		border-radius: 20%;
		outline: 1px solid var(--theme-color-primary);
		background: radial-gradient(ellipse farthest-side at bottom right, #07080a, #0f1317);
	}
</style>

<div class="rows">
	{#each a_list as k_addr}
		{@const gd_contact = k_addr.def}

		<Row
			iconRef={gd_contact.iconRef}
			tagRefs={gd_contact.tagRefs}
			name={gd_contact.label}
			on:click={(d_event) => {
				if(!hm_events.get(d_event)?.cancelMenu) {
					push_screen(ContactView, {
						contact: k_addr,
					});
				}
			}}
		>
			<svelte:fragment slot="icon">
				{#if H_ICONS[gd_contact.iconRef]}
					<Put element={H_ICONS[gd_contact.iconRef].render()} />
				{:else}
					<span class="pfp-gen">
						{gd_contact.label[0]}
					</span>
				{/if}
			</svelte:fragment>

			<svelte:fragment slot="detail">
				<Address address={k_addr.address($yw_chain)} />
			</svelte:fragment>

			<svelte:fragment slot="tags">
				<Tags collapsed rootStyle='margin: 0px;'
					tags={gd_contact.tagRefs.map(p => H_TAGS[p])}
				>
					<span class="icon contact-type" slot="prefix">
						{@html SX_PERSONAL}
					</span>
				</Tags>
			</svelte:fragment>

			<svelte:fragment slot="status">
				<span
					class="icon more-menu"
					class:active={si_overlay === k_addr.def.iri}
					on:click={activate_overlay(k_addr)}
				>
					{@html SX_DOTS}
				</span>

				{#if si_overlay === k_addr.def.iri}
					<span class="overlay" transition:slide={{duration:300, easing:quintOut}}>
						{#each a_overlay_actions as g_action}
							<div class="action clickable" on:click={() => g_action.click(k_addr)}>
								<span class="icon">
									{@html g_action.icon}
								</span>

								<span class="text">
									{g_action.label}
								</span>
							</div>
						{/each}
					</span>
				{/if}
			</svelte:fragment>
		</Row>
	{/each}
</div>