import type { Plural } from "#/util/types";

export const P_WISPR_ROOT = 'wispr://root/';

export type WisprPath = `/${string}`;

export type WisprUri<
	sr_path extends string=string,
> = `wispr://root/${sr_path}`;

export type WisprRef = WisprPath | WisprUri;


const R_AFTER_PATH = /[?#].*$/;

const R_WISPR_ORIGIN = /^wispr:\/\/root\//;

export function wispr_path(sx_ref: WisprRef): WisprPath {
	return sx_ref.replace(R_WISPR_ORIGIN, '/').replace(R_AFTER_PATH, '') as WisprPath;
}


export type WisprResUri<
	s_class extends string,
> = `${typeof P_WISPR_ROOT}${Plural<s_class>}/${string}/`;


const R_PARSE_PATH_CHAIN = /^\/(\w+)\/([^/?#:]+)(.*)$/;

export function parse_path_pair(sr_path: string, si_key: string): [string, string] {
	const m_chain = R_PARSE_PATH_CHAIN.exec(sr_path);

	if(!m_chain) throw new Error(`Failed to parse ${si_key} from path: <${sr_path}>`);
	
	if(si_key !== m_chain[1]) throw new Error(`Mismatched pair key: '${si_key}' !== '${m_chain[1]}'`);

	return [m_chain[2], m_chain[3]];
}

