import type {
	Hash,
} from "#/util/types";

import {
	P_WISPR_ROOT,
	type WisprPath,
	type WisprRef,
	type WisprUri,
} from "./path";


export enum ResourceType {
	OBJECT = 'object',
	SCREEN = 'screen',
}

export interface WisprObject {

}

export type WisprScreen<
	Params extends {}={},
	Events extends {}={},
	Slots extends {}={},
> = SvelteComponentConstructor<
	Svelte2TsxComponent<Params, Events, Slots>,
	Svelte2TsxComponentConstructorParameters<Params>
>;


export interface AnyResource {
	type: ResourceType;
};

export interface ObjectResource extends AnyResource {
	type: ResourceType.OBJECT;
	object: WisprObject;
}

export interface ScreenResource extends AnyResource {
	type: ResourceType.SCREEN;
	screen: WisprScreen;
}

export type Resource = ObjectResource | ScreenResource;


type RouteDescriptor = {
	[x: WisprPath]: RouteDescriptor;
} & {
	// '?'?(h_params: Hash): undefined | null | WisprRef;
	'='?(h_params: Hash): undefined | null | WisprRef;
	$?: WisprScreen;
	_?: WisprObject;
};

export interface Problem {
	url: URL;
	base: WisprUri;
	path: WisprPath;
	pattern: WisprPath;
	params: Hash;
}

export interface Resolution<
	dc_resource extends Resource=Resource,
> extends Problem {
	resource: dc_resource;
}

export type ResolutionAttempt = null | Resolution;


interface RoutePattern {
	id: string;
	path: string | RegExp;
	node: Router;
}


const R_PATTERN_QUERY = /^(.+?)(?:\?(.*))?$/;
const R_EXACT_PATH = /^\/([\w-.]*)$/;
const R_PATTERN_PATH = /^\/(?:\{((\w+Id)|(\w+Num))\})$/;

// interface RouterNode {
// 	'?'?(h_params: Hash): undefined | null | string;
// 	'='?(h_params: Hash): undefined | null | string;
// 	_?: WisprObject,
// 	$?: WisprScreen,
// }

const R_PATH_PART_0 = /^\/([^/?#:]+)(.*)$/;

interface RouterConfig {
	descriptor: RouteDescriptor;
	parent: Router | null;
	pattern: string;
}

export class Router {
	static parse(h_node: RouteDescriptor, k_parent: Router|null=null, sx_pattern: string=''): Router {
		// const k_route = new Router(h_node, k_root, a_path_parts);
		const k_route = new Router({
			descriptor: h_node,
			parent: k_parent,
			pattern: sx_pattern,
		});

		delete h_node._;
		delete h_node.$;
		// delete h_node['?'];
		delete h_node['='];

		for(const sx_route in h_node) {
			k_route.add(sx_route, h_node[sx_route as WisprPath]);
		}

		return k_route;
	}

	protected _k_parent: Router | null;
	protected _sx_pattern: string;

	protected _g_object?: WisprObject;
	protected _dc_screen?: WisprScreen;

	protected _f_redirect?: (h_params: Hash) => undefined | null | WisprRef;

	protected _a_routes: RoutePattern[] = [];
	protected _r_router: RegExp = /^\b$/;

	protected _hm_rmap: Map<WisprScreen, Router[]> = new Map();


	constructor(gc_router: RouterConfig) {
		// save parent
		this._k_parent = gc_router.parent;

		// save pattern
		this._sx_pattern = gc_router.pattern;

		// ref descriptor
		const h_desc = gc_router.descriptor;

		// object pair
		const g_object = this._g_object = h_desc._;

		// screen pair
		const dc_screen = this._dc_screen = h_desc.$;

		// redirect pair
		this._f_redirect = h_desc["="];

		// screen is present
		if(dc_screen) {
			const hm_rmap = this.root._hm_rmap;
			const a_locations = hm_rmap.get(dc_screen);

			if(a_locations) {
				a_locations.push(this);
			}
			else {
				hm_rmap.set(dc_screen, [this]);
			}
		}
	}

	get parent(): Router | null {
		return this._k_parent;
	}

	get root(): Router {
		return null === this._k_parent? this: this._k_parent.root;
	}

	get part_pattern(): string {
		return this._sx_pattern;
	}

	get path_pattern(): string {
		if(!this._k_parent) {
			return '';
		}
		// else if('/' === this.part_pattern) {
		// 	return this._k_parent.path_pattern;
		// }
		else {
			return `${this._k_parent.path_pattern}${this.part_pattern}`;
		}
	}

	lookup_screen(dc_screen: WisprScreen): Router {
		const a_routers = this.root._hm_rmap.get(dc_screen);

		if(!a_routers?.length) {
			throw new Error(`Failed to locate screen in router: ${dc_screen.name}`);
		}
		else if(a_routers.length > 1) {
			throw new Error(`More than one path corresponds to screen: ${dc_screen.name}`);
		}

		return a_routers[0];
	}

	reverse_resolve(h_params: Hash): WisprPath {
		// root; exit
		if(!this.parent) {
			return '/';
		}

		// ref pattern
		const sx_pattern = this.path_pattern;

		// rebuild path
		const a_build = [];

		// each part
		const a_parts = sx_pattern.split(/(?=\/)/g);
		for(const s_part of a_parts) {
			// pattern
			const m_pattern = R_PATTERN_PATH.exec(s_part);
			if(m_pattern) {
				// ref part id
				const si_part = m_pattern[1];

				// lookup value
				const z_value = h_params[si_part];

				// invalid
				if(!z_value || 'string' !== typeof z_value) {
					throw new Error(`Invalid param value supplied for ${si_part} which should be a non-empty string but instead got: ${z_value}`);
				}

				// push to build
				a_build.push(z_value)
			}
			// not pattern
			else {
				const m_exact = R_EXACT_PATH.exec(s_part);
				if(m_exact && m_exact[1]) {
					// push as-is
					a_build.push(m_exact[1]);
				}
				else {
					debugger;
					throw new Error(`Failed to resolve internal route path pattern`);
				}
			}
		}

		// output
		return `/${a_build.join('/')}`;
	}

	protected get resource(): null | Resource {
		// object
		if(this._g_object) {
			return {
				type: ResourceType.OBJECT,
				object: this._g_object,
			};
		}
		// screen
		else if(this._dc_screen) {
			return {
				type: ResourceType.SCREEN,
				screen: this._dc_screen,
			};
		}
		// no resource
		else {
			return null;
		}
	}

	protected _thru(g_route: RoutePattern, sr_rest: string, g_problem: Problem, sr_part: string, sx_part: string): ResolutionAttempt {
	// a_parts: string[], h_params: Hash, du_resource: URL, part: string): null | Resolution {

		// end of route
		if(!R_PATH_PART_0.test(sr_rest)) {
			// update problem
			// @ts-expect-error template type concat
			g_problem.path += sr_part;
			// @ts-expect-error template type concat
			g_problem.pattern += sx_part;

			// redirect
			if(this._f_redirect) {
				const p_new = this._f_redirect(g_problem.params);

				if(p_new) {
					return this.root.resolve(p_new);
				}
			}

			// fetch resource
			const g_resource = g_route.node.resource;

			// unroutable
			if(!g_resource) return null;

			// resolve
			return {
				...g_problem,
				resource: g_resource,
			};
		}
		// more
		else {
			// update problem
			// @ts-expect-error template type concat
			g_problem.path += `${sr_part}/`;
			// @ts-expect-error template type concat
			g_problem.pattern += `${sx_part}/`;

			return g_route.node._route(sr_rest, g_problem);
		}
	}

	resolve(sr_ref: WisprRef, p_base: WisprUri=P_WISPR_ROOT): ResolutionAttempt {
		// attempt to parse URL
		let du_resource!: URL;
		try {
			du_resource = new URL(sr_ref, p_base);
		}
		catch(e_parse) {
			console.error(`Invalid URL reference: <${sr_ref}> using base IRI <${p_base}>`);
			return null;
		}

		// must be wispr wallet
		if('wispr:' !== du_resource.protocol) return null;

		// destructure url
		const {
			pathname: p_path,
			searchParams: du_params,
		} = du_resource;

		// invalid path
		if(!p_path.startsWith('//root/')) return null;

		// split path into parts
		const sr_path = '/'+p_path.replace(/^\/\/root\/|\/+$/g, '');

		// convert params into object
		const h_params = Object.fromEntries(du_params.entries());

		// route
		return this._route(sr_path, {
			url: du_resource,
			base: p_base,
			path: '/',
			pattern: '/',
			params: h_params,
		});
	}

	protected _route(sr_path: string, g_problem: Problem): ResolutionAttempt {
		// first path part
		const m_part_0 = R_PATH_PART_0.exec(sr_path);

		// nothing
		if(!m_part_0) return null;

		// deref strings
		const [, s_part_0, sr_rest] = m_part_0;

		// each route
		for(const g_route of this._a_routes) {
			// ref route path
			const z_path = g_route.path;

			// exact path part
			if('string' === typeof z_path) {
				// matched
				if(z_path === s_part_0) {
					return this._thru(g_route, sr_rest, g_problem, s_part_0, s_part_0);  // a_parts, h_params, du_resource, s_part_0);
				}
			}
			// path pattern
			else if(z_path instanceof RegExp) {
				// matched
				if(z_path.test(s_part_0)) {
					// bind value to id
					g_problem.params[g_route.id] = s_part_0;

					return this._thru(g_route, sr_rest, g_problem, s_part_0, `{${g_route.id}}`); // a_parts, h_params, du_resource, `{${g_route.id}}`);
				}
			}
		}

		// nothing matched
		return null;
	}


	add(sx_pattern: string, h_node: RouteDescriptor) {
		// parse query from end
		const m_query = R_PATTERN_QUERY.exec(sx_pattern);

		// failed to match
		if(!m_query) {
			debugger
			throw new SyntaxError(`Invalid path pattern ${sx_pattern}`);
		}

		// destructure
		const [, sx_part, sx_query] = m_query;

		// query is present
		let h_query = null;
		if(sx_query) {
			h_query = Object.fromEntries(new URLSearchParams(sx_query).entries());
		}

		// ref routes
		const a_routes = this._a_routes;

		// prep route
		let si_route = '';
		let w_path: string | RegExp;


		// parse pattern
		const m_pattern = R_PATTERN_PATH.exec(sx_part);
		if(m_pattern) {
			// destructure
			const [, , s_id, s_num] = m_pattern;

			// id pattern
			if(s_id) {
				si_route = s_id;
				w_path = /^[\w-]+$/;
			}
			// none?
			else {
				throw new SyntaxError(`Failed to capture any groups in Route`);
			}
		}
		// not pattern
		else {
			// parse exact
			const m_exact = R_EXACT_PATH.exec(sx_part);
			if(m_exact) {
				si_route = w_path = m_exact[1];
			}
			else {
				debugger;
				throw new SyntaxError(`Invalid path pattern ${sx_pattern}`);
			}
		}

		// construct child
		const k_child = Router.parse(h_node, this, sx_part);

		// push route
		a_routes.push({
			id: si_route,
			path: w_path,
			node: k_child,
		});
	}
}
