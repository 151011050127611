<script lang="ts">
	import BigNumber from 'bignumber.js';

	import SX_SEND from '#/asset/action/send.svg?raw';
	import SX_RECV from '#/asset/action/recv.svg?raw';

	// import {definition} from '@fortawesome/free-solid-svg-icons/faRobot';
	// const SXP_ROBOT = definition.icon[4];
	const SXP_ROBOT = '';

	import SX_NORTH_EAST from '@material-design-icons/svg/filled/north_east.svg?raw';
	import SX_EDIT from '@material-design-icons/svg/filled/edit.svg?raw';
	import SX_INFO from '@material-design-icons/svg/outlined/info.svg?raw';

	import SX_PERSONAL from '@material-design-icons/svg/outlined/account_box.svg?raw';
	import SX_CONTRACT from '@material-design-icons/svg/outlined/analytics.svg?raw';

	import {
		ode,
	} from '#/util/belt';

	import {
goto_screen,
		push_ref,
		push_screen,
		restart,
		yw_account,
		yw_chain,
yw_path,
yw_pattern,
yw_task,
	} from '#/app';

	import {
		amount_to_fiat,
		format_amount,
		format_fiat,
		H_ACCOUNTS,
		H_ADDR_TO_CONTACT,
		H_ADDR_TO_CONTRACT,
		H_CHAINS,
		H_CONTRACTS,
		H_FAMILIES,
		H_IBCTS,
		H_TXNS,
		H_VERSUS_USD,
	} from '#/sim/data'

	import {
		Screen,
		Row,
		Tags,
		Put,
		Header,
		Portrait,
	} from '#/ui';

	import {
		H_ICONS,
		H_TOKENS,
		H_HOLDINGS,
		buildTxnHistory,
		H_TAGS,
	} from '#/sim/data';

	import {
		Icon,
		Token,
		Ibct,
		Txn,
		Holding,
	} from '#/objects';

	import { Chain } from '#/objects/chain';
	import type { ActionConfig, ActionId, Actions } from '#/ui/Portrait.svelte';
	import Send from './Send.svelte';
	import { Contact } from '#/objects/contact';
	import Address from '#/ui/Address.svelte';
	import TokenEdit from './TokenEdit.svelte';
	import DeadEnd from './DeadEnd.svelte';
import Holdings from './Holdings.svelte';
import { Tasks } from '#/component/Simulator.svelte';

	export let holding: Holding;
	export const {chainId, tokenId} = Token.parseRef(holding.def.tokenRef);

	const k_holding = holding;
	const gd_holding = k_holding.def;
	const k_token = k_holding.token(H_TOKENS);
	export const familyId = H_FAMILIES[k_token.familyRef].def.id;

	const k_chain = H_CHAINS[Chain.refFromFamilyId(k_token.familyRef, chainId)];
	export const accountId = Object.values(H_ACCOUNTS).find((k) => k.address(k_chain) === holding.def.holderAddr)?.def.id || $yw_chain.def.id;

	const gd_token: Token.Def = k_token.def;
	const p_token = gd_token.iri;
	const s_symbol = gd_token.symbol;
	
	const x_amount = k_holding.approx(H_TOKENS);
	const s_usd = amount_to_fiat(x_amount, k_token);

	const x_versus_usd = H_VERSUS_USD[p_token].value;

	// const k_account = $yw_account;
	// yw_account.subscribe((_k_account) => {
	// 	if($yw_pattern.endsWith('/tokens/{tokenId}/holdings/{accountId}/view') && k_account.def.pubkey !== _k_account.def.pubkey) {
	// 		restart();
	// 	}
	// });

	const a_history = H_TXNS[p_token];

	const H_TXN_ICONS = {
		[Txn.Type.UNKN]: Icon.BLANK,
		[Txn.Type.SEND]: Icon.fromHtml(SX_SEND, {class:'icon-20'}),
		[Txn.Type.RECV]: Icon.fromHtml(SX_RECV, {class:'icon-20'}),
		[Txn.Type.COMP]: Icon.fromHtml(`<svg><path d="${SXP_ROBOT}"/></svg>`, {class:'icon-20'}),
		[Txn.Type.SNIP20_XFER]: Icon.fromHtml(SX_RECV, {class:'icon-20'}),
	} as Record<Txn.Type | Txn.BankishType, Icon>;

	const H_SUMMARIZERS = {
		[Txn.Type.UNKN]: (_) => 'Unknown',
		[Txn.Type.SEND]: (k) => `Send on ${k.date()}`,
		[Txn.Type.RECV]: (k) => `Receive on ${k.date()}`,
		[Txn.Type.COMP]: (k) => `Compute on ${k.date()}`,
	} as Record<Txn.Type | Txn.BankishType, (k_txn: Txn) => string>;

	const H_TXN_CLASSES = {
		[Txn.Type.SEND]: 'color-icon-send',
		[Txn.Type.RECV]: 'color-icon-recv',
	} as Record<Txn.Type | Txn.BankishType, string>;

	const gc_actions: Actions = {
		send: {
			label: 'Send',
			trigger() {
				push_screen(Send, {
					token: k_token,
				});
			},
		},
	};

	// // home token
	const k_ibct_native = H_IBCTS[Ibct.refFromHomeToken(gd_token.iri)];
	if(k_ibct_native) {
		gc_actions.wrap = {
			label: 'Wrap',
			trigger() {
				push_screen(DeadEnd);
			},
		};
	}

	// colony token
	if(k_token.ibct) {
		gc_actions.unwrap = {
			label: 'Unwrap',
			trigger() {
				push_screen(DeadEnd);
			},
		};
	}

	// non-native token
	if(!gd_token.native) {
		Object.assign(gc_actions, {
			edit: {
				label: 'Edit',
				trigger() {
					push_screen(TokenEdit, {
						token: k_token,
					});
				},
			},
		});
	}

	function detail_bankish(g_bankish?: Txn.Bankish | null): {prefix:string, name:string, icon:string} {
		if(!g_bankish) return {prefix:'', name:'', icon:''};
		
		const k_contact = H_ADDR_TO_CONTACT[g_bankish.address];
		
		return {
			prefix: (Txn.BankishType.SEND === g_bankish.type? 'to': 'fr')+':',
			name: k_contact? k_contact.def.label: '',
			icon: k_contact
				? Contact.Type.PERSON === k_contact.def.type
					? SX_PERSONAL
					: SX_CONTRACT
				: '',
		};
	}

	
	const a_allowances = gd_token.allowances.map((g_allowance) => {
		const k_spender = H_ADDR_TO_CONTRACT[g_allowance.spender];
		if(!k_spender) {
			debugger;
		}

		let s_amount;
		const yg_amount = new BigNumber(g_allowance.amount);
		if(yg_amount.isGreaterThan(new BigNumber('1000000000000000000'))) {
			s_amount = 'Limitless';
		}
		else {
			format_amount(k_token.approx(BigInt(g_allowance.amount)));
		}

		let s_expiry;
		const x_expires = g_allowance.expiration;
		if(x_expires) {
			const dt_when = new Date(x_expires * 1e3);
			
			s_expiry = dt_when.toLocaleDateString('en-US', {
				month: 'short',
				day: 'numeric',
				year: dt_when.getFullYear() !== (new Date()).getFullYear()? 'numeric': void 0,
			});
		}
		else {
			s_expiry = 'Never expires';
		}

		return {
			...g_allowance,
			k_spender,
			s_amount,
			s_expiry,
		};
	});

	if(Tasks.VERIFY === $yw_task && 'ATOM' === gd_token.symbol) {
		setTimeout(() => {
			$yw_task = -$yw_task;
		}, 1400);
	}
</script>

<style lang="less">
	@import '../style/util.less';

	.txns {
		.row .icon {
			:global(&) {
				color: var(--theme-color-text-light);
				border-radius: 32px;
			}
		}
	}

	.section {
		margin: 0;
		border-top: 6px solid black;
		border-bottom: 6px solid black;

		.bar {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: var(--ui-padding);

			.left {
				display: flex;
				flex-direction: column;
				gap: 0.5ex;

				>.title {
					.font(regular);
				}

				>.info {
					.font(tiny);
					color: var(--theme-color-text-med);
				}
			}

			.right {

			}
		}
	}

	.txn-type.icon {
		vertical-align: middle;
		--icon-diameter: 18px;
		--icon-color: var(--theme-color-text-med);
	}
</style>

<Screen nav slides>
	<Header pops account network
		name={gd_token.symbol}
		subname={gd_token.label}
	>
		<svelte:fragment slot="title">

		</svelte:fragment>
	</Header>

	<Portrait
		pfpIcon={H_ICONS[gd_token.iconRef]}
		title={`${x_amount} ${gd_token.symbol}`}
		subtitle={`${s_usd} (${format_fiat(x_versus_usd)} per token)`}
		actions={gc_actions}
		tags={k_token.def.tagRefs.map(p => H_TAGS[p])}
	/>

	<div class="txns no-margin">

		{#if k_ibct_native}
			<div class="section">
				<div class="bar">
					<span class="left">
						<div class="label">
							Stake
						</div>
						<div class="info">
							Earn up to 24% per year
						</div>
					</span>
					<span class="right">
						<button class="pill">
							Stake {gd_token.symbol}
						</button>
					</span>
				</div>
			</div>
		{:else if $yw_chain.def.id.startsWith('secret-')}
			<div class="section">
				<div class="bar" style={a_allowances.length? "border-bottom: 1px solid var(--theme-color-border);": ''}>
					<span class="left">
						<div class="label">
							Allowances ({gd_token.allowances.length})
						</div>
						<div class="info">
							Accounts allowed to spend this token
						</div>
					</span>
					<span class="right">
						<button class="pill" on:click={() => push_screen(DeadEnd)}>
							Manage
						</button>
					</span>
				</div>

				{#each a_allowances as g_allowance, i_allowance}
					<Row
						name={g_allowance.k_spender.def.label}
						address={g_allowance.k_spender.def.address}
						amount={g_allowance.s_amount}
						fiat={g_allowance.s_expiry}
						iconRef={g_allowance.k_spender.def.iconRef}
						iconClass='site'
						on:click={() => push_screen(DeadEnd)}
						rootStyle={i_allowance === a_allowances.length-1? 'border-bottom: none;': ''}
					>
					</Row>
				{/each}
			</div>
		{/if}

		{#each a_history as k_txn}
			{@const g_bankish = k_txn.bankish($yw_account.address($yw_chain))}
			{@const gd_txn = k_txn.def}
			{@const g_detail = detail_bankish(g_bankish)}

			{#if g_bankish}
				<Row
					icon={H_TXN_ICONS[g_bankish.type]}
					iconClass={H_TXN_CLASSES[g_bankish.type] || ''}
					name={H_SUMMARIZERS[g_bankish.type](k_txn)}
					address={g_bankish.address}
					detail={g_detail.name}
					prefix={g_detail.prefix}
					amount={format_amount(k_token.approx(g_bankish.amount))}
					fiat={amount_to_fiat(k_token.approx(g_bankish.amount), k_token)}
					on:click={() => push_screen(DeadEnd)}
				>
					<svelte:fragment slot="detail">
						{#if g_detail.icon}
							<span class="txn-type icon">
								{@html g_detail.icon}
							</span>
						{/if}
						{#if g_detail.name}
							{g_detail.name}
						{:else if g_bankish.address}
							<Address address={g_bankish.address} />
						{/if}
					</svelte:fragment>
				</Row>
			{:else}
				<Row
					icon={H_TXN_ICONS[gd_txn.type]}
					iconClass={H_TXN_CLASSES[gd_txn.type] || ''}
					name={H_SUMMARIZERS[gd_txn.type](k_txn)}
					address={gd_txn.address}
					amount={format_amount(k_token.approx(gd_txn.amount))}
					fiat={amount_to_fiat(k_token.approx(gd_txn.amount), k_token)}
					on:click={() => push_screen(DeadEnd)}
				/>
			{/if}
		{/each}
	</div>
</Screen>