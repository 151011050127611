import {
	ClassType,
	Definable,
} from './_core';

import {
	Icon,
	Family,
	Chain,
} from '#/objects';

type NetworkRef = Chain.MemberRef<ClassType.NETWORK>;

type NetworkPath = `families/${string}/chains/${string}/networks/${string}`;

interface NetworkDef extends Icon.Refable<NetworkPath> {
	class: ClassType.NETWORK,
	id: string;
	rpc: string;
	rest: string;
	chainRef: Chain.Ref;
};


interface NetworkConfig {
	id: string;
	family?: string;
	chain?: string;
	chainRef?: Chain.Ref;
	label: string;
	iconRef?: Icon.Ref;
}

class NetworkDef {
	static fromConfig(gc_network: NetworkConfig): NetworkDef {
		const p_chain = gc_network.chainRef || Chain.refFromFamilyId(Family.refFromId(gc_network.family!) || '.orphan', gc_network.chain!);

		return {
			class: ClassType.NETWORK,
			iri: Network.refFromChainId(p_chain, gc_network.id),
			chainRef: p_chain,
			id: gc_network.id,
			label: gc_network.label,
			rpc: '',
			rest: '',
			iconRef: gc_network.iconRef || Icon.BLANK.def.iri,
			tagRefs: [],
		};
	}

	// static parseRef(sr_ref: WisprRef): ChainParam {
	// 	const [si_chain, s_rest] = parse_path_pair(wispr_path(sr_ref), 'chains');

	// 	return {
	// 		chainId: si_chain,
	// 		rest: s_rest,
	// 	};
	// }
}

export class Network extends Definable<NetworkDef> {
	static refFromChainId(p_chain: Chain.Ref, si_network: string): NetworkRef {
		return `${p_chain}networks/${si_network}/`;
	}
}


export namespace Network {
	export type Ref = NetworkRef;
	export type Def = NetworkDef;
	export const Def = NetworkDef;
	export type Config = NetworkConfig;

	// export interface Resource<
	// 	s_path extends string=string,
	// > extends Icon.Refable<`${NetworkPath}/${s_path}`> {
	// 	address: string;
	// 	chainRef: Ref;
	// }
}
