<script lang="ts">
	import {
		yw_screen_dom,
		yw_pattern,
		yw_nav_collapsed,
		yw_path,
		yw_path_parts,
		yw_thread_id,
		ThreadId,
		peak,
		pop,
		goto_ref,
		restart,
		yw_nav_visible,
yw_menu_expanded,
yw_blur,
yw_overlay_network,
yw_overlay_account,
yw_notifications,
yw_chain,
yw_account,
	} from '#/app';

	import SX_EXPAND from '#/asset/nav/expand.svg?raw';
	import SX_TOKENS from '#/asset/nav/tokens.svg?raw';
	import SX_HISTORY from '#/asset/nav/history.svg?raw';
	import SX_NFT from '#/asset/nav/nfts.svg?raw';
	import SX_MENU from '#/asset/nav/menu.svg?raw';

	const H_BUTTONS = {
		[ThreadId.TOKENS]: {
			svg: SX_TOKENS,
			label: 'Tokens',
		},
		[ThreadId.NFTS]: {
			svg: SX_NFT,
			label: 'NFTs',
		},
		[ThreadId.HISTORY]: {
			svg: SX_HISTORY,
			label: 'History',
		},
		menu: {
			svg: SX_MENU,
			label: 'Menu',
		},
	} as Record<string, {
		svg: string;
		label: string;
	}>;

	function toggle_collapsed() {
		$yw_nav_collapsed = !$yw_nav_collapsed;
	}

	function nav_click(si_button: string) {
		// remove notification
		$yw_notifications = $yw_notifications.filter(si => si_button !== si);

		// blur is active
		if($yw_blur) {
			// cancel blur
			$yw_blur = false;
		}

		// network overlay is active
		if($yw_overlay_network) {
			$yw_overlay_network = false;
		}

		// account overlay is active
		if($yw_overlay_account) {
			$yw_overlay_account = false;
		}

		// depending on button click
		switch(si_button) {
			// menu
			case 'menu': {
				// expand menu
				$yw_menu_expanded = true;

				break;
			}

			// current thread head loaded
			case si_thread_head: {
				// scroll to top smoothly
				$yw_screen_dom.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});

				break;
			}

			// within this thread
			case $yw_thread_id: {
				// previous; pop
				const k_previous = peak();
				if(k_previous && H_HOMES[k_previous.pattern] === $yw_thread_id) {
					pop();
				}
				// current thread won't cause update
				else {
					restart();
				}

				break;
			}

			// switch thread
			default: {
				$yw_thread_id = si_button as ThreadId;

				break;
			}
		}
	}


	const H_HOMES: Record<string, ThreadId>  = {
		'/families/{familyId}/chains/{chainId}/holdings/{accountId}': ThreadId.TOKENS,
		'/families/{familyId}/chains/{chainId}/gallery/{accountId}': ThreadId.NFTS,
		'/contacts': ThreadId.CONTACTS,
		// '/networks': ThreadId.NETWORKS,
		'/accounts': ThreadId.ACCOUNTS,
		'/history': ThreadId.HISTORY,
	};

	let si_thread_head: '' | ThreadId = '';
	$: {
		const sx_pattern = $yw_pattern;
		
		si_thread_head = H_HOMES[sx_pattern] || '';
	}
</script>

<style lang="less">
	@import '../style/util.less';

	nav {
		--nav-height: 72px;

		position: absolute;
		bottom: 0;
		height: var(--nav-height);
		width: 100%;
		background-color: var(--theme-color-bg);
		display: block;
		z-index: 1000;

		transition: bottom 1s var(--ease-out-quick);

		display: none;
		&.visible {
			display: initial;
		}

		&.collapsed {
			bottom: calc(0px - var(--nav-height));

			>.collapse.icon>svg {
				:global(&) {
					transform: rotate(-180deg);
				}
			}
		}

		>.collapse.icon {
			--button-diameter: 32px;
			--icon-diameter: 24px;
			--svg-color-fg: var(--theme-color-text-light);

			border: 1px solid transparent;
			position: absolute;
			right: 0;
			top: calc(0px - var(--button-diameter));

			border-radius: 0;
			border-bottom-width: 0;
			border-color: var(--theme-color-border);
			box-sizing: border-box;
			background-color: var(--theme-color-bg);

			>svg {
				:global(&) {
					transform: rotate(0deg);
					transition: transform 1s var(--ease-out-quick);
				}
			}

			cursor: pointer;
		}

		>.bar {
			list-style: none;
			padding: 0;
			margin: 0;

			display: flex;
			text-align: center;

			border-top: 1px solid var(--theme-color-border);

			>.button {
				flex: 1;
				padding-top: 11px;
				padding-bottom: 12px;

				--icon-diameter: 24px;
				--svg-color-fg: var(--theme-color-text-light);

				cursor: pointer;

				&.active {
					&.tokens {
						--svg-color-fg: var(--theme-color-primary);
					}
					&.nfts {
						--svg-color-fg: var(--theme-color-primary);
					}
					&.history {
						--svg-color-fg: var(--theme-color-primary);
					}

					>.label {
						color: var(--theme-color-text-light);
					}
				}

				>.icon {
					.inherit(--icon-diameter);
				}

				.notification {
					position: absolute;
					width: 7px;
					height: 7px;
					background-color: var(--theme-color-sky);
					border-radius: 7px;
					margin-top: 15px;
					border: 2px solid var(--theme-color-bg);
					margin-left: -9px;
				}

				>.label {
					font-size: 11px;
					color: var(--theme-color-text-med);
				}
			}
		}
	}
</style>

<nav class:collapsed={$yw_nav_collapsed} class:visible={$yw_nav_visible}>
	<div class="collapse icon clickable" on:click={() => toggle_collapsed()}>
		{@html SX_EXPAND}
	</div>
	<ul class="bar">
		{#each Object.entries(H_BUTTONS) as [si_button, g_button]}
			<li class="button {si_button}" class:active={si_thread_head === si_button} on:click={() => nav_click(si_button)}>
				<div class="icon">
					{@html g_button.svg}

					<span class="notification" class:display_none={!$yw_notifications.includes(si_button)}></span>
				</div>
				<div class="label">
					{g_button.label}
				</div>
			</li>
		{/each}
		</ul>
</nav>