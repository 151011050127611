<script lang="ts">
	import { createEventDispatcher, onMount, tick } from "svelte";
	import { expoOut } from "svelte/easing";
	import { slide } from 'svelte/transition';
	import { yw_blur, yw_chain } from "#/app";

	import type {
		Chain,
	} from "#/objects";
	
	import SX_ADD from '#/asset/action/add-small.svg?raw';
	import SX_CHECKED from '#/asset/nav/checked-circle.svg?raw';

	import { H_CHAINS } from "#/sim/data";
	import { Row } from "#/ui";
	import { ode } from "#/util/belt";

	const dispatch = createEventDispatcher();

	export let title: string;
	export let open = true;

	$: $yw_blur = open;

	let b_showing = false;
	setTimeout(() => {
		b_showing = true;
	}, 10);

	function pause(dm: HTMLElement, gc: any) {
		return {
			duration: 200,
		};
	}
</script>

<style lang="less">
	@import '../style/util.less';

	.cancel {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		z-index: 5000;

		&.showing {
			>.overlay {
				opacity: 1;
				// height: auto;
				height: 320px;

				max-height: 320px;
			}
		}

		.overlay {
			position: absolute;
			top: 4em;
			right: 20px;
			z-index: 1000;
			width: 300px;
			padding-left: var(--ui-padding);
			padding-right: var(--ui-padding);
			padding-bottom: var(--ui-padding);

			background-color: rgba(0, 0, 0, 0.95);
			border-radius: 8px;
			max-height: 320px;
			display: flex;
			flex-direction: column;
			overscroll-behavior: contain;
			overflow: scroll;
			.hide-scrollbar();

			box-sizing: border-box;
			opacity: 0.25;
			transition: opacity 200ms linear, height 250ms var(--ease-out-expo);
			height: 0;

			>.top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				>h3 {
					margin: 16px 0;
				}

				>.add-new {
					>.icon {
						--icon-diameter: 8px;
						--icon-color: var(--theme-color-primary);
					}
				}
			}

			>.rows {
				display: flex;
				flex-direction: column;
				--row-padding: 12px;


				.overlay-select.icon {
					:global(&) {
						--icon-diameter: 20px;
						align-self: center;
					}
				}

				>.row {
					:global(&) {
						padding-top: var(--row-padding) !important;
						padding-left: 0 !important;
						padding-right: var(--row-padding) !important;
						padding-bottom: var(--row-padding) !important;
					}
				}
			}
		}
	}
</style>

<div class="cancel clickable"
	class:showing={b_showing}
	on:click={() => {
		b_showing = false;
		open = false;
	}}
>
	<div class="overlay select" out:pause>
		<div class="top">
			<h3>
				{title}
			</h3>

			<button class="pill add-new">
				<span class="icon">
					{@html SX_ADD}
				</span>

				<span class="text">
					Add New
				</span>
			</button>
		</div>

		<div class="rows">
			<slot name="rows"></slot>
		</div>
	</div>
</div>