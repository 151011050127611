<script lang="ts">
	import { goto_screen } from '#/app';

	import Init from './Init.svelte';

	import {
		Screen,
		Field,
	} from '#/ui';

	let s_password = '';
	let s_verify = '';

	$: b_password_okay = !!s_password && s_password === s_verify && s_password.length > 2;

	function focus_pass(d_event: FocusEvent) {
		const dm_input = d_event.target as HTMLInputElement;

		Object.assign(dm_input, {
			readOnly: true,
		});

		const s_name = dm_input.getAttribute('name');

		if('password' === s_name) {
			s_password = ' '.repeat(16);
		}
		else {
			s_verify = ' '.repeat(16);
		}

		dm_input.blur();
	}
</script>


<style lang="less">
	h3 {
		// margin-bottom: 4em;
		margin-bottom: 0;

		.lead {
			margin-top: 1em;
			margin-bottom: 0;
		}
		.title {
			letter-spacing: 1px;
			transform: scaleX(2);
			font-weight: 100;
			font-size: 27px;
			color: #d0d0d0;
			margin: 0;

			em {
				font-size: 32px;
				font-style: normal;
			}
		}
		.icon {
			--svg-color-fg: silver;
			// --svg-color-bg: #f52525;
			width: calc(100% - 60px);
			height: auto;

			img {
				:global(&) {
					width: 172px;
					height: 137px;
					margin: 10px 0;
				}
			}

			>svg {
				:global(&) {
					margin: 0;
				}
			}
		}
		
	}

	.narrow {
		color: var(--theme-color-text-med);
		font-weight: 300;
		max-width: 18em;
	}
</style>

<Screen leaves>
	<p slot="help">
		Passwords will auto-fill. {window.innerWidth <= 500? 'Tap this dialog to dismiss.': ''}
	</p>

	<center>
		<!-- <div>
			<Logo />
		</div> -->
		<h3>
			<div class="lead">
				Welcome to
			</div>
			<h1 class="title">
				<em>S</em>TAR<em>S</em>HELL
			</h1>
			<div class="icon">
				<!-- {@html SX_LOGO} -->
				<img src="/asset/vendor/logo-2x.png" />
			</div>
		</h3>
		<p class="narrow">
			Create a password for this machine to be able to import your existing account or create a new one.
		</p>
	</center>

	<div class="form flex-rows">
		<Field key="password" name="New password">
			<input name="password" type="password" placeholder="Password" bind:value={s_password} on:focus={focus_pass}>
		</Field>

		<Field key="verify-password" name="Verify password">
			<input name="verify" type="password" placeholder="Password" bind:value={s_verify} on:focus={focus_pass}>
		</Field>
	</div>

	<div class="action-line">
		<button disabled={!b_password_okay} class="primary" on:click={() => goto_screen(Init)}>
			Continue
		</button>
	</div>
</Screen>