<script lang="ts">
import { push_screen } from "#/app";

	import { H_CHAINS, H_ICONS, H_NETWORKS, H_TOKENS } from "#/sim/data";

	import {
		Header,
		Portrait,
		Row,
		Screen,
		SubHeader,
	} from "#/ui";
import { ode } from "#/util/belt";
import NetworkEdit from "./NetworkEdit.svelte";




	const a_networks = Object.values(H_NETWORKS)
		.sort((k_a, k_b) => k_a.def.chainRef.localeCompare(k_b.def.chainRef))
		.map((k_network) => {
			const gd_network = k_network.def;
			const k_chain = H_CHAINS[gd_network.chainRef];
			// const k_icon = H_ICONS[k_chain.native(H_TOKENS).def.iconRef];
			const k_icon = H_ICONS[gd_network.iconRef];

			return {
				object: k_network,
				def: gd_network,
				chain: k_chain,
				icon: k_icon,
			};
		});
</script>

<style lang="less">

</style>

<Screen nav root>
	<Header account network search
	>
		<svelte:fragment slot="title">

		</svelte:fragment>
	</Header>

	<SubHeader title="Networks and Chains"
	/>

	<!-- <Portrait
		pfpIcon={H_ICONS[gd_token.iconRef]}
		title={`${x_amount} ${gd_token.symbol}`}
		subtitle={`${s_usd} (${format_fiat(x_versus_usd)} per token)`}
		actions={gc_actions}
		tags={k_token.def.tagRefs.map(p => H_TAGS[p])}
	/> -->

	<div class="rows no-margin">
		{#each a_networks as g_network}
			<Row
				name={g_network.chain.def.label}
				detail={g_network.def.label}
				iconRef={g_network.def.iconRef}
				lockIcon={'default' === g_network.def.id}
				on:click={() => push_screen(NetworkEdit, {
					network: g_network.object,
				})}
			></Row>
		{/each}
	</div>

</Screen>