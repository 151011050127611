<script lang="ts">
	import { yw_account, yw_chain, yw_popup, yw_popup_account } from "#/app";

	import QRCode from 'qrcode-svg';

	import type { Account } from "#/objects/account";
	import { format_fiat, H_ACCOUNTS, H_CHAINS, H_HOLDINGS, H_TOKENS, H_VERSUS_USD } from "#/sim/data";

	import Select from "svelte-select";
	import Field from "./Field.svelte";
	import StarSelect from "./StarSelect.svelte";
import { onMount } from "svelte";
import Info from "./Info.svelte";
import Address from "./Address.svelte";
import Close from "./Close.svelte";
import type { Chain } from "#/objects/chain";

	const a_chains = Object.values(H_CHAINS).map((k_chn) => {
		return {
			object: k_chn,
			value: k_chn.def.id,
			primary: k_chn.def.label,
			secondary: k_chn.def.id,
			// value: k_chain.def.id,
		};
	});

	if($yw_account) $yw_popup_account = $yw_account;

	let k_chain = a_chains.find(g => g.value === $yw_chain.def.id)!;

	$: $yw_chain = k_chain.object;

	const a_accounts = Object.values(H_ACCOUNTS).filter(k => '*' !== k.def.id).map((k_acc) => {
		return {
			object: k_acc,
			value: k_acc.def.id,
			primary: k_acc.def.label,
			secondary: format_fiat(k_acc.holdings(H_HOLDINGS, k_chain.object)
				.reduce((c_sum, k_holding) => c_sum + k_holding.toUsd(H_TOKENS, H_VERSUS_USD), 0)),
		};
	});

	let k_account = a_accounts.find(g => g.value === $yw_popup_account?.def.id)!;

	$: $yw_popup_account = k_account.object;


	let dm_qr: HTMLElement;
	$: {
		if(dm_qr) {
			const y_qrcode = new QRCode({
				content: `starshell:${$yw_popup_account?.address(k_chain.object) || ''}`,
				width: 240,
				height: 240,
				padding: 3,
				ecl: 'H',
				join: true,
			}).svg();

			dm_qr.innerHTML = y_qrcode;
		}
	}

</script>

<style lang="less">
	@import '../style/util.less';

	.qr-code {
		width: 240px;
		height: 240px;
		border-radius: 8px;
		overflow: hidden;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}

	.info {
		.font(regular);
		text-align: center;
	}
</style>


<h3>
	Receive
</h3>

<Close absolute --margin='5px' on:click={() => $yw_popup = null} />

<Field short
	key="chain-select"
	name="Chain"
>
	<StarSelect id="chain-select"
		placeholder="Select chain"
		items={a_chains}
		bind:value={k_chain}
	/>
</Field>

<Field short
	key="account-select"
	name="Account"
>
	<StarSelect id="account-select"
		placeholder="Select account"
		secondaryClass='balance'
		items={a_accounts}
		bind:value={k_account}
	/>
</Field>

<Info key="receive-address">
	<Address copyable address={$yw_popup_account?.address(k_chain.object) || ''} />
</Info>


<div class="qr-code" bind:this={dm_qr} />

<div class="info">
	Scan QR code to receive to this address
</div>