import { ThreadId } from '#/app';
import { objects_might_differ, ode } from '#/util/belt';
import { dd, qsa } from '#/util/dom';
import type { Hash } from '#/util/types';
import type {
	SvelteComponent,
} from 'svelte';

import {
	derived,
	writable,
	type Readable,
	type Writable,
} from 'svelte/store';

import {
	wispr_path,
	type WisprPath,
	type WisprRef,
} from './path';

import type {
	Router,
	WisprScreen,
} from './router';


export interface WritableSync<T> extends Writable<T> {
	get(): T;
}

export function writableSync<T>(w_value: T): Writable<T> & {get(): T} {
	const yw_original = writable<T>(w_value);

	return Object.assign(Object.create(yw_original), {
		set(w_set: T) {
			w_value = w_set;
			return yw_original.set(w_set);
		},
		get(): T {
			return w_value;
		},
	});
}

export interface DerivedSync<T> extends Readable<T> {
	get(): T;
}

export function derivedSync<
	T,
	yw_src extends WritableSync<T>,
>(yw_src: yw_src, f_transform: (w_value: T) => T): Readable<T> & {get(): T} {
	if(Array.isArray(yw_src)) {
		return Object.assign(Object.create(derived<yw_src, T>(yw_src, f_transform)), {
			get(): T {
				return f_transform(yw_src);
			},
		});
	}
	else {
		return Object.assign(Object.create(derived<yw_src, T>(yw_src, f_transform)), {
			get(): T {
				return f_transform(yw_src.get());
			},
		});
	}
}

type UnknownObject = Record<string, unknown>;

let c_states = 0;

export class State {
	protected _sr_path: WisprPath;
	protected _sx_pattern: WisprPath;
	protected _yc_component: SvelteComponent;
	protected _gc_props: UnknownObject;

	protected _dm_state: HTMLElement;
	protected _i_state = c_states++;

	constructor(sr_path: WisprRef, yc_component: SvelteComponent, sx_pattern: WisprPath, gc_props: UnknownObject, dm_state: HTMLElement) {
		this._sr_path = wispr_path(sr_path);
		this._sx_pattern = sx_pattern || this._sr_path;
		this._yc_component = yc_component;
		this._gc_props = gc_props;
		this._dm_state = dm_state;
	}

	get id(): number {
		return this._i_state;
	}

	get path(): WisprPath {
		return this._sr_path;
	}

	get pattern(): WisprPath {
		return this._sx_pattern;
	}

	get component(): SvelteComponent {
		return this._yc_component;
	}

	get props(): UnknownObject {
		return this._gc_props;
	}

	get dom(): HTMLElement {
		return this._dm_state;
	}
}

export class StateThread {
	// protected _k_state_0: State;
	protected _a_history: State[] = [];

	protected _dm_thread: HTMLElement;

	protected _gc_default: NewStateConfig;

	constructor(si_thread: ThreadId, gc_default: NewStateConfig) {
		this._gc_default = gc_default;
		
		this._dm_thread = dd('div', {
			class: 'thread',
			'data-thread-id': si_thread,
			style: `z-index: 100;`,
		});

		// this._k_state_0 = k_state_0;

		// this._a_history = [
		// 	this._k_state_0,
		// ];
	}

	get default(): NewStateConfig {
		return this._gc_default;
	}

	get history(): State[] {
		return this._a_history;
	}

	get state(): State {
		return this._a_history[0];
	}

	get dom(): HTMLElement {
		return this._dm_thread;
	}

	reset(ks_new: State, b_keep_top=false) {
		// ref history
		const a_history = this._a_history;

		// // keep top is enabled; grab src style
		// const d_style = b_keep_top? a_history[0].dom.style: null;

		// drop all stale states in history
		for(let i_state=b_keep_top? 1: 0; i_state<a_history.length; i_state++) {
			a_history[i_state].component.$destroy();
		}
		
		// reset history
		a_history.length = 0;

		// push new state
		this.push(ks_new);

		// // keep top is enabled
		// if(d_style) {
		// 	// set new screen below
		// 	ks_new.dom.style.zIndex = ((+d_style.zIndex)+1)+'';
		// }
	}

	push(ks_new: State) {
		// append to dom
		this._dm_thread.appendChild(ks_new.dom);

		// push state to front of stack
		this._a_history.unshift(ks_new);
	}

	hide() {
		this._dm_thread.style.display = 'none';
	}

	show() {
		this._dm_thread.style.display = 'initial';
	}
}

export interface NewStateConfig {
	path: WisprPath | ((yc: SvelteComponent) => {path: WisprPath, params: {}});
	screen: WisprScreen;
	props: {};
	pattern?: WisprPath;
}

namespace Hooks {
	export interface Push {
		(this: StateManager, ks_src: State, ks_dst: State): void | Promise<void>;
	}

	export interface Pop {
		(this: StateManager, ks_src: State, ks_dst: State, b_bypass?: boolean): void | Promise<void>;
	}

	export interface Arrive {
		(this: StateManager, ks_src: State, k_dst: State, si_thread_src: string, s_transition: string): void | Promise<void>;
	}
}

interface ThreadSpawn {
	(h_params: Hash): NewStateConfig;
}

export interface ManagerConfig {
	container: HTMLElement;
	router: Router;
	threads: {default: ThreadSpawn} & Record<ThreadId, ThreadSpawn>;

	push?: Hooks.Push;
	pop?: Hooks.Pop;
	arrive?: Hooks.Arrive;
}

const F_NOOP = () => {};

function string_or_call<
	s_value extends string,
>(z_value: s_value | ((...args: any[]) => s_value), ...a_args: any[]) {
	return 'string' === typeof z_value? z_value: z_value(...a_args);
}

function set_zindex_relatively(dm_src: HTMLElement, dm_dst: HTMLElement, n_order: number) {
	const iz_src = +dm_src.style.zIndex;
	const iz_dst = iz_src + n_order;
	dm_src.style.zIndex = iz_src+'';
	dm_dst.style.zIndex = iz_dst+'';
}

export class StateManager {
	protected _h_threads: Partial<Record<ThreadId, StateThread>> = {};
	protected _h_thread_spawners: Record<ThreadId, ThreadSpawn>;
	protected _dm_threads!: HTMLElement;
	protected _k_router: Router;

	protected _dm_buffer = dd('div');

	protected _gc_manager: ManagerConfig;

	protected _f_push: Hooks.Push;
	protected _f_pop: Hooks.Pop;
	protected _f_arrive: Hooks.Arrive;

	protected _si_thread: ThreadId = ThreadId.DEFAULT;

	protected _c_thread_z = 200;

	constructor(gc_manager: ManagerConfig) {
		// save config
		this._gc_manager = gc_manager;

		// save thread container dom
		this._dm_threads = gc_manager.container;

		// save router
		this._k_router = gc_manager.router;

		// save hooks
		this._f_push = gc_manager.push || F_NOOP as Hooks.Push;
		this._f_pop = gc_manager.pop || F_NOOP as Hooks.Pop;
		this._f_arrive = gc_manager.arrive || F_NOOP as Hooks.Arrive;

		// set thread spawners
		this._h_thread_spawners = gc_manager.threads;

		// create new thread
		this._new_thread(ThreadId.DEFAULT);
	}

	_new_thread(si_thread: ThreadId, h_params: Hash={}): StateThread {
		// create new thread
		const kt_new = new StateThread(si_thread, this._h_thread_spawners[si_thread](h_params));
		
		// save to threads
		this._h_threads[si_thread] = kt_new;

		// append thread to container
		this._dm_threads.appendChild(kt_new.dom);

		// make new state from default and merge params
		const ks_default = this._new_state({
			...kt_new.default,
			props: {
				...kt_new.default.props,
				...h_params,
			},
		});

		// push to history
		kt_new.push(ks_default);

		// return new thread
		return kt_new;
	}

	alter_thread(si_thread: ThreadId, h_params: Hash={}): boolean {
		// ref current thread
		const kt_src = this.thread;

		// ref previous state
		let ks_src = this.state;

		// lookup existing thread
		let kt_dst = this._h_threads[si_thread];

		// thread change
		if(si_thread !== this._si_thread) {
			// prev thread
			const si_thread_prev = this._si_thread;

			// no existing thread; create new one
			if(!kt_dst) {
				this._new_thread(si_thread, h_params)
			}
			// params differ
			else if(objects_might_differ(kt_dst.default.props, h_params)) {
				// create new state
				const ks_dst = this._new_state({
					...kt_dst.default,
					props: h_params,
				});

				// reset thread history
				kt_dst.reset(ks_dst, true);

				// place incoming state below
				set_zindex_relatively(ks_src.dom, ks_dst.dom, -1);
			}

			// update thread
			this._si_thread = si_thread;

			// place thread in front
			this.thread.show()
			this.thread.dom.style.zIndex = (this._c_thread_z++)+'';

			// arrive
			this._arrive(ks_src, si_thread_prev, 'thread').then(() => {
				// // hide previous thread
				// if(kt_dst && kt_src && kt_src !== kt_dst) {
				// 	// debugger;
				// 	// kt_src.hide();
				// }
			});

			return true;
		}
		// // same thread
		// else {
		// 	// thread default is previous in history
		// 	debugger;
		// 	console.info(k_thread?.default.path);
		// }

		return false;
	}

	protected get thread(): StateThread {
		return this._h_threads[this._si_thread]!;
	}

	get history(): State[] {
		return this.thread.history
	}

	get state(): State {
		return this.thread.state;
	}

	protected get container(): HTMLElement {
		return this._dm_threads;
	}

	get location(): string {
		return this.state.path;
	}

	restart() {
		this.goto(this.thread.default, true);
	}

	reset_all_threads() {
		for(const [si_thread, kt_each] of ode(this._h_threads)) {
			const ks_default = this._new_state(kt_each.default);
			kt_each.reset(ks_default);
		}
	}

	spawn_component(dc_spawn: WisprScreen, gc_props: {}={}) {
		const yc_spawn = new dc_spawn({
			target: this._dm_buffer,
			props: {
				...gc_props,
			},
		});

		return yc_spawn;
	}

	protected _new_state(gc_state: NewStateConfig): State {
		// spawn component
		const yc_spawn = this.spawn_component(gc_state.screen, gc_state.props);

		// fetch dom
		const dm_state = this._dm_buffer.firstElementChild as HTMLElement;
		if(!dm_state) {
			throw new Error(`No DOM element was created during component spawning using: ${JSON.stringify(gc_state)}`);
		}

		// reset buffer
		this._dm_buffer = dd('div');

		// build path
		let gc_params = {};
		let sr_path = gc_state.path;

		if('function' === typeof sr_path) {
			({
				params: gc_params,
				path: sr_path,
			} = sr_path(yc_spawn));
		}

		// return new state
		return new State(sr_path, yc_spawn, gc_state.pattern || sr_path, {...gc_state.props, ...gc_params}, dm_state);
	}

	protected async _arrive(k_previous: State, si_thread_prev='', s_transition=''): Promise<void> {
		// arrive callback
		await this._f_arrive(k_previous, this.state, si_thread_prev, s_transition);

		// // destroy previous component
		// k_previous.component.$destroy();
	}

	push(gc_state: NewStateConfig): State {
		// ref previous state
		const ks_old = this.state;

		// create state
		const ks_new = this._new_state(gc_state);

		// push to front of history stack
		this.thread.push(ks_new);

		// call hooks
		this._f_push(ks_old, ks_new);
		this._arrive(ks_old, '', 'push');

		// return new state
		return ks_new;
	}

	goto(gc_state: NewStateConfig, b_force=false): State {
		// ref previous state
		const ks_src = this.state;

		// ref current thread history
		const a_history = this.history;

		// make new state
		const ks_dst = this._new_state(gc_state);

		// reuse previous item in thread
		if(!b_force && a_history[1] && ks_dst.path === a_history[1].path) {
			// destroy the new component
			ks_dst.component.$destroy();

			// pop original
			return this.pop();
		}
		// jump
		else {
			// destroy all components further back in the stack
			this.thread.reset(ks_dst, true);

			// move dead
			set_zindex_relatively(ks_dst.dom, ks_src.dom, +1);

			// call hook
			this._arrive(ks_src, '', 'goto').then(() => {
				try {
					ks_src.component.$destroy();
				}
				catch(e_destroy) {
					console.warn(`Failed to destroy stale component belonging to State: ${ks_src.pattern}`);
					ks_src.dom.remove();
				}
			});

			// return new sate
			return ks_dst;
		}
	}

	pop(b_bypass_animation: boolean=false): State {
		// too short
		if(this.history.length < 2) {
			throw new Error(`Failed to pop empty history`);
		}

		// pop from front of stack
		const ks_src = this.history.shift()!;

		// // empty history
		// if(!ks_src) {
		// 	throw new Error(`Failed to pop empty history`);
		// }

		// call hooks
		this._f_pop(ks_src, this.state, b_bypass_animation);
		this._arrive(ks_src, '', b_bypass_animation? 'pop.bypass': 'pop').then(() => {
			try {
				ks_src.component.$destroy();
			}
			catch(e_destroy) {
				console.error(`Failed to destroy stale component belonging to State: ${ks_src.pattern}`);
			}
		});

		// return old state
		return ks_src;
	}
}
