
type ReduceParameters<T extends any=any> = Parameters<Array<T>['reduce']>;

export const F_NOOP = () => {};

export function fold<OutType, ValueType>(a_in: ValueType[], f_fold: (z_value: ValueType) => Record<string, OutType>): Record<string, OutType> {
	const h_out = {};
	for(const z_each of a_in) {
		Object.assign(h_out, f_fold(z_each));
	}
	return h_out;
}

export const ode = Object.entries;

export function oder<
	OutType extends any,
	ValueType extends any,
>(h_thing: Record<string, ValueType>, f_reduce: ReduceParameters[0], w_init: OutType): OutType {
	return ode(h_thing).reduce(f_reduce, w_init) as OutType;
}

/**
 * Reduce object entries to array via concatenation
 */
export function oderac<
	OutType extends any,
	ValueType extends any,
>(h_thing: Record<string, ValueType>, f_concat: (si_key: string, w_value: ValueType) => OutType, b_add_undefs=false): OutType[] {
	return ode(h_thing).reduce((a_out, [si_key, w_value]) => {
		return [
			...a_out,
			f_concat(si_key, w_value),
		];
	}, [] as OutType[]);
}

/**
 * Reduce object entries to array via flattening
 */
export function oderaf<
	OutType extends any,
	ValueType extends any,
>(h_thing: Record<string, ValueType>, f_concat: (si_key: string, w_value: ValueType) => OutType[]): OutType[] {
	return ode(h_thing).reduce((a_out, [si_key, w_value]) => [
		...a_out,
		...f_concat(si_key, w_value),
	], [] as OutType[]);
}

/**
 * Reduce object entries to object via mapping
 */
export function oderom<
	OutType extends any,
	ValueType extends any,
>(h_thing: Record<string, ValueType>, f_concat: (si_key: string, w_value: ValueType) => Record<string, OutType>): Record<string, OutType> {
	return ode(h_thing).reduce((h_out, [si_key, w_value]) => ({
		...h_out,
		...f_concat(si_key, w_value),
	}), {} as Record<string, OutType>);
}

export function timeout(xt_wait: number): Promise<void> {
	return new Promise((fk_resolve) => {
		setTimeout(() => {
			fk_resolve();
		}, xt_wait);
	});
};

export function forever<T>(): Promise<T> {
	return new Promise(() => {});
}

export function microtask(): Promise<void> {
	return new Promise((fk_resolve) => {
		queueMicrotask(() => {
			fk_resolve();
		});
	});
}

export const proper = (s: string) => s[0].toUpperCase() + s.slice(1);


export function shuffle<T>(a_items: T[]): T[] {
	let i_item = a_items.length;

	while(i_item > 0) {
		const i_swap = Math.floor(Math.random() * i_item--);
		const w_item = a_items[i_item];
		a_items[i_item] = a_items[i_swap];
		a_items[i_swap] = w_item;
	}

	return a_items;
}


export function objects_might_differ(h_a: Record<string, unknown>, h_b: Record<string, unknown>) {
	const a_keys_a = Object.keys(h_a);
	const a_keys_b = Object.keys(h_b);

	const nl_keys = a_keys_a.length;

	if(nl_keys !== a_keys_b.length) return true;

	for(const si_key in h_a) {
		if(h_b[si_key] !== h_a[si_key]) return true;
	}

	return false;
}


export class Killables {
	protected _as_intervals = new Set<number>();
	protected _as_timeouts = new Set<number>();

	constructor() {

	}

	addInterval(fk_action: VoidFunction, xtl_interval: number): number {
		const i_handle = window.setInterval(fk_action, xtl_interval);

		this._as_intervals.add(i_handle);

		return i_handle;
	}

	addTimeout(fk_action: VoidFunction, xtl_timeout: number): number {
		const i_handle = window.setTimeout(() => {
			// auto-delete
			this.delTimeout(i_handle);

			// call action
			fk_action();
		}, xtl_timeout);

		this._as_timeouts.add(i_handle);

		return i_handle;
	}

	delInterval(i_handle: number): void {
		window.clearInterval(i_handle);

		this._as_intervals.delete(i_handle);
	}

	delTimeout(i_handle: number): void {
		window.clearTimeout(i_handle);

		this._as_timeouts.delete(i_handle);
	}

	killAll(): void {
		for(const i_handle of this._as_intervals) {
			this.delInterval(i_handle);
		}

		for(const i_handle of this._as_timeouts) {
			this.delTimeout(i_handle);
		}
	}
}

