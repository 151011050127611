import {
	type IntraChain,
	ClassType,
	Definable,
	type Thing,
	Addressable,
	AddressType,
} from './_core';

import {
	type WisprResUri,
	parse_path_pair,
} from '#/state/path';

import { Chain } from './chain';
import type { Icon } from './icon';
import type { Tag } from './tag';
import BigNumber from 'bignumber.js';
import type { Ibct } from './ibct';
import type { Type } from 'ts-toolbelt/out/Any/Type';
import type { Plural } from '#/util/types';
import { Family } from './family';
import type { Hash } from '#/util/types';
import type { Snip20 } from '#/type/snip20';


type TokenRef = Chain.MemberRef<ClassType.TOKEN>;

type TokenPath = `${Chain.Path}/tokens/${string}`;

interface TokenDef extends Chain.Asset<`tokens/${string}`> {
	class: ClassType.TOKEN,
	symbol: string;
	decimals: number;
	native: boolean;
	data: Hash;
	allowances: Snip20.Allowance[],
}

interface TokenConfig {
	symbol: string;
	label: string;
	address: string,
	decimals: number;
	chainRef: Chain.Ref;
	iconRef: Icon.Ref;
	allowances?: Snip20.Allowance[],
	tagRefs?: Tag.Ref[];
	native?: boolean;
	data?: Hash;
}

interface TokenParam extends Chain.Param {
	tokenId: string;
}

class TokenDef {
	static fromConfig(gc_token: TokenConfig): TokenDef {
		return {
			...gc_token,
			native: gc_token.native || false,
			tagRefs: gc_token.tagRefs || [],
			iri: Token.refFromChainAddr(gc_token.chainRef, gc_token.address),
			class: ClassType.TOKEN,
			data: gc_token.data || {},
			allowances: gc_token.allowances || [],
		};
	}
}

type TokenDenom = Type<string, 'amount-in-lowest-denomination'>;

export class Token extends Addressable<TokenDef> {
	static refFromChainAddr(p_chain: Chain.Ref, si_addr: string): TokenRef {
		return `${p_chain}tokens/${si_addr}/`;
	}

	static parseRef(sr_ref: TokenRef): TokenParam {
		const {
			familyId: si_family,
			chainId: si_chain,
			rest: sr_path,
		} = Chain.Def.parseRef(sr_ref);

		const a_parsed = parse_path_pair(sr_path, 'tokens');
	
		return {
			familyId: si_family,
			chainId: si_chain,
			tokenId: a_parsed[0],
			rest: a_parsed[1],
		};
	}

	protected _k_ibct: null | Ibct = null;

	approx(xg_amount: bigint): number {
		return new BigNumber(xg_amount+'').shiftedBy(-this.def.decimals).toNumber();
	}

	denomFromString(s_amount: string): TokenDenom {
		return new BigNumber(s_amount).shiftedBy(this._g_def.decimals).toString() as TokenDenom;
	}

	set ibct(k_ibct: null | Ibct) {
		if(!k_ibct) {
			throw new Error(`Not allowed to set null Ibct`);
		}
		else if(this._k_ibct && k_ibct !== this._k_ibct) {
			throw new Error(`Ibct already set on token <${this.def.iri}>`);
		}

		this._k_ibct = k_ibct;
	}

	get ibct(): null | Ibct {
		return this._k_ibct;
	}

	get label(): string {
		return this._g_def.label;
	}

	address(k_chain: Chain): string {
		return this._g_def.chainRef === k_chain.def.iri? this._g_def.address: '';
	}

	get type(): AddressType {
		return AddressType.CONTRACT;
	}

	get familyRef(): Family.Ref {
		return Family.refFromId('cosmos-sdk');
	}
}

export namespace Token {
	export type Ref = TokenRef;
	export type Def = TokenDef;
	export const Def = TokenDef;
	export type Config = TokenConfig;
	export type Param = TokenParam;

	export type Denom = TokenDenom;

	export type Path = TokenPath;

	export type MemberRef<
		s_path extends string=string,
	> = `${TokenRef}${Plural<s_path>}/${string}/`;

	export interface Member<
		s_path extends string=string,
	> extends Thing<`${TokenPath}/${s_path}`> {
	}
}
