<script lang="ts">
import { push_screen } from "#/app";

	import Screen from "#/ui/Screen.svelte";
	import Mvp_3 from "./Mvp_3.svelte";

	
	const p_high = '/asset/vendor/orb-2-high.png';
	let dm_logo: HTMLElement;
	const dm_img = new Image();
	dm_img.onload = () => {
		if(dm_logo) {
			(dm_logo.closest('.mvp_2') as HTMLElement).style.backgroundImage = `url('${p_high}')`;
		}
	};
	dm_img.src = p_high;


	let n_seconds = 10;
	let s_countdown = '(10s)';

	setInterval(() => {
		n_seconds -= 1;
		s_countdown = `(${n_seconds}s)`;
	}, 1000);
</script>

<style lang="less">
	@import '../style/util.less';

	.thread>section.screen.mvp_2 {
		:global(&) {
			align-items: center;
			justify-content: center;
			text-align: center;
			gap: 20px;
			padding-left: 16px;
			padding-right: 16px;
			background-image: url('/asset/vendor/orb-2.png');
			background-repeat: no-repeat;
			background-position: center top;
			background-size: contain;

			padding-top: 50%;

			// @media screen and (max-width: 500px) {
			// 	padding-top: calc(50vh - 160px);
			// }
		}
	}

	.logo {

	}

	.large {
		.font(big);
	}

	p {
		.font(regular);
		padding: 8px 0;
		margin: 0 15px !important;
	}

	.line {
		width: calc(100% - 40px);
		height: 1px;
		background: radial-gradient(50% 50% at 50% 50%, #FFC700 0%, rgba(255, 199, 0, 0) 100%);
	}

	.action-line {
		width: 100%;
	}

	.timer {
		position: absolute;
		margin-left: 6px;
	}
</style>

<Screen classNames='mvp_2'>
	<div class="logo" bind:this={dm_logo}>
		<img width=66 src="/asset/vendor/logo-2x-dark.png" />
	</div>

	<p>
		To help us measure how well the UI is working, we're going to give you a few tasks, such as "send crypto to this address", or "find this transaction", and so on.
	</p>

	<p>
		Once testing is complete, you will be free to explore the rest of the app and give us written feedback.
	</p>

	<div class="action-line">
		<button class="primary" readonly={n_seconds > 0} on:click={() => push_screen(Mvp_3)}>
			Next {n_seconds > 0? s_countdown: ''}
		</button>
	</div>
</Screen>