<script lang="ts">
import { XP_APP_HEIGHT } from '#/app';

	import SX_DROPDOWN from '#/asset/nav/drop-down.svg?raw';
import { dd } from '#/util/dom';
import { onMount } from 'svelte';
	import Select from "svelte-select";

	export let id = '';
	export let placeholder = '';
	export let listOffset = 1;
	export let isClearable = false;
	export let showIndicator = true;
	export let items: unknown[];
	export let value: unknown = undefined;

	export let primaryClass = '';
	export let secondaryClass = '';

	export let containerClasses = '';

	interface Option {
		value: string;
		primary?: string;
		secondary?: string;
	}

	function create_label(g_item: Option) {
		if(g_item.primary) {
			return dd('span', {
				class: 'item',
			}, [
				dd('span', {
					class: 'name '+primaryClass,
				}, [
					g_item.primary,
				]),
				dd('span', {
					class: 'secondary '+secondaryClass,
				}, [
					g_item.secondary || '',
				])
			]).outerHTML;
		}

		return g_item.value;
	}

	let dm_star_select: HTMLElement;
	let sx_max_height = '500px';
	onMount(() => {
		setTimeout(() => {
			try {
				const x_bottom = dm_star_select.getBoundingClientRect().bottom;
				sx_max_height = Math.min(XP_APP_HEIGHT - x_bottom - 20, 500)+'px';
			} catch(e_bounds) {}
		}, 1000);
	});
</script>

<style lang="less">
	@import '../style/util.less';

	.star-select {
		position: relative;
		.style-svelte-select();
		.font(regular);

		>input {

			&::after {
				content: '';
				position: absolute;
				right: 0;
			}
		}

		>.icon {
			--icon-diameter: 24px;
			--icon-color: var(--theme-color-primary);
			position: absolute;
			top: 0;
			right: 0;
			padding: 12px;
			cursor: pointer;
		}

		.secondary {
			:global(&) {
				color: var(--theme-color-text-med);
			}

			:global(&::before) {
				content: ' - ';
				color: var(--theme-color-text-med);
			}
		}

		.item.active {
			:global(&) {
				display: none;
			}

			.secondary {
				:global(&) {
					color: rgba(0, 0, 0, 0.5);
				}
				
				:global(&::before) {
					color: rgba(0, 0, 0, 0.5);
				}
			}
		}
	}
</style>

<div class="star-select {containerClasses}" bind:this={dm_star_select}>
	<Select id="sender-select"
		placeholder="Select account"
		listOffset={listOffset}
		isClearable={isClearable}
		showIndicator={showIndicator}
		indicatorSvg={SX_DROPDOWN}
		listPlacement='bottom'
		items={items}
		bind:value={value}
		getOptionLabel={create_label}
		getSelectionLabel={create_label}
		--listMaxHeight={sx_max_height}
	/>
</div>