<script context="module" lang="ts">
	let c_screens = 0;
</script>

<script lang="ts">
	import { arrival, yw_blur, yw_help, yw_nav_collapsed, yw_nav_visible, yw_overscroll_pct, yw_progress } from "#/app";

	import type { Account } from "#/objects/account";
import { timeout } from "#/util/belt";
	import { createEventDispatcher, onMount } from "svelte";


	export let nav = false;
	export let debug = false;
	export let progress: null | [number, number] = null;
	export let full = false;
	export let keyed = false;
	export let classNames = '';

	export let leaves = false;
	export let swipes = false;
	export let root = false;
	export let slides = !leaves && !swipes && !root;

	export let form = false;

	export let transparent = false;

	let dm_screen: HTMLElement;

	// @mvp
	let dm_help: HTMLElement | null = null;

	const si_exit = leaves? 'leaves': swipes? 'swipes': '';

	const dispatchEvent = createEventDispatcher();
	onMount(() => {
		dm_screen.addEventListener('wheel', (de_wheel) => {
			if(0 === dm_screen.scrollTop) {
				if(de_wheel.DOM_DELTA_PIXEL === de_wheel.deltaMode) {
					// const x_delta_y = de_wheel.deltaY;
					// const x_pct = Math.min(Math.abs(x_delta_y), 50) / 50;
					// $yw_overscroll_pct = x_pct;
					// console.log((x_pct * 100).toFixed(2));

					// console.log({
					// 	deltaY: de_wheel.deltaY,
					// 	// wheelDeltaY: de_wheel.wheelDeltaY,
					// 	screenY: de_wheel.screenY,
					// 	mode: de_wheel.deltaMode,
					// });
				}
			}
		});

		if(debug) {
			debugger;
		}

		arrival(dm_screen, () => {
			console.log('arrived to screen');
			$yw_nav_visible = nav;
			// $yw_nav_collapsed = !nav;

			// if(nav) {
			// 	$yw_nav_visible = true;
			// }

			if(progress) {
				$yw_progress = progress;
			}

			$yw_help = dm_help
				? Array.from(dm_help.cloneNode(true).childNodes) as HTMLElement[]
				: [];

			dispatchEvent('arrive');
		});

		// if screen has keyed svelte components
		if(keyed) {
			let x_scroll_top = 0;
			
			// svelte will replace those elements when changing screens
			(new MutationObserver(async(a_mutations) => {
				// keyed component was removed
				if(a_mutations[0]?.addedNodes.length) {
					try {
						x_scroll_top = dm_screen.scrollTop;
					} catch(e_null) {}
				}
				// keyed component was restored
				else if(a_mutations[0]?.removedNodes.length) {
					if(dm_screen) {
						dm_screen.scrollTop = x_scroll_top;
					}
				}
			})).observe(dm_screen, {
				childList: true,
			});
		}
	});

	export let style = '';
</script>

<style lang="less">
	@import '../style/util.less';
	@import '../style/screen.less';

	section.screen,form.screen {
		position: absolute;
		top: 0;
		left: 0;

		box-sizing: border-box;

		width: var(--app-window-width);
		height: 100%;

		.font(regular);

		background-color: var(--theme-color-bg);

		opacity: 1;
		filter: blur(0);

		// transition: transform 0.6s var(--ease-out-quick);

		&.transparent {
			background-color: transparent;
		}

		&.progress {
			padding-top: 22px;
		}

		&.nav {
			padding-bottom: 71px;
		}

		&.flex {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: var(--gap, var(--ui-padding));

			>:first-child:not(.no-margin) {
				:global(&) {
					margin-top: var(--ui-padding);
				}
			}

			&>* {
				:global(&) {
					flex: 0;
				}

				:global(&:not(.no-margin)) {
					margin: 0 var(--ui-padding);
				}
			}
		}

		overflow: hidden;
		&.scroll {
			overflow-y: scroll;
			overscroll-behavior-y: contain;
			.hide-scrollbar();
		}

		&.slides {
			transform: translateX(calc(var(--app-window-width) / 2));
			transition: transform 0.6s var(--ease-out-quick);
		}

		&.slid {
			transition: transform 0.5s var(--ease-out-cubic);
		}

		&[data-s2-exit]:not([data-s2-exit='']) {
			:global(&) {
				// left: 0;
				transform: translateX(0px);
				transition: transform 0.5s var(--ease-out-quint);
			}
		}

		@keyframes fade-away {
			0% {
				opacity: 1;
			}

			75% {
				opacity: 0;
			}

			100% {
				opacity: 0;
			}
		}

		@keyframes blur-away {
			0% {
				filter: blur(0);
			}

			100% {
				filter: blur(14px);
			}
		}

		@keyframes scale-up {
			0% {
				transform: scale(1);
			}

			100% {
				transform: scale(1.75);
			}
		}

		// @keyframes sublimate {
		// 	0% {
		// 		opacity: 1;
		// 		transform: scale(1);
		// 		filter: blur(0);
		// 	}

		// 	25% {
		// 		filter: blur(14px);
		// 	}

		// 	80% {
		// 		opacity: 0;
		// 	}

		// 	100% {
		// 		transform: scale(1.75)
		// 	}
		// }

		@keyframes turn-away {
			0% {
				transform: perspective(0px) rotateY(0deg);
			}

			1% {
				transform: perspective(1500px) rotateY(0deg);
			}

			100% {
				transform: perspective(1500px) rotateY(-80deg);
			}
		}

		@keyframes fade-out {
			0% {
				opacity: 1;
			}

			10% {
				opacity: 1;
			}

			80% {
				opacity: 0;
			}

			100% {
				opacity: 0;
			}
		}

		&.sublimate {
			// animation: 
			// 	scale-up 1000ms var(--ease-out-cubic) forwards,
			// 	fade-away 1000ms var(--ease-out-quad) forwards,
			// 	blur-away 200ms var(--ease-out-quad) forwards;

			// --mimic: 1000ms var(--ease-out-quint) forwards;
			// animation: turn-away var(--mimic),
			// 	fade-out var(--mimic);
			// transform-origin: 120%;

			transition: opacity 400ms var(--ease-out-quad);
			opacity: 0;
		}

		&.materialize {

		}

		>*:not(.header) {
			:global(&) {
				transition: filter 400ms var(--ease-out-cubic);
			}
		}

		&.blur {
			>*:not(.header) {
				:global(&) {
					filter: blur(2px);
				}
			}
		}
	}

</style>


{#if form}
	<form
		class="screen {classNames}"
		class:flex={true}
		class:scroll={true}
		class:nav={nav}
		class:progress={progress}
		class:slides={slides}
		class:transparent={transparent}
		class:sublimate={false}
		class:blur={$yw_blur}
		data-s2-exit={si_exit}
		bind:this={dm_screen}
		on:submit={(d_event) => d_event.preventDefault()}
		on:submit
		style="{style}"
		autocomplete="off"
	>
		<slot></slot>

		{#if 'help' in $$slots}
			<div class="display_none" bind:this={dm_help}>
				<slot name="help" />
			</div>
		{/if}

	</form>
{:else}
	<section
		class="screen {classNames}"
		class:flex={true}
		class:scroll={true}
		class:nav={nav}
		class:progress={progress}
		class:slides={slides}
		class:transparent={transparent}
		class:sublimate={false}
		class:blur={$yw_blur}
		data-s2-exit={si_exit}
		bind:this={dm_screen}
		style="{style}"
	>
		<slot></slot>

		{#if 'help' in $$slots}
			<div class="display_none" bind:this={dm_help}>
				<slot name="help" />
			</div>
		{/if}
	</section>
{/if}
