<script lang="ts">
import { yw_popup, yw_task } from '#/app';

	import SX_COPY from '#/asset/action/copy.svg?raw';
import { Tasks } from '#/component/Simulator.svelte';

	export let prefix: string = '';
	export let address: string;

	export let copyable = false;

	let dm_copied: HTMLElement;

	let b_copy_confirm = false;
	function copy() {
		try {
			navigator.clipboard.writeText(address);
		} catch(e_write) {}

		b_copy_confirm = true;
		setTimeout(() => {
			b_copy_confirm = false;
			// dm_copied.style.opacity = '0';

			// setTimeout(() => {
			// 	b_copy_confirm = false;
			// }, 850);
		}, 800);

		if(Tasks.RECEIVE === $yw_task) {
			setTimeout(() => {
				$yw_popup = null;
				$yw_task = -$yw_task;
			}, 600);
		}
	}
</script>

<style lang="less">
	@import '../style/util.less';

	.address {
		width: calc(100% - 2.5ch);
		align-items: baseline;

		color: var(--theme-color-text-med);
		display: inline-flex;
		position: relative;

		&.copyable {
			align-items: center;
			width: calc(100% - 3.5ch);
			cursor: copy;
		}

		>.prefix {
			.font(tiny);
			flex-shrink: 0;
			margin-right: 0.25em;
		}

		>.head {
			.font(mono-tiny);
			flex: auto;
			min-width: 10ch;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		>.tail {
			.font(mono-tiny);
			flex: 0;
			min-width: 7ch;
			overflow: hidden;

			display: flex;
			justify-content: flex-end;
			white-space: nowrap;
		}

		>.icon.copy {
			--icon-diameter: 22px;
			--icon-color: var(--theme-color-primary);
			padding-left: 6px;
		}

		>.copied {
			opacity: 0;
			position: absolute;
			left: calc(50% - 40px);
			top: -6px;
			color: var(--theme-color-black);
			border-radius: 6px;
			padding: 8px 20px;
			background-color: var(--theme-color-text-light);
			box-shadow: -2px 3px 6px;
			pointer-events: none;

			&:not(.confirm) {
				transition: opacity 800ms linear;
			}

			&.confirm {
				opacity: 1;
			}
		}
	}
</style>

<span class="address clickable" class:copyable={copyable} on:click={copyable? () => copy(): void 0}>
	{#if prefix}
		<span class="prefix">
			{prefix}
		</span>
	{/if}
	<span class="head">
		{address}
	</span>
	<span class="tail">
		{address}
	</span>

	{#if copyable}
		<span class="copy icon">
			{@html SX_COPY}
		</span>

		<span class="copied" class:confirm={b_copy_confirm} bind:this={dm_copied}>
			Copied!
		</span>
	{/if}
</span>
