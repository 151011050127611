import type {
	WisprUri,
	WisprResUri,
} from '#/state/path';

import type {
	Type,
} from 'ts-toolbelt/out/Any/_api';
import type { Chain } from './chain';
import type { Contact } from './contact';
import type { Family } from './family';

export type Color = Type<string, 'color'>;

export enum ClassType {
	UNKNOWN = 'unknown',

	// set of chains which share a common address space, such that user accounts are translatable across members
	FAMILY = 'family',

	// specific blockchain
	CHAIN = 'chain',

	// configuration for how to communicate with chain
	NETWORK = 'network',

	// 
	ACCOUNT = 'account',

	// pubkey associated with distinct family 'member'
	CONTACT = 'contact',

	// on-chain resource that only exists this chain
	CONTRACT = 'contract',

	// addressable asset associated with distinct chain
	TOKEN = 'token',

	// asset holdings
	HOLDING = 'holding',

	// site connection
	SITE = 'site',

	TAG = 'tag',
	ICON = 'icon',
	
	IBCT = 'ibct',
	SNIP721 = 'snip721',
	TXN = 'txn',
	OTHER = 'other',
}


export enum SearchScoreType {
	EXACT_ADDR = 0,
	EXACT_LABEL = 1,
	EXACT_SYMBOL = 2,
	STARTS_ADDR = 10,
	STARTS_LABEL = 11,
	STARTS_SYMBOL = 12,
	NONE = 100,
}

export interface SearchScore {
	type: SearchScoreType;
	score: number;
}

export class SearchScore {
	static exact_label() {
		return {
			type: SearchScoreType.EXACT_LABEL,
			score: 1,
		};
	}

	static type(i_type: SearchScoreType, x_score=1): SearchScore {
		return {
			type: i_type,
			score: x_score,
		};
	}
}

export interface SearchConfig {
	// account?: 
}

export interface Searchable {
	// search(s_search: string, gc_search: SearchConfig): SearchScore;

	get label(): string;

	get detail(): string;
}


export interface Thing<
	s_path extends string=string,
> {
	class: ClassType;
	iri: WisprUri<`${s_path}/`>;
	label?: string;
}


export class Definable<
	g_def extends Thing=Thing,
> implements Searchable {
	protected _g_def: g_def;

	constructor(g_def: g_def) {
		this._g_def = g_def;
	}

	get def(): g_def {
		return this._g_def;
	}

	search(s_search: string, gc_search: SearchConfig): SearchScore {
		const g_def = this._g_def;

		const s_label = g_def.label?.toLocaleLowerCase();
		if(s_label) {
			if(s_search === s_label) {
				return SearchScore.exact_label();
			}
			else if(s_label.startsWith(s_search)) {
				return SearchScore.type(SearchScoreType.STARTS_LABEL);
			}
			// else if(s_label)

		}
		return {
			type: SearchScoreType.NONE,
			score: 0,
		};
	}

	get label() {
		return this._g_def.label || '';
	}

	get detail() {
		return '';
	}
}

export enum AddressType {
	UNKNOWN = 0,
	PERSON = 1,
	CONTRACT = 2,
}


export abstract class Addressable<
	g_def extends Thing=Thing,
> extends Definable<g_def> {
	abstract address(k_chain: Chain): string;

	abstract get label(): string;

	abstract get type(): AddressType;
}

export type IntraChain<
	s_class extends string,
> = `chains/${string}/${s_class}`;



export interface Labelable<
	s_path extends string=string,
> extends Thing<s_path> {
	label: string;
}



export type TagRef = WisprResUri<ClassType.TAG>;

export interface TagDef extends Labelable<`tags/${string}`> {
	class: ClassType.TAG,
	color: string;
}

export interface TagConfig {
	id: string;
	label: string;
	color: string;
}

// export interface Tag extends Thing<`tags/${string}`> {
// 	class: ClassType.TAG,
// 	label: string;
// 	color: Color;
// }

export class TagDef {
	static fromConfig(gc_tag: TagConfig): TagDef {
		return {
			class: ClassType.TAG,
			iri: Tag.refFromId(gc_tag.id),
			label: gc_tag.label,
			color: gc_tag.color,
		};
	}
}

export class Tag extends Definable<TagDef> {
	static fromDef(gd_tag: TagDef) {
		return new Tag(gd_tag);
	}

	static refFromId(si_tag: string): TagRef {
		return `wispr://root/tags/${si_tag}/`;
	}
}


export interface TagRefable<
	s_path extends string=string,
> extends Labelable<s_path> {
	tagRefs: TagRef[];
}



// export class Taggable<
// 	g_def extends Thing=Thing,
// > extends Definable<g_def> {
// 	get tags(): Tag[] {

// 	}
// }
