<script lang="ts">
import { yw_chain } from "#/app";

	import type { Contact } from "#/objects";
	import { H_ADDR_TO_CONTACT, H_CONTACTS } from "#/sim/data";
	import Address from "./Address.svelte";
	import Pfp from "./Pfp.svelte";


	 export let contact: Contact;
	 export let address = '';
	 export let item: {
		 value: string;
		 contact: Contact;
		 isGroupHeader?: boolean;
		 isGroupItem?: boolean;
		 isCreator?: boolean;
	 } | undefined = void 0;

	 if(!contact && H_ADDR_TO_CONTACT[address]) {
		 contact = H_ADDR_TO_CONTACT[address];
	 }
</script>

<style lang="less">
	@import '../style/util.less';

	.contact {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 100%;

		// padding-top: 3px;
		box-sizing: border-box;;

		>.contact-pfp {
			display: inline-flex;
			--proxy-icon-diameter: 26px;
			line-height: 26px;
			margin-right: 6px;

			>.icon {
				:global(&) {
					border-radius: 20%;
					display: inline-flex;
					align-items: center;
				}

				.icon-dom {
					:global(&) {
						height: unset;
						font-size: 14px; 
					}
				}
			}
		}

		>.info {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			max-width: 172px;

			>.name {
				line-height: 1.2em;
			}

			>.address {
				:global(&) {
					// .font(mono-tiny);
					line-height: 1.2em;
					color: var(--theme-color-text-med);
				}
			}
		}

		// >* {
		// 	:global(&) {
		// 		vertical-align: middle;
		// 	}
		// }
	}

	.manual {
		.address {
			width: calc(100% - 3.5ch);
		}
	}
</style>

{#if contact}
	<div class="contact">
		<span class="contact-pfp">
			{#key contact}
				<Pfp contact={contact} />
			{/key}
		</span>

		<span class="info">
			<span class="name">
				{contact.def.label}
			</span>

			<Address address={contact.address($yw_chain)} />
		</span>
	</div>
{:else if address}
	<span class="manual">
		<Address address={address} />
	</span>
{:else if item && item.isCreator}
	<span class="manual">
		<Address prefix={'\xa0\xa0\xa0\xa0addr: '} address={item.value} />
	</span>
{/if}