import type {
	Merge,
} from 'ts-toolbelt/out/Object/Merge';

import type {
	WisprResUri,
} from '#/state/path';

import {
	ClassType,
	Definable,
	type Thing,
} from './_core';

import {
	Chain,
	Token,
} from '#/objects';

import type { JsonObject } from '#/util/types';
import { Snip20 } from '#/type/snip20';
import { H_CHAINS, K_DEFAULT_CHAIN } from '#/sim/data';

type TxnRef = `${WisprResUri<ClassType.CHAIN>}txns/${string}/`;

enum TxnType {
	UNKN = 'unknown',
	SEND = 'send',
	RECV = 'receive',
	COMP = 'compute',
	SNIP20_XFER = 'snip-20:transfer',
}

interface TxnDef extends Thing<`chains/${string}/txns/${string}`> {
	class: ClassType.TXN,
	txnId: string;
	address: string;
	chainRef: Chain.Ref;
	type: TxnType;
	data: JsonObject;
	amount: bigint;
	timestamp: number;
	tokenRef: Token.Ref;
	pending?: boolean;
}

type TxnConfig = {
	token: Token;
	txnId: string;
	timestamp: number;
	pending?: boolean;
	type?: TxnType;
	data?: JsonObject;
} | {
	txnId: string;
	address: string;
	timestamp: number;
	chainRef: Chain.Ref;
	type?: TxnType;
	data?: JsonObject;
	amount?: bigint;
}

class TxnDef {
	static fromConfig(gc_txn: TxnConfig): TxnDef {
		let h_def = {
			...gc_txn,
		};

		if('token' in gc_txn) {
			const k_token = gc_txn.token;
			Object.assign(h_def, {
				tokenRef: k_token.def.iri,
				address: k_token.def.address,
				chainRef: k_token.def.chainRef,
				amount: 0,
			});
		}
		else {
			Object.assign(h_def, {
				amount: gc_txn.amount || 0n,
				data: gc_txn.data || {},
				type: gc_txn.type || TxnType.UNKN,
				iri: Txn.refFromChainTxnId(gc_txn.chainRef, gc_txn.txnId),
				class: ClassType.TXN,
				tokenRef: Token.refFromChainAddr(K_DEFAULT_CHAIN.def.iri, '.native'),
			});
		}

		return h_def as TxnDef;
	}
}

enum TxnBankishType {
	SEND = 'send',
	RECV = 'receive',
}

interface TxnBankish {
	type: TxnBankishType;
	amount: bigint;
	address: string;
	proxy?: string;
}

interface TxnEvent extends JsonObject {
	id?: string;
	from: string;
	sender: string;
	receiver: string;
	coins: {
		denom: string;
		amount: string;
	};
}

export class Txn extends Definable<TxnDef> {
	static refFromChainTxnId(p_chain: string, si_txn: string): TxnRef {
		return `${p_chain}txns/${si_txn}/` as TxnRef;
	}

	snip20(): null | TxnEvent {
		const z_data = this._g_def.data;
		if(z_data) {
			// if(Snip20.isTransfer(z_data)) {
				// return z_data;
			// }
			return z_data as TxnEvent;
		}

		return null;
	}

	token(H_TOKENS: Record<Token.Ref, Token>): Token {
		return H_TOKENS[this._g_def.tokenRef];
	}

	bankish(sa_owner: string): null | TxnBankish {
		const z_data = this._g_def.data;

		switch(this._g_def.type) {
			case TxnType.SEND: {
				return {
					type: TxnBankishType.SEND,
					amount: this._g_def.amount,
					address: this._g_def.address,
				};
			}

			case TxnType.RECV: {
				return {
					type: TxnBankishType.RECV,
					amount: this._g_def.amount,
					address: this._g_def.address,
				};
			}

			case TxnType.SNIP20_XFER: {
				const g_xfer = z_data as Snip20.TransferEvent;
				if(!g_xfer) debugger;
				const xg_amount = BigInt(g_xfer.coins.amount);
				if(sa_owner === g_xfer.receiver) {
					return {
						type: TxnBankishType.RECV,
						amount: xg_amount,
						address: g_xfer.from,
						proxy: g_xfer.sender,
					};
				}
				else {
					return {
						type: TxnBankishType.SEND,
						amount: xg_amount,
						address: g_xfer.receiver,
					}
				}
			}
		}

		return null;
	}

	date(): string {
		const dt_when = new Date(this._g_def.timestamp);
		
		return dt_when.toLocaleDateString('en-US', {
			month: 'short',
			day: 'numeric',
			year: dt_when.getFullYear() !== (new Date()).getFullYear()? 'numeric': void 0,
		});
	}
}

export namespace Txn {
	export type Ref = TxnRef;

	export type Def = TxnDef;
	export const Def = TxnDef;

	export type Type = TxnType;
	export const Type = TxnType;

	export type Event = TxnEvent;

	export type BankishType = TxnBankishType;
	export const BankishType = TxnBankishType;
	export type Bankish = TxnBankish;

	export type Snip20Msg<Key extends string=string, Data extends JsonObject={}> = JsonObject & {
		[k in Key]: Merge<Data, {
			padding: string;
		}>;
	}

	export type Snip20Msg_Transfer = Snip20Msg<'transfer', {
		recipient: string;
		amount: Token.Denom;
	}>;

	export type Snip20Msg_Send = Snip20Msg<'send', {
		recipient: string;
		amount: Token.Denom;
		msg: string;
	}>;

	export type Snip20Msg_RegisterReceive = Snip20Msg<'register_receive', {
		code_hash: string;
	}>;

	export interface Snip20Config extends JsonObject {
		transfer?: {
			recipient: string;
			amount: Token.Denom;
		};
	}

}
