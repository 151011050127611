import type { JsonObject } from "#/util/types";

const RT_INTEGER = /^[0-9]+$/;

export namespace Snip20 {
	interface _Msg extends JsonObject {
		padding?: string;
	}

	export interface Transfer extends _Msg {
		transfer: {
			recipient: string;
			amount: string;
		};
	}

	export interface Send extends _Msg {
		send: {
			recipient: string;
			amount: string;
			msg?: string;
		};
	}

	export interface RegisterReceive extends _Msg {
		code_hash: string;
	}

	export interface CreateViewingKey extends _Msg {
		entropy: string;
	}

	export interface SetViewingKey extends _Msg {
		key: string;
	}

	export type Message = Transfer | Send | RegisterReceive | CreateViewingKey | SetViewingKey;

	export function isNonEmptyString(z_data: any): z_data is string {
		return z_data && 'string' === typeof z_data;
	}

	export function isRecipient(z_data: any): z_data is string {
		return z_data && 'string' === typeof z_data;
	}

	export function isInteger(z_data: any): z_data is `${number}` {
		return z_data && 'string' === typeof z_data && RT_INTEGER.test(z_data);
	}

	export function isTransfer(z_data: JsonObject): z_data is Transfer {
		if(z_data && 'object' == typeof z_data.transfer) {
			if(1 === Object.keys(z_data).length) {
				const z_transfer = z_data.transfer as JsonObject;
				if(isRecipient(z_transfer.recipient) && isInteger(z_transfer.amount)) {
					if('string' === typeof z_transfer.padding) {
						return 3 === Object.keys(z_transfer).length;
					}
					else {
						return 2 === Object.keys(z_transfer).length;
					}
				}
			}
		}

		return false;
	}

	export interface TransferEvent extends JsonObject {
		id?: string;
		from: string;
		sender: string;
		receiver: string;
		coins: {
			denom: string;
			amount: string;
		};
	}

	export interface Allowance extends JsonObject {
		spender: string;
		amount: string;
		expiration?: null | number;
	}
}