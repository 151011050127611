<script lang="ts">
	import BigNumber from 'bignumber.js';

	import {
		ode, oder, oderac,
	} from "#/util/belt";

	import {
		Screen,
		Row,
		Header,
		Tags,
		Portrait,
		Put,
		HoldingsList,
	} from "#/ui";

	import {
		H_ACCOUNTS,
		H_CHAINS,
		H_HOLDINGS,
		H_TAGS,
		H_TOKENS,
		H_VERSUS_USD,
	} from '#/sim/data';

	import {
		Holding,
	} from '#/objects';

	import {
		format_fiat,
	} from '#/sim/data'

	import {
		push_screen,
		yw_account,
		yw_chain,
		yw_popup,
		yw_popup_account,
	} from '#/app';

	import Send from './Send.svelte';
	import PopupReceive from '#/ui/PopupReceive.svelte';
import DeadEnd from './DeadEnd.svelte';

	$: a_holdings = $yw_account.holdings(H_HOLDINGS, $yw_chain);
	// Object.values(H_HOLDINGS)
	// 	.filter(k_holding => $yw_account.address($yw_chain) === k_holding.def.holderAddr);

	$: x_usd_balance = Holding.usdSum(a_holdings, H_TOKENS, H_VERSUS_USD);
	// a_holdings.reduce((x_usd, k_holding) => x_usd + k_holding.toUsd(H_TOKENS, H_VERSUS_USD), 0);

</script>

<Screen nav root keyed>

	<Header search network account
	>
		<svelte:fragment slot="title">

		</svelte:fragment>
	</Header>

	<Portrait
		title={format_fiat(x_usd_balance)}
		subtitle={$yw_account.def.label}
		tags={$yw_account.def.tagRefs.map(p => H_TAGS[p])}
		actions={{
			send: {
				label: 'Send',
				trigger() {
					push_screen(Send, {
						from: $yw_account,
					});
				},
			},
			recv: {
				label: 'Receive',
				trigger() {
					$yw_popup_account = $yw_account;
					$yw_popup = PopupReceive;
				},
			},
			add: {
				label: 'Add Token',
				trigger() {
					push_screen(DeadEnd);
				},
			},
		}}
	/>

	{#key a_holdings}
		<HoldingsList holdings={a_holdings} />
	{/key}

</Screen>
