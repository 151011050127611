<script lang="ts">
	import { A_TXNS, format_amount, H_ADDRESS_TO_TOKEN, H_CHAINS, H_CONTACTS, H_ICONS, H_TAGS } from "#/sim/data";

	import {
		Screen,
		Address,
		Header,
		Portrait,
		Put,
		Row,
	} from '#/ui';

	import type { Network } from "#/objects";


	export let network: Network;
	const k_chain = H_CHAINS[network.def.chainRef];
	export const networkId = network.def.id;
	export const chainId = k_chain.def.id;

</script>

<style lang="less">
	@import '../style/util.less';
</style>


<Screen nav slides>
	<Header pops search network account
	>
	</Header>

	<Portrait
		pfpIcon={H_ICONS[k_chain.def.iconRef]}
		title={k_chain.def.label}
		subtitle={network.def.label}
	>
	</Portrait>



</Screen>