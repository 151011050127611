import BigNumber from 'bignumber.js';

import {
	type Thing,
	ClassType,
	Definable,
} from './_core';

import type {
	WisprResUri, WisprUri,
} from '#/state/path';

import type { Token } from './token';

type HoldingPath = `${Token.Path}/holdings/${string}`;

type HoldingRef = Token.MemberRef<ClassType.HOLDING>;

export interface HoldingDef extends Token.Member<`holdings/${string}`> {
	balance: bigint;
	tokenRef: Token.Ref;
	holderAddr: string;
}

export interface HoldingConfig {
	balance: bigint;
	tokenRef: Token.Ref;
	holderAddr?: string;
}

export class HoldingDef {
	static fromConfig(gc_holding: HoldingConfig): HoldingDef {
		const si_holder = gc_holding.holderAddr || '*';

		return {
			class: ClassType.HOLDING,
			iri: Holding.refFromTokenAccount(gc_holding.tokenRef, si_holder),
			tokenRef: gc_holding.tokenRef,
			holderAddr: si_holder,
			balance: gc_holding.balance,
		};
	}
}

export class Holding extends Definable<HoldingDef> {
	static refFromTokenAccount(p_token: Token.Ref, sa_holder: string): HoldingRef {
		return `${p_token}holdings/${sa_holder}/`;
	}

	static usdSum(a_holdings: Holding[], H_TOKENS: Record<Token.Ref, Token>, H_VERSUS_USD: Record<Token.Ref, {value:number}>): number {
		return a_holdings.reduce((x_usd, k_holding) => x_usd + k_holding.toUsd(H_TOKENS, H_VERSUS_USD), 0);
	}

	token(H_TOKENS: Record<WisprUri, Token>): Token {
		return H_TOKENS[this._g_def.tokenRef];
	}

	approx(H_TOKENS: Record<WisprUri, Token>): number {
		return this.token(H_TOKENS).approx(this._g_def.balance);
	}

	get humanReadableBalance(): string {
		const xg_balance = this._g_def.balance;
		return xg_balance.toLocaleString();
	}

	toUsd(H_TOKENS: Record<WisprUri, Token>, H_VERSUS_USD: Record<WisprUri, {value:number}>): number {
		if(!H_VERSUS_USD[this._g_def.tokenRef]?.value) return 0;
		return H_VERSUS_USD[this._g_def.tokenRef].value * this.approx(H_TOKENS);
	}

	amount(H_TOKENS: Record<WisprUri, Token>): BigNumber {
		return (new BigNumber(this._g_def.balance+'')).shiftedBy(-this.token(H_TOKENS).def.decimals);
	}
}

export namespace Holding {
	export type Ref = HoldingRef;
	export type Def = HoldingDef;
	export const Def = HoldingDef;
	export type Config = HoldingConfig;
}