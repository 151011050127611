<script context="module" lang="ts">
	export enum ActionId {
		SEND = 'send',
		RECV = 'recv',
		ADD = 'add',
		EDIT = 'edit',
		WRAP = 'wrap',
		UNWRAP = 'unwrap',
	}

	export interface ActionConfig {
		label: string;
		trigger: VoidFunction;
	}

	export type Actions = Partial<Record<ActionId, ActionConfig>>;
</script>

<script lang="ts">
	import {
		Icon,
		type Tag,
	} from "#/objects";

	import {
		Put,
		Tags,
	} from '#/ui';

	import { ode } from "#/util/belt";

	import SX_INFO from '@material-design-icons/svg/outlined/info.svg?raw';

	import SX_SEND from '#/asset/action/send.svg?raw';
	import SX_RECV from '#/asset/action/recv.svg?raw';
	import SX_ADD from '#/asset/action/add.svg?raw';
	import SX_EDIT from '@material-design-icons/svg/filled/edit.svg?raw';
	import SX_DELETE from '@material-design-icons/svg/outlined/delete.svg?raw';
	import SX_WRAP from '#/asset/action/wrap.svg?raw';
	import SX_UNWRAP from '#/asset/action/unwrap.svg?raw';

	const H_ACTION_ICONS: Record<string, string> = {
		send: SX_SEND,
		recv: SX_RECV,
		add: SX_ADD,
		edit: SX_EDIT,
		wrap: SX_WRAP,
		unwrap: SX_UNWRAP,
		delete: SX_DELETE,
	};

	export let title: string;

	export let subtitle: string = '';

	export let actions: null | Actions = null;

	export let pfpIcon: null | Icon = null;

	export let info = false;

	export let tags: Tag[] = [];

</script>

<style lang="less">
	@import '../style/util.less';

	.portrait {
		display: flex;
		flex-direction: column;
		padding-top: 1em;

		>.pfp {
			width: 100%;
			text-align: center;
			margin-bottom: 10px;
			
			>.icon {
				--icon-diameter: 64px;

				.group {
					:global(&) {
						transform: scale(1.5);
					}
				}

				img {
					:global(&) {
						border-radius: 20%;
					}
				}
			}
		}

		>.title {
			.font(huge);
			display: flex;
			justify-content: center;
			gap: 4px;
			white-space: nowrap;
			overflow: scroll;
			.hide-scrollbar();

			>.info {
				fill: var(--theme-color-primary);
				vertical-align: baseline;

				>svg {
					:global(&) {
						margin-top: -4px;
						vertical-align: middle;
						width: 20px;
						height: 20px;
					}
				}
			}
		}

		>.subtitle {
			.font(regular);
			text-align: center;
			color: var(--theme-color-text-med);
			margin-top: 4px;
		}

		>.actions {
			display: flex;
			justify-content: center;
			margin-top: 1rem;
			margin-bottom: 1rem;

			>.action {
				.font(tiny);

				cursor: pointer;
				flex: 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 5px;

				color: var(--theme-color-text-med);
				min-width: calc(48px + 3.5ex);

				>.icon {
					--button-diameter: 48px;
					--icon-diameter: 20px;
					background-color: var(--theme-color-border);
				}

				&:hover {
					>.icon {
						--icon-color: black;
						background-color: var(--theme-color-primary);
					}
				}
			}
		}
	}
</style>


<div class="portrait">
	{#if pfpIcon || $$slots.pfp}
		<div class="pfp">
			<span class="icon">
				<slot name="pfp">
					<Put element={(pfpIcon || Icon.BLANK).render()} />
				</slot>
			</span>
		</div>
	{/if}
	<div class="title">
		<span class="text">
			{title}
		</span>
		{#if info}
			<span class="info">
				{@html SX_INFO}
			</span>
		{/if}
	</div>
	{#if subtitle || $$slots.subtitle}
		<div class="subtitle">
			<span class="text">
				<slot name="subtitle">
					{subtitle}
				</slot>
			</span>
		</div>
	{/if}

	<Tags {tags} rootStyle='margin: var(--ui-padding) 0 0 0;' />

	{#if actions}
		<div class="actions">
			{#each ode(actions) as [si_action, gc_action]}
				<span class="action action-{si_action} clickable" on:click={() => gc_action.trigger()}>
					<span class="icon">
						{@html H_ACTION_ICONS[si_action]}
					</span>
					<span class="label">
						{gc_action.label}
					</span>
				</span>
			{/each}
		</div>
	{/if}

	
</div>