<script type="ts">
	import {
		yw_cancel_search,
		yw_fuse,
		yw_header_props,
		yw_search,
	} from '#/app';
import { ClassType } from '#/objects/_core';

import { H_ACCOUNTS, H_CHAINS, H_CONTACTS, H_CONTRACTS, H_NETWORKS, H_NFTS, H_SITES, H_TOKENS, type SearchItem } from '#/sim/data';

	import {
		Icon,
		type Account,
		type Chain,
		type Contact,
		type Contract,
		type Family,
		type Holding,
		type Ibct,
		type Network,
		type Nft,
		type Site,
		type Tag,
		type Token,
		type Txn,
	} from '#/objects';

	import {
		Screen,
		Header,
	} from '#/ui';
	import Row from '#/ui/Row.svelte';
import { onMount } from 'svelte';
import GenericRow from '#/ui/GenericRow.svelte';

	let dm_results: HTMLElement;
	let a_results: SearchItem[] = [];

	function search(s_search: string) {
		console.log('search: '+s_search);

		// dm_results.innerHTML = '';
		a_results = $yw_fuse.search(s_search).slice(0, 64).map(g => g.item);
		
		// for(const g_item of a_results)	{
		// 	const gc_row = {
		// 		name: g_item.label,
		// 		detail: g_item.detail,
		// 	};

		// 	let gd_thing: Icon.Refable | null = null;

		// 	if(gd_thing) {
		// 		Object.assign(gc_row, {
		// 			iconRef: gd_thing.iconRef || Icon.Def.BLANK.iri,
		// 			tagRefs: gd_thing.tagRefs || [],
		// 		});
		// 	}

		// 	new Row({
		// 		target: dm_results,
		// 		props: gc_row,
		// 	});
		// }
	}

	$: {
		if($yw_search) {
			search($yw_search);
		}
	}

	// onMount(() => {
	// 	search($yw_search);
	// });
</script>

<style lang="less">
	.search {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 1000;

		.results {

		}
	}
</style>


<div class="search" class:display_none={!$yw_search}>
	<Screen root>
		<Header network account search
			isSearchScreen {...($yw_header_props || {})} on:search={(d_event) => search(d_event.detail)}
		/>

		<p style='font-size:12px'>
			<span style='color:var(--theme-color-caution);'>Disclaimer: </span>
			This temporary search interface does not represent the look and function of the actual search interface currently under development for beta.
			<!-- This basic search interface is for MVP purposes only and does not represent the advanced search feature currently under development for beta. -->
		</p>

		<div class="results no-margin" bind:this={dm_results}>
			{#key a_results}
				{#each a_results as g_result}
					<GenericRow item={g_result} />
				{/each}
			{/key}
		</div>
	</Screen>
</div>
