<script lang="ts">
	import { slide } from 'svelte/transition';

	import BigNumber from 'bignumber.js';
	import { yw_asset_send, yw_holding_send } from "#/app";

	import SX_INCREMENT from '@material-design-icons/svg/outlined/expand_less.svg?raw';
	import SX_DECREMENT from '@material-design-icons/svg/outlined/expand_more.svg?raw';
import type { Token } from '#/objects/token';
import { amount_to_fiat, format_amount, H_TOKENS, H_VERSUS_USD } from '#/sim/data';

	export let value = '';

	const YG_ZERO = new BigNumber(0);
	const YG_ONE = new BigNumber(1);

	// $: xg_amount = BigInt((new BigNumber(value)).shiftedBy($yw_asset_send?.def.decimals || 0)+'');

	$: yg_step = $yw_asset_send? YG_ONE.shiftedBy(-$yw_asset_send?.def.decimals): YG_ZERO;
	$: yg_max = $yw_holding_send?.amount(H_TOKENS) || YG_ZERO;

	// // fix svelte's stupid mistake of coercing input[type="number"] values to es numbers
	// $: {
	// 	if('number' === typeof value) {
	// 		value = dm_input.value;
	// 	}
	// }

	function capture_input(d_event: Event) {
		value = (d_event.target as HTMLInputElement).value;
	}

	function increment() {
		const yg_next = yg_step.plus(value);
		if(yg_next.lte(yg_max)) {
			value = yg_next+'';
		}
		else {
			value = yg_max+'';
		}

		check_validity();
	}

	function decrement() {
		const yg_next = yg_step.negated().plus(value);
		if(yg_next.gte(0)) {
			if(yg_next.gt(yg_max)) {
				value = yg_max+'';
			}
			else {
				value = yg_next+'';
			}
		}
		else {
			value = '0';
		}

		check_validity();
	}

	function long_press(f_action: VoidFunction) {
		let i_ticker = 0;
		const i_buffer = window.setTimeout(() => {
			i_ticker = window.setInterval(f_action, 90);
		}, 1000);

		window.addEventListener('mouseup', () => {
			clearTimeout(i_buffer);
			clearInterval(i_ticker);
		}, {
			once: true,
		});
	}

	export let error = '';
	let dm_input: HTMLInputElement;

	function invalidate(s_msg: string) {
		dm_input.setCustomValidity(s_msg);
		error = s_msg;
	}

	function check_validity() {
		if(!value) {
			return invalidate('Enter an amount');
		}

		let yg_input!: BigNumber;
		try {
			yg_input = new BigNumber(value);
		}
		catch(e_parse) {
			return invalidate('Invalid number')
		}

		if(yg_input.lt(YG_ZERO)) {
			return invalidate('Value must be positive');
		}

		if(yg_input.gt(yg_max)) {
			return invalidate('Insufficient balance');
		}

		error = '';
	}

	export let showValidation = 0;
	$: {
		if(showValidation) {
			check_validity();
		}
		else if(!value) {
			error = '';
		}
	}
</script>

<style lang="less">
	@import '../style/util.less';

	input[type="number"] {
		appearance: textfield;

		&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
			-webkit-appearance: none;
		}
	}

	.amount-input {
		position: relative;
	}

	.occupy {
		position: absolute;
		right: 0;
		top: 0;
		height: var(--ui-row-height);

		display: flex;
		align-items: center;

		.adjust {

			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 4px;
			margin-right: 8px;

			.icon {
				cursor: pointer;
				padding: 0px 8px;
				--icon-diameter: 16px;
				--icon-color: var(--theme-color-primary);

				.increment {
					padding-top: 4px;
				}

				.decrement {
					padding-bottom: 4px;
				}
			}
		}

		.equivalent {
			.font(regular, @size: 13px, @weight: 300);

			.amount {
				color: var(--theme-color-text-med);
			}

			.fiat {
				color: var(--theme-color-primary);
			}
		}
	}
</style>

<div class="amount-input">
	<input
		disabled={!$yw_asset_send}
		type="number"
		min="0"
		max={yg_max+'' || '0'}
		step="0.{'0'.repeat(($yw_asset_send?.def.decimals || 1) - 1)}1"
		required
		on:change={() => check_validity()}
		on:input={capture_input}
		{value}
		bind:this={dm_input}
		on:invalid={d => d.preventDefault()}
		class:invalid={error}
	>

	{#if $yw_asset_send && $yw_holding_send}
		<span class="occupy">
			<span class="equivalent">
				<span class="amount">
					= {format_amount(+value * H_VERSUS_USD[$yw_asset_send.def.iri].value, true)}
					<!--  amount_to_fiat(+value, $yw_asset_send, true) -->
				</span>
				<span class="fiat">
					USD
				</span>
			</span>

			<span class="adjust">
				<span class="icon increment clickable"
					on:click={() => increment()}
					on:mousedown={() => long_press(increment)}
				>
					{@html SX_INCREMENT}
				</span>

				<span class="icon decrement clickable"
					on:click={() => decrement()}
					on:mousedown={() => long_press(decrement)}
				>
					{@html SX_DECREMENT}
				</span>
			</span>
		</span>
	{/if}

	{#if error}
		<span class="validation-message">
			{error}
		</span>
	{/if}
</div>