<script lang="ts">
	import { pop, push_screen, restart } from "#/app";

	import type {
		Token,
	} from "#/objects";

	import { H_CHAINS, H_HOLDINGS, H_TAGS, H_TOKENS } from "#/sim/data";

	import {
		Screen,
		Header,
		Field,
		Tags,
		Address,
		Info,
		IconEditor,
	} from "#/ui";
import TokenHoldingView from "./TokenHoldingView.svelte";

	export let token: Token | null = null;
	export const chainId = token? H_CHAINS[token.def.chainRef].def.id: '.all';
	export const tokenId = token?.def.address || '.new';

	let s_name = token?.def.label || '';
	let s_symbol = token?.def.symbol || '';
	let sa_address = token?.def.address || '';
	let s_notes = '';
	let a_tags = token?.def.tagRefs.map(p => H_TAGS[p]) || [];

	let s_err_name = '';
	let s_err_symbol = '';
	let s_err_address = '';

	$: b_form_valid = !!(s_name && s_symbol && sa_address
		&& !s_err_name && !s_err_symbol && !s_err_address);

	$: {
		if(c_show_validations) {
			s_err_name = s_name? '': 'Enter a name';
			s_err_symbol = s_symbol? '': 'Symbol cannot be blank';
		}
	}

	let c_show_validations = 0;

	let b_submitted = false;

	function submit() {
		if(!b_form_valid) {
			c_show_validations++;
			return;
		}
		else {
			if(b_submitted) return false;

			b_submitted = true;

			Object.assign(token?.def, {
				label: s_name,
				symbol: s_symbol,
				tagRefs: a_tags.map(k => k.def.iri),
			});

			restart();
			
			// if($yw_asset_send!.def.native) {
			// 	// push_screen(SendNative, {});
			// }
			// else {
			// 	push_screen(Execute, {
			// 		contract: $yw_asset_send?.def.address,
			// 		snip20: {
			// 			transfer: {
			// 				recipient: to,
			// 				amount: $yw_asset_send?.denomFromString(s_amount) || '0',
			// 			},
			// 		},
			// 	});
			// }
		}
	}

	let b_dead = false;
</script>

<style lang="less">

</style>

<Screen form slides on:submit={() => submit()}>
	<Header
		plain pops
		name="{token? 'Edit': 'Add New'} Token"
	/>

	<Field key="profile-icon" name="Profile icon">
		<IconEditor iconRef={token?.def.iconRef || ''} />
	</Field>

	<Field
		key="token-name"
		name="Name"
	>
		<input class:invalid={s_err_name} type="text" bind:value={s_name} placeholder="Secret Coin">

		{#if s_err_name}
			<span class="validation-message">
				{s_err_name}
			</span>
		{/if}
	</Field>

	<Field
		key="token-symbol"
		name="Symbol"
	>
		<input class:invalid={s_err_symbol} type="text" bind:value={s_symbol} placeholder="sCOIN">

		{#if s_err_symbol}
			<span class="validation-message">
				{s_err_symbol}
			</span>
		{/if}
	</Field>

	<Field
		key="token-address"
		name="Address"
	>
		<Info key="token-address">
			<Address copyable address={sa_address} />
		</Info>
	</Field>

	<Field
		key="token-notes"
		name="Secure Notes"
	>
		<textarea bind:value={s_notes} placeholder=""></textarea>
	</Field>

	<hr>

	<h3>
		{token? 'Edit': 'Add'} Tags
	</h3>

	<Tags editable bind:tags={a_tags} />

	<div class="action-line" class:pointer-events_none={b_dead}>
		<button type="button" on:click={() => (b_dead = true) && pop()}>
			Back
		</button>

		<button class="primary"
			readonly={!b_form_valid}
			on:click={() => submit()}
		>
			{token? 'Save': 'Add'}
		</button>
	</div>
</Screen>