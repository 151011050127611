<script lang="ts">
	import {
		push_screen,
		pop,
	} from '#/app';

	import {
		Screen,
	} from '#/ui';

	import InitCreateVerify from './InitCreateVerify.svelte';

	// import type {
	//    SecretHdWallet,
	// } from '#/wallet';

	import { onMount } from "svelte";

	// @ts-ignore import json

	import {default as A_BIP39_WORDS} from 'bip39/src/wordlists/english.json';
	import MnemonicTable from '#/ui/MnemonicTable.svelte';

	function words() {
		const a_list = A_BIP39_WORDS;
		const nl_list = a_list.length;

		const a_build = [];
		const as_indexes = new Set<number>();
		for(;;) {
			const i_word = Math.floor(Math.random() * nl_list);
			if(as_indexes.has(i_word)) continue;
			as_indexes.add(i_word);
			if(24 === a_build.push(a_list[i_word])) break;
		}

		return a_build;
	}

	const a_words = words();
</script>

<style lang="less">
</style>

<Screen progress={[2, 4]}>
	<p slot="help">
		You do NOT need to write this down. You will receive clues on the next screen.
	</p>

	<h3>
		Write down your recovery phrase
	</h3>

	<p>
		Write down this secret recovery phrase on paper and keep it in a safe place. Do not store it digitally! The recovery phrase is used to generate all accounts that are stored on your device. You can view your phrase again later in Settings > Security & Privacy.
	</p>

	<MnemonicTable words={a_words} />

	<div class="action-line">
		<button on:click={() => pop()}>
			Cancel
		</button>

		<button class="primary" on:click={() => push_screen(InitCreateVerify, {mnemonic:a_words})}>
			Continue
		</button>
	</div>
</Screen>