<script lang="ts">
	import { yw_account, yw_chain, yw_help } from "#/app";

	import RecipientSelectItem from './RecipientSelectItem.svelte';

	import Select from 'svelte-select';
	import SX_SCAN from '@material-design-icons/svg/outlined/qr_code_scanner.svg?raw';
	import { H_CONTACTS,  } from "#/sim/data";
	import RecipientSelectSelection from "./RecipientSelectSelection.svelte";
	import { oderac } from "#/util/belt";
import type { Contact } from "#/objects/contact";
import { onMount } from "svelte";
import { dd, qs } from "#/util/dom";

	export let address: string = '';

	export let error = '';

	interface Option {
		value: string;
		label: string;
		contact: Contact;
	}

	let a_contacts = oderac(H_CONTACTS, (_, k_contact) => ({
		value: k_contact.address($yw_chain),
		label: `${k_contact.address($yw_chain)} ${k_contact.def.label}`,
		contact: k_contact,
	}));

	a_contacts.unshift({
		value: '',
		label: '',
		contact: null as unknown as Contact,
	});

	function select(d_event: CustomEvent<Option>) {
		address = d_event.detail.value;
		error = '';
	}

	function clear() {
		address = '';
	}

	let s_manual_input: string;
	let k_item_select: typeof a_contacts[0];
	if(address) {
		for(const g_entry of a_contacts) {
			if(address === g_entry.value) {
				k_item_select = g_entry;
				break;
			}
		}
	}


	let s_accepted_input = '';
	let b_hide_cursor = false;

	let b_list_open = false;

	$: {
		b_hide_cursor = false;

		if(s_manual_input) {
			check_manual_input();
		}
		else {
			b_hide_cursor = !!s_accepted_input;
			s_accepted_input = '';
		}
	}

	function check_manual_input() {
		s_accepted_input = '';

		if(!$yw_chain) {
			// should not be able to get here without being on a chain
			error = 'No chain set';
		}
		else if(!$yw_chain.verifyAddressLegitimacy(s_manual_input)) {
			error = 'Invalid address for this chain';
		}
		else {
			error = '';

			// search for address in contacts
			for(const g_entry of a_contacts) {
				// contact exists
				if(s_manual_input === g_entry.value) {
					// clear filter text
					s_manual_input = '';
					
					// select contact instead of using raw address
					k_item_select = g_entry;

					// close list
					b_list_open = false;

					// hide cursor
					b_hide_cursor = true;
					return;
				}
			}

			s_accepted_input = s_manual_input;

			// select address immediately
			setTimeout(() => {
				(qs(dm_sender, '.manual>.address') as HTMLElement).click();
				b_list_open = false;
			}, 0);
		}
	}

	let dm_sender: HTMLElement;
	
	export let showValidation = 0;
	$: {
		if(showValidation) {
			if(!address) {
				if(s_manual_input) {
					check_manual_input();
				}
				else {
					error = 'Enter a recipient';
				}
			}
			else if(!$yw_chain.verifyAddressLegitimacy(address)) {
				error = 'Invalid address for this chain';
			}
			else {
				error = '';
			}
		}
		else if(!address) {
			error = '';
		}
	}
</script>


<style lang="less">
	@import '../style/util.less';

	.sender {
		position: relative;

		.style-svelte-select();
		.font(regular, 400, 13px);

		--inputPadding: 16px;
		--padding: 0 4px;
		--itemPadding: 0;
		--selectedItemPadding: 0;

		>input {
			&::after {
				content: '';
				position: absolute;
				right: 0;
			}
		}

		>.icon {
			--icon-diameter: 24px;
			--icon-color: var(--theme-color-primary);
			position: absolute;
			top: 0;
			right: 0;
			padding: 12px;
			cursor: pointer;
		}

		.listContainer .empty {
			:global(&) {
				white-space: pre-wrap;
			}
		}

		&.hide-cursor {
			.selectContainer input[type="text"] {
				:global(&) {
					caret-color: transparent;;
				}
			}
		}
	}
</style>


<div class="sender" bind:this={dm_sender} class:hide-cursor={b_hide_cursor}>
	<Select id="recipient-select"
		placeholder="Address or contact"
		listOffset={1}
		isClearable={!!address}
		isCreatable={!!s_accepted_input}
		Item={RecipientSelectItem}
		Selection={RecipientSelectSelection}
		items={a_contacts}
		value={k_item_select}
		noOptionsMessage={'Stop typing in the address. \n Use copy/paste instead!'}
		bind:filterText={s_manual_input}
		bind:listOpen={b_list_open}
		on:select={select}
		on:clear={clear}
		containerClasses={error? 'invalid': ''}
	/>

	<span class="icon" class:visibility_hidden={!!address} on:click={() => {
		$yw_help = [
			dd('br'),
			dd('p', {}, [`No QR code scanner in MVP. But you can try copying the address text by using your device's built-in camera app.`]),
			dd('br'),
			dd('br'),
		];
	}}>
		{@html SX_SCAN}
	</span>

	{#if error}
		<span class="validation-message">
			{error}
		</span>
	{/if}
</div>