<script lang="ts">
	export let words: string[];

	export let reveal: number = Infinity;

	$: a_words = words;
</script>

<style lang="less">
	@import '../style/util.less';

	.mnemonic {
		flex: auto;
		overflow-y: scroll;
		.hide-scrollbar();

		display: grid;
		grid-gap: 6px;
		grid-template-columns: min-content auto min-content auto;

		border-radius: var(--ui-border-radius);
		border: 1px dashed var(--theme-color-border);
		padding: var(--ui-padding);
		padding-top: calc(var(--ui-padding) * 0.5);
		padding-bottom: calc(var(--ui-padding) * 0.5);

		>.index {
			color: var(--theme-color-text-med);
		}

		>.word {
			color: var(--theme-color-text-light);
		}

		>* {
			visibility: hidden;

			&.visible {
				visibility: visible;
			}
		}
	}
</style>


<div class="mnemonic">
	{#each a_words as _, i_iter}
		{@const i_word = i_iter % 2? 12+((i_iter+1)/2): 1+(i_iter/2)}
		<span class="index" class:visible={i_word <= reveal}>
			{i_word}.
		</span>
		<span class="word" class:visible={i_word <= reveal}>
			{a_words[i_word-1]}
		</span>
	{/each}
</div>
