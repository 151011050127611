<script lang="ts">
	import {
		Screen,
		Field,
		Info,
		Row,
		Address,
	} from "#/ui";

	import type {
		Account,
	} from '#/objects';

	import AccountCreate from './AccountCreate.svelte';
	import Header from "#/ui/Header.svelte";
	import { ode } from "#/util/belt";
	import { H_ACCOUNTS } from "#/sim/data";
	import { push_screen, yw_chain } from "#/app";
	import SubHeader from "#/ui/SubHeader.svelte";
	import DeadEnd from "./DeadEnd.svelte";
	import AccountView from "./AccountView.svelte";

	// let a_accounts: Account[];
	// $: {
	// 	if($yw_chain) {
	// 		a_accounts = Object.values(H_ACCOUNTS).filter(k => !k.aggregator);
	// 	}
	// }

	const a_accounts = Object.values(H_ACCOUNTS).filter(k => !k.aggregator);

</script>

<style lang="less">
	@import '../style/util.less';

	.hd-path {
		:global(&) {
			.font(tiny);
			color: var(--theme-color-text-med);
		}
	}
</style>

<Screen nav root>
	<Header search network account
	>
	</Header>

	<SubHeader
		title="Accounts"
		on:add_new={() => push_screen(AccountCreate)}
	/>

	<div class="rows no-margin">
		{#each a_accounts as k_account}
			{@const gd_account = k_account.def}
			<Row
				name={gd_account.label}
				address={k_account.address($yw_chain)}
				iconRef={gd_account.iconRef}
				iconClass={'square pfp'}
				tagRefs={gd_account.tagRefs}
				on:click={() => push_screen(AccountView, {
					account: k_account,
				})}
			>
				<svelte:fragment slot="detail">
					<div class="hd-path">
						StarShell - m/44'/118'/0'/0/{+(gd_account.id)-1}
					</div>

					<Address address={k_account.address($yw_chain)} />
				</svelte:fragment>
			</Row>
		{/each}
	</div>
</Screen>