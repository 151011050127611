import {
	Addressable,
	AddressType,
	ClassType,
	Definable,
} from './_core';

import {
	type Tag,
	type Chain,
	type Txn,
	Icon,
	Contact,
} from '#/objects';


type ContractPath = `${Chain.Path}/contracts/${string}`;
	
type ContractRef = Chain.MemberRef<ClassType.CONTRACT>;

interface ContractDef extends Chain.Asset<`contracts/${string}`> {
	class: ClassType.CONTRACT,
	type: Contact.Type;
	chainRef: Chain.Ref;
	codeHash: string;
}

interface ContractConfig {
	label: string;
	address: string;
	codeHash: string;
	chainRef: Chain.Ref;
	tagRefs?: Tag.Ref[],
	iconRef?: Icon.Ref;
	type?: Contact.Type;
}

class ContractDef {
	static fromConfig(gc_contact: ContractConfig): ContractDef {
		return {
			...gc_contact,
			iconRef: gc_contact.iconRef as Icon.Ref,
			//  || Icon.Def.BLANK.iri,
			tagRefs: gc_contact.tagRefs || [],
			iri: Contract.refFromChainAddr(gc_contact.chainRef, gc_contact.address),
			type: gc_contact.type || Contact.Type.CONTRACT,
			class: ClassType.CONTRACT,
		};
	}
}



export class Contract extends Addressable<ContractDef> {
	static refFromChainAddr(p_chain: Chain.Ref, sa_addr: string): ContractRef {
		return `${p_chain}contracts/${sa_addr}/`;
	}

	protected _a_history: Txn.Bankish[] = [];

	get history(): Txn.Bankish[] {
		return this._a_history;
	}

	address(k_chain: Chain): string {
		return k_chain.def.iri === this._g_def.chainRef? this._g_def.address: '';
	}

	get label(): string {
		return this._g_def.label;
	}

	get type(): AddressType {
		return AddressType.CONTRACT;
	}
}

export namespace Contract {
	export type Ref = ContractRef;

	export type Def = ContractDef;
	export const Def = ContractDef;

	export type Path = ContractPath;

	export type Config = ContractConfig;
}
