<script lang="ts">
	import {
		createEventDispatcher,
	} from 'svelte';

	import { Tag, Icon } from '#/objects';
	
	import {
		Put,
		Tags,
	} from '#/ui';
	
	import LockOutline from 'svelte-material-icons/LockOutline.svelte';
	
	import {
		H_ADDR_TO_CONTACT,
		H_ICONS,
		H_TAGS,
	} from '#/sim/data';
	
	import Address from './Address.svelte';
	
	export let name: string;
	export let amount: string = '';
	export let fiat: string = '';
	export let symbol: string = '';
	export let lockIcon: boolean = false;
	export let address: string = '';
	export let detail: string = '';
	export let prefix: string = '';
	export let iconRef: Icon.Ref | null = null;
	export let tagRefs: null | Tag.Ref[] = null;
	export let rootStyle = '';

	export let icon = iconRef? H_ICONS[iconRef] || Icon.BLANK: null;
	export let iconClass = '';
	const k_icon = icon;
	$: a_tags = tagRefs?.length? tagRefs.map(p_tag => H_TAGS[p_tag]): [];

	const k_contact = address? H_ADDR_TO_CONTACT[address] || null: null;

	const dispatchEvent = createEventDispatcher();

</script>

<style lang="less">
	@import '../style/util.less';

	.monoline() {
		white-space: nowrap;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	:root {
		--row-padding: 20px;
		--icon-margin: 14px;
	}

	.row {
		padding: var(--row-padding);
		border-top: 1px solid var(--theme-color-border);
		border-bottom: 1px solid var(--theme-color-border);
		max-width: var(--app-window-width);
		// overflow-x: scroll;
		cursor: pointer;

		// display: flex;
		// align-items: center;

		display: flex;
		flex-direction: column;

		>.banner {
			display: flex;
			align-items: center;
		}

		&:nth-child(n+2) {
			border-top-color: transparent;
		}

		
		.icon {
			--icon-diameter: var(--app-icon-diameter);

			flex: 0 0 var(--icon-diameter);
			margin-right: var(--icon-margin);

			&.bordered {
				--icon-diameter: calc(var(--app-icon-diameter) - 2px);
				background-color: var(--button-color, var(--theme-color-border));
			}

			&>.icon-20 {
				:global(&) {
					--icon-diameter: 18px;
				}
			}

			img {
				:global(&) {
					border-radius: 20%;
				}
			}

			&.site {
				:global(&) {
					background-color: var(--theme-color-text-light);
				}
			}
		}

		.content {
			flex: auto;

			display: flex;
			max-width: calc(var(--app-window-width) - var(--app-icon-diameter) - var(--icon-margin) - var(--row-padding) - var(--row-padding));

			>.part {
				flex: auto;

				display: flex;
				flex-direction: column;

				&.main {
					overflow: hidden;

					>.title {
						flex: 0;

						>.name {
							.font(regular);

							display: inline-flex;
							max-width: 100%;

							>.text {
								max-width: 100%;
								.monoline();
							}
						}

						>.symbol {
							color: var(--theme-color-text-med);
							margin-left: 0.63ch;
						}

						>svg {
							:global(&) {
								margin-left: -1px;
								vertical-align: -3px;
							}
						}
					}

					>.subtitle {
						flex: 0;

						>:nth-child(n+2) {
							:global(&) {
								margin-left: 4px;
							}
						}

						>.detail {
							color: var(--theme-color-text-med);
							
							.font(tiny);
						}

						>.contact {
							display: flex;
							color: var(--theme-color-text-med);
							.font(tiny);
							
							>.icon {
								--icon-diameter: 0.8em;
								--icon-margin: 0.5em;
								margin-top: -1px;
								color: var(--theme-color-text-med);
							}
							>.text {
							}
						}
					}
				}
				
				&.status {
					text-align: right;
					max-width: 55%;

					.amount {
						.font(regular);
						flex: 0;
					}

					.fiat {
						.font(tiny);
						flex: 0;
						color: var(--theme-color-text-med);
						white-space: nowrap;
					}
				}
			}
		}
	}

	.number {
		display: inline-flex;
		max-width: 18ch;
		overflow-x: scroll;
		.hide-scrollbar();

		white-space: nowrap;
	}

	.rest {
		margin-left: calc(var(--icon-margin) + var(--app-icon-diameter));
	}
</style>

<div class="row clickable" style={rootStyle} on:click>
	<div class="banner">
		{#if k_icon || $$slots.icon}
			<span class="icon {iconClass}" class:bordered={k_icon?.isHtml}>
				<slot name="icon">
					<Put element={(k_icon || Icon.BLANK).render()} /> 
				</slot>
			</span>
		{/if}
		<span class="content">
			<span class="main part">
				<div class="title">
					<span class="name">
						<span class="text">
							{name}
						</span>
					</span>
					<!-- {#if symbol}
						<span class="symbol">
							{symbol}
						</span>
					{/if} -->
					{#if lockIcon}
						<LockOutline color='var(--theme-color-text-med)' size='18px' />
					{/if}
				</div>
				{#if address || symbol || detail || a_tags.length || $$slots.detail}
					<div class="subtitle">
	<!-- 					
						{#if k_contact}
							<span class="contact">
								<span class="icon">
									<Fa icon={faUser} />
								</span>
								<span class="text">
									{k_contact.def.label}
								</span>
							</span> -->
						{#if detail || symbol || $$slots.detail}
							<span class="detail">
								<slot name="detail">
									{prefix}{detail || symbol}
								</slot>
							</span>
						{:else if address}
							<Address address={address} />
						{/if}
					</div>
				{/if}
			</span>

			<span class="status part">
				{#if $$slots.status}
					<slot name="status"></slot>
				{:else if amount}
					<div class="amount">
						<span class="number">
							<span>
								{amount}
							</span>
						</span>
					</div>

					{#if fiat}
						<div>
							<span class="fiat">
								{fiat}
							</span>
						</div>
					{/if}
				{/if}
			</span>
		</span>
		{#if $$slots.right}
			<slot name="right" />
		{/if}
	</div>

	<div class="rest">
		{#if a_tags.length || $$slots.tags}
			<slot name="tags">
				<Tags tags={a_tags} collapsed />
			</slot>
		{/if}
	</div>
</div>
