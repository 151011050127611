<script lang="ts">
import { push_screen, yw_account, yw_chain } from '#/app';

	import SX_DROPDOWN from '#/asset/nav/drop-down.svg?raw';
import DeadEnd from '#/screen/DeadEnd.svelte';
	import NftView from '#/screen/NftView.svelte';
	import { H_ICONS, H_NFTS, H_TAGS } from '#/sim/data';
	import { ode } from '#/util/belt';
	import { slide } from 'svelte/transition';
	import Collapsable from './Collapsable.svelte';
	import Put from './Put.svelte';
	import Tags from './Tags.svelte';

	export let title: string;
	export let collectionAddress: string;


	$: a_nfts = ode(H_NFTS).map(([, k]) => k)
		.filter(k_nft => {
			return collectionAddress === k_nft.def.address && $yw_account.address($yw_chain) === k_nft.def.owner;
		});
</script>


<style lang="less">
	// .screen div.collection:last-child {
	// 	:global(&) {
	// 		margin-bottom: 6px;
	// 	}
	// }
</style>

<Collapsable classNames="collection" title={title} expanded>
	<style lang="less">
		@import '../style/util.less';

		@thumb-width: calc(var(--app-window-width) / 3.75);

		.wall {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-around;

			>.nft {
				display: flex;
				flex-flow: column;
				align-items: center;
				padding: 0.5em 2%;
				cursor: pointer;
				max-width: @thumb-width;

				transition: outline-color 350ms var(--ease-out-quad);
				outline: 1px solid transparent;
				border-radius: 18px;

				// &:hover {
				// 	outline-color: fade(@theme-color-border, 75%);
				// }

				&:active {
					outline-color: var(--theme-color-primary);
				}

				>.icon {
					--icon-diameter: @thumb-width;
					border-radius: 12px;
					overflow: hidden;
					outline: 1px solid var(--theme-color-border);
				}

				>.label {
					.font(tiny);
					color: var(--theme-color-text-med);
					margin-top: 6px;
					text-align: center;
					max-width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				&.void {
					&:hover,&:active {
						outline-color: transparent;
						cursor: default;
					}
				}
			}
		}
	</style>

	<div class="wall" transition:slide={{duration:350}}>
		{#each a_nfts as k_nft}
			<span class="nft clickable" on:click={() => push_screen(DeadEnd)}>
				<span class="icon">
					<img src={k_nft.def.image} />
				</span>

				<span class="label">
					#{k_nft.def.id}
				</span>

				<Tags collapsed tags={k_nft.def.tagRefs.map(p => H_TAGS[p])}>
					<span slot="suffix">
						{k_nft.def.label}
					</span>
				</Tags>
			</span>
		{/each}

		{#each a_nfts.slice(0, (3 - (a_nfts.length % 3)) % 3) as _}
			<span class="nft void">
				<span class="icon" style="outline: none;">
				</span>
			</span>
		{/each}
	</div>
</Collapsable>
