<script lang="ts">
	import { faClone } from '@fortawesome/free-solid-svg-icons';

	import SX_COPY from '#/asset/action/copy.svg?raw';

	import Fa from 'svelte-fa';

	export let key: string;
	// export let address = false;
	export let copyable = false;
</script>

<style lang="less">
	.info {
		--icon-diameter: 24px;

		white-space: nowrap;
		height: var(--ui-row-height);
		box-sizing: border-box;
		padding: var(--ui-padding) 12px;

		border: 1px dashed var(--theme-color-border);
		border-radius: var(--ui-border-radius);

		display: flex;
		align-items: center;

		&.copyable {
			cursor: copy;
		}

		>* {
			flex: var(--flex, 0);
		}

		.info-value {
			width: 100%;
			display: flex;

			>.address {
				:global(&) {
					width: calc(100% - 1ch) !important;
					color: var(--theme-color-text-light) !important;
				}
			}
		}

		.icon {
			--icon-diameter: var(--icon-diameter);
			--icon-color: var(--theme-color-primary);
			display: inline-flex;
		}
	}
</style>

<div class="info" class:copyable={copyable}>
	<span id={key} class="info-value">
		<slot></slot>
	</span>
	{#if copyable}
		<span class="icon">
			{@html SX_COPY}
		</span>
	{/if}
</div>