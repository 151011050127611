<script lang="ts">
	import { onMount } from "svelte";

	export let element: HTMLElement;

	let dm_dummy: HTMLElement;

	onMount(() => {
		dm_dummy.replaceWith(element);
	});
</script>

<span bind:this={dm_dummy} />
