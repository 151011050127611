<script lang="ts">
	import {
		Screen,
	} from '#/ui';

	import {
		push_screen,
	} from '#/app';

	import InitCreate from './InitCreate.svelte';

	// import * as bip39 from 'bip39';

	// (() => {
	//    const a_words = bip39.generateMnemonic();
	//    console.log(a_words);
	// })()
</script>

<style lang="less">
	@import '../style/util.less';

	.welcome {
		height: 320px;
		overflow-y: hidden;
	}

	.terms {
		.font(tiny, 300);
		text-align: center;
		margin-top: 1rem;
	}
</style>


<Screen progress={[1, 4]} root>
	<h3>
		Create or import an account
	</h3>

	<p>
		In order to send and receive digital assets, your device needs to keep a secret and cryptographically secure account.
	</p>
		
	<!-- <p>
		Or, if you already have an account, mnemonic, or hardware wallet, you can import it below.
	</p> -->

	<div class="action-wall">
		<button class="primary" on:click={() => push_screen(InitCreate)}>
			Create a new account
		</button>

		<button>
			Import with private key
		</button>

		<button>
			Import with mnemonic
		</button>

		<button>
			Import with hardware
		</button>
	</div>

	<div class="terms">
		By proceeding, you agree to the <a href="#">Terms & Conditions</a>.
	</div>
</Screen>