<script lang="ts">
	import {
		goto_ref,
	} from '#/app';

	import {
		Screen,
	 } from "#/ui";

</script>


<Screen nav={false} slides={false} leaves={false}>
	<!-- Locked.

	<button on:click={() => goto_ref('/root')}>

	</button> -->
</Screen>