<script lang="ts">
import { pop, reset_all, ThreadId, yw_thread_id } from "#/app";

	import {
		Header,
		Screen,
	} from "#/ui";

	import { faUserAstronaut } from "@fortawesome/free-solid-svg-icons";
	import Fa from "svelte-fa";

	export let reset = false;

	function cont() {
		if(reset) {
			reset_all();
			$yw_thread_id = ThreadId.TOKENS;
		}
		else {
			pop();
		}
	}
</script>

<style lang="less">
	.icon {
		padding-top: 25%;
	}
</style>

<Screen>
	<Header pops
		name="Not yet available"
	/>

	<h3>
		Nothing to see here!
	</h3>

	<p>
		This screen has not yet been implemented.
	</p>

	<p>
		Please continue exploring the beta.
	</p>

	<center>
		<span class="icon">
			<Fa icon={faUserAstronaut} size='10x' />
		</span>
	</center>

	<div class="action-line">
		<button class="primary" on:click={() => cont()}>
			Continue
		</button>
	</div>
</Screen>