<script context="module" lang="ts">
	export enum TxnContext {
		NONE='none',
		TOKEN='token',
		CONTACT='contact',
	}
</script>

<script lang="ts">
	import {
		push_screen,
		yw_account,
yw_chain,
	} from "#/app";

	import {
		amount_to_fiat,
		format_amount,
		H_ADDRESS_TO_TOKEN,
		H_ADDR_TO_CONTACT,
H_TOKENS,
	} from "#/sim/data";

	import {
		Txn,
		Icon,
		Contact,
		Token,
	} from "#/objects";

	import Row from "./Row.svelte";

	import SX_SEND from '#/asset/action/send.svg?raw';
	import SX_RECV from '#/asset/action/recv.svg?raw';

	// import {definition} from '@fortawesome/free-solid-svg-icons/faRobot';
	// const SXP_ROBOT = definition.icon[4];
	const SXP_ROBOT = '';

	import SX_PERSONAL from '@material-design-icons/svg/outlined/account_box.svg?raw';
	import SX_CONTRACT from '@material-design-icons/svg/outlined/analytics.svg?raw';
import Address from "./Address.svelte";
import TxnView from "#/screen/TxnView.svelte";
import DeadEnd from "#/screen/DeadEnd.svelte";

	export let txns: Txn[];
	export let context: TxnContext = TxnContext.NONE;

	const b_ctx_contact = context === TxnContext.CONTACT;
	const b_ctx_token = context === TxnContext.TOKEN;

	const H_TXN_ICONS = {
		[Txn.Type.UNKN]: Icon.BLANK,
		[Txn.Type.SEND]: Icon.fromHtml(SX_SEND, {class:'icon-20'}),
		[Txn.Type.RECV]: Icon.fromHtml(SX_RECV, {class:'icon-20'}),
		[Txn.Type.COMP]: Icon.fromHtml(`<svg><path d="${SXP_ROBOT}"/></svg>`, {class:'icon-20'}),
		[Txn.Type.SNIP20_XFER]: Icon.fromHtml(SX_RECV, {class:'icon-20'}),
	} as Record<Txn.Type | Txn.BankishType, Icon>;

	const H_SUMMARIZERS = {
		[Txn.Type.UNKN]: (_) => 'Unknown',
		// ...TxnContext.CONTACT === context
		// 	? {
		// 		[Txn.Type.SEND]: (k) => `Send ${k.token(H_TOKENS).def.symbol} on ${k.date()}`,
		// 		[Txn.Type.RECV]: (k) => `Receive ${k.token(H_TOKENS).def.symbol} on ${k.date()}`,
		// 	}
		// 	: {
				[Txn.Type.SEND]: (k) => `Send on ${k.date()}`,
				[Txn.Type.RECV]: (k) => `Recv on ${k.date()}`,
			// },
		[Txn.Type.COMP]: (k) => `Compute on ${k.date()}`,
	} as Record<Txn.Type | Txn.BankishType, (k_txn: Txn) => string>;

	const H_TXN_CLASSES = {
		[Txn.Type.SEND]: 'color-icon-send',
		[Txn.Type.RECV]: 'color-icon-recv',
	} as Record<Txn.Type | Txn.BankishType, string>;

	function detail_bankish(g_bankish?: Txn.Bankish | null): {prefix:string, name:string, icon:string} {
		if(!g_bankish) return {prefix:'', name:'', icon:''};
		
		const k_contact = H_ADDR_TO_CONTACT[g_bankish.address];
		
		return {
			prefix: (Txn.BankishType.SEND === g_bankish.type? 'to': 'fr')+':',
			name: k_contact? k_contact.def.label: '',
			icon: k_contact
				? Contact.Type.PERSON === k_contact.def.type
					? SX_PERSONAL
					: SX_CONTRACT
				: '',
		};
	}
</script>

<style lang="less">
	@import '../style/util.less';

	.row .main .title {
		:global(&) {
			min-width: min-content;
		}
	}

	.row .pfp.icon {
		:global(&) {
			color: var(--theme-color-text-light);
			border-radius: 32px;
		}
	}

	.txn-type.icon {
		vertical-align: middle;
		--icon-diameter: 18px;
		--icon-color: var(--theme-color-text-med);
	}
</style>

<div class="txns no-margin">
	<slot name="first"></slot>

	{#each txns as k_txn}
		{@const k_token = H_TOKENS[k_txn.def.tokenRef]}
		{@const g_bankish = k_txn.bankish($yw_account.address($yw_chain))}
		{@const gd_txn = k_txn.def}
		{@const g_detail = detail_bankish(g_bankish)}

		{#if g_bankish}
			<Row
				icon={H_TXN_ICONS[g_bankish.type]}
				iconClass={H_TXN_CLASSES[g_bankish.type] || ''}
				name={H_SUMMARIZERS[g_bankish.type](k_txn)}
				address={g_bankish.address}
				detail={g_detail.name}
				prefix={g_detail.prefix}
				amount={format_amount(k_token.approx(g_bankish.amount))+(b_ctx_token? '': ` ${k_token.def.symbol}`)}
				fiat={amount_to_fiat(k_token.approx(g_bankish.amount), k_token)}
				on:click={() => {
					// push_screen(TxnView, {
					// 	txn: k_txn,
					// });
					push_screen(DeadEnd);
				}}
			>
				<svelte:fragment slot="detail">
					{#if !b_ctx_contact}
						{#if g_detail.icon}
							<span class="txn-type icon">
								{@html g_detail.icon}
							</span>
						{/if}
						{#if g_detail.name}
							{g_detail.name}
						{:else if g_bankish.address}
							<Address address={g_bankish.address} />
						{/if}
					{/if}
				</svelte:fragment>
			</Row>
		{:else}
			<Row
				icon={H_TXN_ICONS[gd_txn.type]}
				iconClass={H_TXN_CLASSES[gd_txn.type] || ''}
				name={H_SUMMARIZERS[gd_txn.type](k_txn)}
				address={gd_txn.address}
				amount={format_amount(k_token.approx(gd_txn.amount))}
				fiat={amount_to_fiat(k_token.approx(gd_txn.amount), k_token)}
			/>
		{/if}
	{/each}
</div>