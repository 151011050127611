<script lang="ts">
	import {
		Address,
		Screen,
		Field,
		Info,
		Tags,
	} from "#/ui";

	import {
		Account,
	} from '#/objects';

	import IconEditor from "#/ui/IconEditor.svelte";
	import { A_HOUSES, H_ACCOUNTS, H_TAGS } from "#/sim/data";
	import { Icon } from "#/objects/icon";
	import type { Tag } from "#/objects/tag";
	import { pop, push_screen, restart, yw_account, yw_chain, yw_task } from "#/app";
import AccountView from "./AccountView.svelte";
import { timeout } from "#/util/belt";
import { Tasks } from "#/component/Simulator.svelte";

	export let account: Account | null = null;

	$: accountName = account?.def.label || '';

	let p_icon: Icon.Ref = account?.def.iconRef!;
	
	let a_tags: Tag[] = (account?.def.tagRefs || []).map(p => H_TAGS[p]);
	let s_placeholder = 'Satoshi Nakamoto';

	if(!account) {
		const s_house = A_HOUSES[Object.values(H_ACCOUNTS).length - 1];
		s_placeholder = s_house[0].toUpperCase()+s_house.slice(1);
		p_icon = Icon.refFromFile(`house/${s_house}.png`);
	}

	$: b_form_valid = !!accountName;

	let sa_account = account?.address($yw_chain) || $yw_account.address($yw_chain).replace(/.{5}$/, Object.values(H_ACCOUNTS).length.toString(16).padStart(3, '0')+'t9');

	function save() {
		if(!b_form_valid) return;

		const g_save = {
			label: accountName,
			tagRefs: a_tags.map(k => k.def.iri),
		};

		if(account) {
			Object.assign(account.def, g_save);

			restart();

			if(Tasks.ADD_TAG === $yw_task) {
				setTimeout(() => {
					$yw_task = -$yw_task;
				}, 1200);
			}
		}
		else {
			const gd_account = Account.Def.fromConfig({
				...g_save,
				pubkey: sa_account.replace(/^\w+1/g, ''),
				iconRef: p_icon,
			});

			const k_account = H_ACCOUNTS[gd_account.iri] = new Account(gd_account);

			restart();

			push_screen(AccountView, {
				account: k_account,
			});
		}
	}

</script>

<Screen>
	<h3>
		{account? 'Edit': 'New'} account
	</h3>

	<Field key="profile-icon" name="Profile icon">
		<IconEditor intent='person' iconRef={p_icon} />
	</Field>

	<Field key="account-name" name="Name">
		<input id="account-name" type="text" bind:value={accountName} placeholder={s_placeholder}>
	</Field>

	<!-- <Field key="account-path" name="Derivation path">
		<Info key="account-path">
			m/44'/118'/0'/0/{Object.values(H_ACCOUNTS).length}
		</Info>
	</Field> -->

	<Field key="account-address" name="Public address">
		<Info key="account-address">
			<Address copyable address={sa_account} />
		</Info>
	</Field>

	<Field key="account-tags" name="Add tags">
		<Tags editable bind:tags={a_tags} />
	</Field>

	<div class="action-line clickable">
		<button on:click={() => pop()}>
			Cancel
		</button>

		<button class="primary" readonly={!b_form_valid} on:click={() => save()}>
			Finish
		</button>
	</div>
</Screen>