<script lang="ts">
	import {
		Address,
		Screen,
		Field,
		Info,
		Tags,
	} from "#/ui";

	import type {
		Account,
	} from '#/objects';

	import { H_ICONS, H_TAGS } from "#/sim/data";
	import { pop, push_screen, yw_account, yw_chain, yw_popup, yw_popup_account } from "#/app";
		
	import Header from "#/ui/Header.svelte";
	import Portrait from "#/ui/Portrait.svelte";
	import Send from "./Send.svelte";
	import Put from "#/ui/Put.svelte";
	import PopupReceive from "#/ui/PopupReceive.svelte";
	import AccountEdit from "./AccountEdit.svelte";

	export let account: Account;

	export const accountId = account.def.id;

	$: a_tags = account.def.tagRefs.map(p => H_TAGS[p]);

	const gc_actions = {
		send: {
			label: 'Send',
			trigger() {
				push_screen(Send, {
					from: account.address($yw_chain),
				});
			},
		},
		recv: {
			label: 'Receive',
			trigger() {
				$yw_popup_account = $yw_account;
				$yw_popup = PopupReceive;
			},
		},
		edit: {
			label: 'Edit',
			trigger() {
				push_screen(AccountEdit, {
					account,
				});
			},
		},
	};

</script>

<Screen nav>
	<Header pops search network
		name="Account"
	></Header>

	<Portrait
		title={account.def.label}
		actions={gc_actions}
		tags={a_tags}
	>
		<svelte:fragment slot="pfp">
			{#if H_ICONS[account.def.iconRef]}
				<Put element={H_ICONS[account.def.iconRef].render()} />
			{:else}
				<span class="pfp-gen">
					{account.def.label[0]}
				</span>
			{/if}
		</svelte:fragment>

		<svelte:fragment slot="subtitle">
			<Address copyable address={account.address($yw_chain)} />
		</svelte:fragment>
	</Portrait>

</Screen>