<script lang="ts">
	import type {
		Token,
	} from "#/objects";

	import {
		format_amount,
		amount_to_fiat,
		H_TOKENS,
	} from "#/sim/data";

	export let amount: bigint | string;
	export let token: Token;

	const xg_amount = BigInt(amount);

	const s_amount = xg_amount.toString();
	const s_major = s_amount.slice(0, -token.def.decimals);
	const s_decimals = s_amount.slice(-token.def.decimals);
	const s_hr_denom = `${s_major}.${s_decimals}`;

</script>

<style lang="less">
	@import '../style/util.less';

	.token-amount-fiat {
		.font(tiny);
		color:var(--theme-color-text-med);
	}
</style>

<div class="token-amount">
	<div class="token-amount-value">
		{s_hr_denom} {token.def.symbol}
	</div>

	<div class="token-amount-fiat">
		{amount_to_fiat(token.approx(xg_amount), token)}
	</div>
</div>
