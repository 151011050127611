<script context="module" lang="ts">
	export enum Tasks {
		ACCOUNT = 1,
		RECEIVE = 2,
		SEND = 3,
		NEW_CONTACT = 4,
		VERIFY = 5,
		ADD_TAG = 6,
		REWARD = 7,
	}
</script>

<script lang="ts">
import { ode, oderac, timeout } from '#/util/belt';
import { dd, qsa } from '#/util/dom';
import { faArrowsAltV, faMousePointer } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

	import { onMount } from 'svelte';
	import Fa from 'svelte-fa';

	import System from './System.svelte';
	import { identity, type Task } from 'svelte/internal';
	import { goto_screen, reset_all, ThreadId, yw_account, yw_asset_send, yw_chain, yw_help, yw_holding_send, yw_nav_collapsed, yw_nav_visible, yw_path, yw_pattern, yw_popup, yw_progress, yw_task, yw_thread_id, yw_vendor_menu } from '#/app';
	import type { Account } from '#/objects/account';
	import type { Chain } from '#/objects/chain';
	import type { Token } from '#/objects/token';
import Welcome from '#/screen/Welcome.svelte';
import Locked from '#/screen/Locked.svelte';
import Holdings from '#/screen/Holdings.svelte';
import { K_DEFAULT_ACCOUNT, K_DEFAULT_CHAIN } from '#/sim/data';

	let dm_window: HTMLElement;
	let dm_cursor: HTMLElement;
	let dm_playbacks: HTMLElement;

	enum EventType {
		INIT = 1,
		MOVE = 2,
		CLICK = 3,
		BUTTON = 4,
		WHEEL = 5,
		RESIZE = 6,
		KEYPRESS = 7,
		VALUE = 8,
	}

	let b_awaiting_upload = false;

	const b_admin = '#admin' === location.hash;

	const P_SERVER = 'https://mvp-data.starshell.net';
	const SQ_BUTTON = 'button,.button,.clickable,.listItem,input,textarea';
	const SQ_INPUT = 'input,textarea';


	function system_reset() {
		$yw_account = K_DEFAULT_ACCOUNT;
		$yw_chain = K_DEFAULT_CHAIN;
		$yw_popup = null;
		reset_all();
	}

	function Recorder$log(this: Recorder, ...a_data: number[]) {
		const av_buffer = this._av_write;
		let ib_write = this._ib_write;

		const x_write = (a_data[0] << 28) | (Date.now() - this._xt_start);
		av_buffer.setUint32(ib_write, x_write);
		av_buffer.setUint16(ib_write + 4, a_data[1]);
		av_buffer.setUint16(ib_write + 6, a_data[2]);

		ib_write = this._ib_write = ib_write + 8;

		if(0 === ib_write % 128) {
			console.info(`${ib_write / 1024} KiB`);
		}

		// if(ib_write >= this._xb_watermark) {
		// 	this.swap();
		// }
	}

	class Recorder {
		_x_offset_x = 0;
		_x_offset_y = 0;
		_x_dim_x = 360;
		_x_dim_y = 640;
		_xt_start = 0;
		_xb_watermark = 64 << 10;  // 64Kib
		_nl_watermark = 0;

		_ab_write!: ArrayBuffer;
		_av_write!: DataView;
		_ib_write = 0;

		constructor(x_offset_x: number, x_offset_y: number) {
			this._x_offset_x = x_offset_x;
			this._x_offset_y = x_offset_y;
			this._nl_watermark = Math.floor(this._xb_watermark / 8);

			this.reset();
		}

		load(ab_data: ArrayBuffer) {
			this._ab_write = ab_data;
			this._av_write = new DataView(this._ab_write);
		}

		reset() {
			const ab_write = this._ab_write = new ArrayBuffer(this._xb_watermark);
			this._av_write = new DataView(ab_write);
			this._ib_write = 0;
		}

		log(...a_data: number[]) {
			this._xt_start = Date.now();
			this.log = Recorder$log;
			this.log(...a_data);
		}

		string(xc_event: EventType, i_element: number, s_data: string) {
			const atu8_string = (new TextEncoder()).encode(s_data);
			const nb_string = atu8_string.byteLength;

			this.log(xc_event, i_element, nb_string);

			let ib_buffer = this._av_write.byteOffset + this._ib_write;
			const atu8_write = new Uint8Array(this._ab_write, ib_buffer, nb_string);
			atu8_write.set(atu8_string);
			this._ib_write += nb_string;
		}
		
		// log_str(xc_type: EventType, sx_selector: string) {
			
		// }

		mouse(xc_type: EventType, de_mouse: MouseEvent) {
			this.log(xc_type, de_mouse.pageX - this._x_offset_x, de_mouse.pageY - this._x_offset_y);
		}

		button(i_button: number) {
			this.log(EventType.BUTTON, i_button, 0);
		}

		// click(sx_selector: string) {
		// 	this.log_str(EventType.CLICK, sx_selector);
		// }

		wheel(i_elmt: number, x_scroll_top: number) {
			this.log(EventType.WHEEL, i_elmt, x_scroll_top);
		}

		keypress(i_elmt: number, x_key: number) {
			this.log(EventType.KEYPRESS, i_elmt, x_key);
		}

		async upload(i_task: number) {
			const atu8_dump = new Uint8Array(this._ab_write.slice(0, this._ib_write));
			try {
				await fetch(`${P_SERVER}/data?task=${i_task}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/octet-stream',
					},
					body: atu8_dump,
				});

				console.info(`uploaded ${atu8_dump.byteLength}`);
			}
			catch(e_upload) {
				console.error(e_upload);
			}
		}

		async replay() {
			const av_read = this._av_write;
			const nb_read = av_read.byteLength;

			system_reset();

			dm_cursor.style.display = 'initial';

			const nl_events = nb_read / 8;
			if(!nl_events) return;

			let ib_read = 0;

			let x_read_0 = av_read.getUint32(ib_read);
			let xt_sim = x_read_0 & 0xf_ff_ff_ff;

			let x_mouse_x = 0;
			let x_mouse_y = 0;

			for(; ib_read<nb_read; ib_read+=8) {
				const x_read = av_read.getUint32(ib_read);
				const xt_event = x_read & 0xf_ff_ff_ff;
				const xc_type = x_read >>> 28;
				const a_data = [av_read.getUint16(ib_read+4), av_read.getUint16(ib_read+6)];

				await timeout(Math.max(0, xt_event - xt_sim - 1));

				switch(xc_type) {
					case EventType.INIT: {
						switch(a_data[1]) {
							case Tasks.ACCOUNT: {
								$yw_thread_id = ThreadId.INIT;
								break;
							}

							default: {
								$yw_thread_id = ThreadId.TOKENS;
								break;
							}
						}
						break;
					}

					case EventType.MOVE: {
						x_mouse_x = a_data[0];
						x_mouse_y = a_data[1];

						dm_cursor.style.transform = `translate(${x_mouse_x}px, ${x_mouse_y}px)`;
						break;
					}

					case EventType.CLICK: {
						const dm_circle = dd('div', {
							class: 'click playback',
							style: `left:${a_data[0]}px; top:${a_data[1]}px;`,
						});

						const yc_circle = new Fa({
							target: dm_circle,
							props: {
								icon: faCircle,
							},
						});

						dm_playbacks.append(dm_circle);

						setTimeout(() => {
							dm_circle.style.transform = 'scale(1.5)';
						}, 100);

						setTimeout(() => {
							dm_circle.style.transform = 'scale(2.5)';
						}, 200);

						setTimeout(() => {
							dm_circle.style.transform = 'scale(3.75)';
						}, 300);

						setTimeout(() => {
							yc_circle.$destroy();
						}, 600);
						break;
					}

					case EventType.BUTTON: {
						const dm_button = qsa(dm_window, SQ_BUTTON)[a_data[0]];

						// (dm_button as HTMLElement).focus();
						// if('BUTTON' === dm_button.tagName || Array.from(dm_button.classList).includes('clickable')) {
							(dm_button as HTMLElement).focus();
							(dm_button as HTMLElement).click();
							(dm_button as HTMLElement).blur();
						// }
						// else {
						// 	dm_button.dispatchEvent(new MouseEvent('click'));
						// }
						break;
					}

					case EventType.KEYPRESS: {
						const dm_input = qsa(dm_window, SQ_INPUT)[a_data[0]];

						(dm_input as HTMLInputElement).value += String.fromCharCode(a_data[1]);

						dm_input.dispatchEvent(new KeyboardEvent('input', {
							key: String.fromCodePoint(a_data[1]),
						}));
						break;
					}

					case EventType.VALUE: {
						const dm_input = qsa(dm_window, SQ_INPUT)[a_data[0]];

						const nb_string = a_data[1];
						const utu8_read = new Uint8Array(this._ab_write, av_read.byteOffset+ib_read+8, nb_string);
						const s_string = (new TextDecoder()).decode(utu8_read);

						(dm_input as HTMLInputElement).value = s_string;

						dm_input.dispatchEvent(new KeyboardEvent('input', {}));

						ib_read += nb_string;
						break;
					}

					case EventType.WHEEL: {
						const i_element = a_data[0];

						const dm_container = qsa(dm_window, '*')[i_element];

						try {
							dm_container.scrollTop = a_data[1];
						} catch(e_set) {
							console.error(`Failed to set scrollTop to ${a_data[1]}`);
							break;
						}


						const dm_scroll = dd('div', {
							class: 'scroll playback',
							style: `left:${x_mouse_x - 16}px; top:${x_mouse_y}px;`,
						});

						const yc_scroll = new Fa({
							target: dm_scroll,
							props: {
								icon: faArrowsAltV,
							},
						});

						dm_playbacks.append(dm_scroll)

						setTimeout(() => {
							Object.assign(dm_scroll.style, {
								transform: 'scale(1.5)',
								opacity: 1,
							});
						}, 50);

						setTimeout(() => {
							Object.assign(dm_scroll.style, {
								transform: 'scale(2.5)',
								opacity: 0.5,
							});
						}, 100);

						setTimeout(() => {
							Object.assign(dm_scroll.style, {
								transform: 'scale(3.75)',
								opacity: 0.25,
							});
						}, 150);

						setTimeout(() => {
							yc_scroll.$destroy();
						}, 200);
						break;
					}

					case EventType.RESIZE: {
						if(a_data[0] <= 500) {
							dm_window.style.setProperty('--app-window-width', a_data[0]+'px');
							dm_window.style.setProperty('--app-window-height', a_data[1]+'px');
						}
						else {
							dm_window.style.setProperty('--app-window-width', '360px');
							dm_window.style.setProperty('--app-window-height', '640px');
						}
						break;
					}
				}

				xt_sim = xt_event;
			}
		}
	}

	let f_stop_recording = (i_task: number) => {};

	function record(i_task: number) {
		const g_rect = dm_window.getBoundingClientRect();
		const k_recorder = new Recorder(g_rect.left, g_rect.top);

		k_recorder.log(EventType.INIT, Date.now(), i_task);

		let dt_last_move = 0;

		function resize() {
			let g_rect_new = dm_window.getBoundingClientRect();
			Object.assign(k_recorder, {
				_x_offset_x: g_rect_new.left,
				_x_offset_y: g_rect_new.top,
			});

			k_recorder.log(EventType.RESIZE, window.innerWidth, window.innerHeight);
		}

		window.addEventListener('resize', resize);

		resize();

		function mousemove(de_move: MouseEvent) {
			const dt_now = Date.now();

			if(dt_now - dt_last_move > 30) {
				k_recorder.mouse(EventType.MOVE, de_move);
				dt_last_move = dt_now;
			}
		}

		dm_window.addEventListener('mousemove', mousemove);

		function click(de_click: MouseEvent) {
			k_recorder.mouse(EventType.CLICK, de_click);

			const dm_target = de_click.target as HTMLElement | null;
			if(dm_target) {
				const dm_button = dm_target.closest(SQ_BUTTON);

				if(dm_button) {
					const i_target = qsa(dm_window, SQ_BUTTON).indexOf(dm_button);
					if(i_target >= 0) {
						k_recorder.button(i_target)
					}
				}
			}
		}

		dm_window.addEventListener('click', click, {
			capture: true,
		});

		function keypress(de_press: KeyboardEvent) {
			const dm_target = de_press.target as HTMLElement | null;
			if(dm_target) {
				const dm_input = dm_target.closest(SQ_INPUT);

				if(dm_input) {
					const i_target = qsa(dm_window, SQ_INPUT).indexOf(dm_input);
					if(i_target >= 0) {
						const s_key = de_press.key;
						if(1 === s_key.length) {
							k_recorder.keypress(i_target, s_key.codePointAt(0)!);
						}
					}
				}
			}
		}

		function input(de_input: Event) {
			const dm_target = de_input.target as HTMLElement | null;
			if(dm_target) {
				const dm_input = dm_target.closest(SQ_INPUT);

				if(dm_input && 'string' === typeof (dm_input as HTMLInputElement).value) {
					const i_target = qsa(dm_window, SQ_INPUT).indexOf(dm_input);
					if(i_target >= 0) {
						k_recorder.string(EventType.VALUE, i_target, (dm_input as HTMLInputElement).value);
					}
				}
			}
		}

		dm_window.addEventListener('input', input);

		// dm_window.addEventListener('keypress', keypress, {
		// 	capture: true,
		// });

		function stop() {
			window.removeEventListener('resize', resize);
			dm_window.removeEventListener('mousemove', mousemove);
			dm_window.removeEventListener('click', click);
			// document.removeEventListener('keydown', play);
			window.removeEventListener('scroll', scroll);
			window.removeEventListener('wheel', wheel);
			// window.removeEventListener('keypress', keypress);
			window.removeEventListener('input', input);
		}

		f_stop_recording = async(i_task: number) => {
			stop();
			b_awaiting_upload = true;
			await k_recorder.upload(i_task);
			b_awaiting_upload = false;
		};

		// async function play(de_key: KeyboardEvent) {
		// 	if('y' === de_key.key.toLowerCase()) {
		// 		stop();

		// 		await k_recorder.upload();


		// 		// k_recorder.replay();
		// 		// document.removeEventListener('keydown', play);

		// 		// document.addEventListener('keydown', function pause() {
		// 		// 	k_recorder.pause()
		// 		// });
		// 	}
		// }
	
		// document.addEventListener('keydown', play);

		function scroll(de_scroll: Event) {
			const dm_target = de_scroll.target as HTMLElement;

			if(dm_target && 'scrollTop' in dm_target) {
				console.log('scroll top: '+dm_target.scrollTop);
			}
		}

		window.addEventListener('scroll', scroll);

		function wheel(de_wheel: WheelEvent) {
			const dm_target = de_wheel.target as HTMLElement | null;
			if(dm_target?.tagName) {
				let dm_select: HTMLElement | null = null;
				let dm_node = dm_target as HTMLElement | null;
				for(; dm_node;) {
					if(dm_node.scrollTop) {
						dm_select = dm_node;
						break;
					}
					dm_node = dm_node.parentElement;
				}

				if(!dm_select) {
					for(; dm_node;) {
						if(dm_node.scrollHeight > dm_node.getBoundingClientRect().height + 5) {
							dm_select = dm_node;
							break;
						}
						dm_node = dm_node.parentElement;
					}
				}

				if(dm_select) {
					const a_targets = qsa(dm_window, '*');
					const i_target = a_targets.indexOf(dm_select);

					k_recorder.wheel(i_target, dm_select.scrollTop);
				}
				else {
					k_recorder.wheel(0, 0);
				}
			}
		}

		window.addEventListener('wheel', wheel);
	}

	let a_files = [] as {name:string; mtime:number}[];

	const si_key = Object.fromEntries(new URLSearchParams(location.search)).key;

	async function load_files() {
		if(!si_key) return;
		a_files = await (await fetch(`${P_SERVER}/stream?key=${si_key}`)).json();
	}

	async function download_and_play(sr_file: string) {
		dm_cursor.style.display = 'initial';

		const g_rect = dm_window.getBoundingClientRect();
		const k_recorder = new Recorder(g_rect.left, g_rect.top);

		const atu8_data = await (await fetch(`${P_SERVER}/stream?key=${si_key}&file=${sr_file}`)).arrayBuffer();
		k_recorder.load(atu8_data);
		
		k_recorder.replay();
	}

	load_files();

	let a_inspects = [
		{
			key: 'account',
			store: yw_account,
			render: (k: Account) => k.def.id,
		},
		{
			key: 'thread',
			store: yw_thread_id,
		},
		{
			key: 'path',
			store: yw_path,
		},
		{
			key: 'pattern',
			store: yw_pattern,
		},
		{
			key: 'nav_visible',
			store: yw_nav_visible,
		},
		{
			key: 'nav_collapsed',
			store: yw_nav_collapsed,
		},
		{
			key: 'progress',
			store: yw_progress,
		},
		{
			key: 'chain_id',
			store: yw_chain,
			render: (k: Chain) => k? k.def.id: '(none)',
		},
		{
			key: 'asset_send',
			store: yw_asset_send,
			render: (k: Token) => k? k.def.symbol: '(none)',
		},
		{
			key: 'holding_send',
			store: yw_holding_send,
			render: (k: Holding) => k? k.def.balance: '?',
		},
	].map((gc_inspect) => ({
		value: '',
		render: function render(z: unknown): string {
			if('string' === typeof z || 'number' === typeof z || 'boolean' === typeof z) return z+'';
			if(null === z) return 'null';
			if(Array.isArray(z)) return `[${z.map(zz => render(zz)).join(', ')}]`;
			return z+'';
		},
		...gc_inspect,
	}));

	for(const g_inspect of a_inspects) {
		g_inspect.store.subscribe((w_value: any) => {
			g_inspect.value = g_inspect.render(w_value);

			// update inpsector
			a_inspects = a_inspects;
		});
	}


	let b_show_help = false;

	let dm_help: HTMLElement
	$: {
		if(dm_help) {
			dm_help.replaceChildren(...$yw_help);
			if($yw_help.length) {
				b_show_help = true;
				if(window.innerWidth <= 500) {
					setTimeout(() => {
						try {
							dm_help.scrollIntoView({behavior:'smooth'});
						} catch(e_scroll) {}
					}, 100);
				}
			}
		}
	}


	let b_recording = false;
	async function begin_task() {
		b_show_help = false;

		system_reset();
		b_recording = true;

		switch($yw_task) {
			case Tasks.ACCOUNT: {
				$yw_thread_id = ThreadId.INIT;
				goto_screen(Welcome);
				break;
			}

			default: {
				$yw_thread_id = ThreadId.TOKENS;
				goto_screen(Holdings);
				break;
			}
		}

		await timeout(0);
		record($yw_task);
	}


	yw_task.subscribe((xc_task: number) => {
		if(xc_task < 0) {
			b_recording = false;
			f_stop_recording(Math.abs(xc_task));
			$yw_thread_id = ThreadId.DEFAULT;
			goto_screen(Locked);
			localStorage.task = `${Math.abs(xc_task)+1}`;
		}

		if(xc_task !== 0) {
			setTimeout(() => {
				b_show_help = true;
			}, 100);
		}
	});


	function resize() {
		if(window.innerWidth <= 500) {
			document.body.style.setProperty('--app-window-width', window.innerWidth+'px');
			document.body.style.setProperty('--app-window-height', window.innerHeight+'px');
		}
	}

	window.addEventListener('resize', resize);
	resize();

</script>

<style lang="less">
	@import '../style/util.less';

	@app-mobile-max-width: 500px;

	html {
		@media screen and (max-width: @app-mobile-max-width) {
			:global(&) {
				overflow: hidden;
			}
		}
	}

	body {
		@media screen and (max-width: @app-mobile-max-width) {
			:global(&) {
				height: 100%;
				overflow-x: hidden;
				overflow-y: scroll;
				.hide-scrollbar();
				scroll-snap-type: y mandatory;
			}
		}
	}


	// .container {
	// 	@media screen and (max-width: @app-mobile-max-width) {
	// 		height: 200vh;
	// 		display: block;
	// 	}
	// }

	.window {
		@media screen and (max-width: @app-mobile-max-width) {
			--app-window-width: 100vw;
			--app-window-height: 100%;
			margin: 0;
			border: none;

			scroll-snap-align: start;

			.screen {
				:global(&) {
					overscroll-behavior-y: auto !important;
				}
			}

			input,textarea {
				font-size: 16px !important;
			}

			&.guided {
				margin: 0;
			}
		}

		width: var(--app-window-width);
		height: var(--app-window-height);

		margin-top: calc(45vh - var(--app-window-height) / 2);
		// margin-left: auto;

		margin-left: calc(50% - var(--app-window-width) / 2);
		transition: margin-left 1s var(--ease-out-quad);

		margin-right: auto;
		border: 1px solid rgba(255, 255, 255, 0.2);

		@media screen and (min-width: @app-mobile-max-width) {
			&.guided {
				margin-left: calc(50% - (var(--app-window-width) / 2) - 40px);
			}
		}
	}

	.playback {
		&.click {
			:global(&) {
				color: red;
				margin-left: -8px;
				margin-top: -8px;
			}
		}
		&.scroll {
			:global(&) {
				color: blue;
				margin-left: -8px;
				margin-top: -8px;
			}
		}
		&.cursor {
			:global(&) {
				display: none;
				top: 0;
				left: 0;
				color: white;
				margin-top: -2px;
			}

			path {
				:global(&) {
					stroke: black;
					stroke-width: 2em;
				}
			}
		}

		:global(&) {
			position: absolute;
			z-index: 100000;
		}
	}

	.inspector {
		@media screen and (max-width: @app-mobile-max-width) {
			display: none;
		}

		position: absolute;
		top: 0;
		left: 0;
		padding-left: 2em;
		padding-top: 1em;
		box-sizing: border-box;
		display: grid;
		grid-template-columns: auto auto;
		font-size: 13px;
		font-family: 'PT Mono';
		color: rgba(240,240,240,0.8);
		
		max-width: calc(50% - 182px);
		overflow: scroll;
		.hide-scrollbar();

		>* {
			margin-top: 0.25em;
			margin-bottom: 0.25em;
			padding-top: 0.35em;
			padding-bottom: 0.35em;
		}

		.key {
			padding-right: 1em;
		}

		.value {
			padding-left: 1em;
			border-left: 1px solid rgba(250, 250, 250, 0.2);
		}
	}

	.guide {
		display: none;

		&.displayed {
			display: initial;
		}

		@media screen and (max-width: @app-mobile-max-width) {
			z-index: 100000;
			top: calc(50% - 120px);
			left: 0;
			width: 80vw;
			height: 240px;
			background-color: var(--theme-color-text-light);
			color: black;
			// transform: translatex(var(--app-window-width));
			border-radius: 6px 0 0 6px;
			max-width: 80vw;
			padding: 4px 16px;
			overflow-y: scroll;

			transition: transform 350ms var(--ease-out-quint);
			box-sizing: border-box;;

			transform: translateX(calc(var(--app-window-width) - 20px));

			&.showing {
				transform: translateX(20vw);
			}
		}

		position: absolute;
		top: 0;
		left: calc(50% + 175px);
		max-width: 300px;

		margin-top: 1em;
		font-family: 'Poppins';

		font-size: 16px;
		font-weight: 300;
		color: var(--theme-color-text-light);

		>h3 {
			color: var(--theme-color-text-light);
			text-decoration: underline;
			font-weight: 600;
		}

		>.info {
			font-size: 14px;
			font-weight: 200;
		}

		>hr {
			opacity: 0.2;
		}

		.task-index {
			.font(tiny);
			color: var(--theme-color-text-med);
		}

		.instructions {
			.font(regular);
		}
	}

	.help {
		.font(regular);
	}

	.scroll-hint {
		display: none;

		@media screen and (max-width: @app-mobile-max-width) {
			display: block;
			position: sticky;
			bottom: -3px;
			height: 40px;
			background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
			width: 100%;
			margin: 0;
			left: 0;
		}
	}

	button {
		margin-top: 14px;
		.font(regular);
		width: 180px;
		height: 42px;
		background-color: var(--theme-color-primary);
		border: none;
		border-radius: 6px;
	}
</style>

<div class="window" bind:this={dm_window} class:guided={$yw_task !== 0 && $yw_task < 9}>
	<System>
		<div bind:this={dm_playbacks}>
			<div class="cursor playback" bind:this={dm_cursor}>
				<Fa icon={faMousePointer} />
			</div>
		</div>
	</System>
</div>


<div class="inspector" style={b_admin? '': 'display: none;'}>
	{#each a_inspects as g_inspect}
		<span class="key">
			{g_inspect.key}
		</span>
		<span class="value">
			{g_inspect.value}
		</span>
	{/each}
</div>

<div class="guide" class:displayed={b_admin || ($yw_task !== 0 && $yw_task < 9)} class:showing={b_show_help} on:click={() => {
	b_show_help = !b_show_help;
}}>
	<p class="info">
		{#if $yw_task < 9}
			<h3>Beta testing closed</h3>

			<p>
				The period for beta testing has ended. However, we will keep the demo available for a bit longer -- so feel free to play around with the UI!
			</p>

			<button class="start" on:click={(d_event) => {
				d_event.stopPropagation();
				localStorage.task = '9';
				$yw_task = 9;
				system_reset();
				goto_screen(Holdings);
			}}>
				Explore the UI
			</button>
		{:else if $yw_task > 0 && $yw_task < 8}
			<div class="task-index">
				Task {$yw_task} of 7
			</div>

			<div class="instructions">
				{#if Tasks.ACCOUNT === $yw_task}
					<p>
						Create a new wallet account.
					</p>
				{:else if Tasks.RECEIVE === $yw_task}
					<p>
						A friend wants to send you some tokens. To be able to receive them, find your public address and copy it to your clipboard.
					</p>
				{:else if Tasks.SEND === $yw_task}
					<p>
						Send 100 sUSDC to your friend, Satoshi Nakamoto, who is already in your contacts.
					</p>
				{:else if Tasks.NEW_CONTACT === $yw_task}
					<p>
						Add a new contact.
					</p>
				{:else if Tasks.VERIFY === $yw_task}
					<p>
						Check your ATOM balance on the Cosmos chain in your account named "Minerva".
					</p>
				{:else if Tasks.ADD_TAG === $yw_task}
					<p>
						Add a tag to one of your accounts.
					</p>
				{:else if Tasks.REWARD === $yw_task}
					<p>
						Send 1 StarShell MVP Reward token (S2MVPR) to your REAL Secret Network address!
					</p>

					<p>
						We want to show our appreciation to you for taking some time to participate in the beta program.
						Complete this task if you want to be eligible for some future airdrop or whitelisting.
						Please note, there is no guarantee you will receive anything.
					</p>

					<p>
						If you'd rather not provide your address, you can <a href="javascript:void(0)" on:click={() => $yw_task += 1}>skip this step</a>.
					</p>
				{:else}
					...
				{/if}
			</div>

			{#if !b_recording}
				<button class="start" on:click={(d_event) => {
					d_event.stopPropagation();
					begin_task();
				}}>
					Begin
				</button>
			{/if}
		{:else if $yw_task === 8}
			<h3>All done!</h3>

			<p>
				You've reached the end of user testing. Feel free to play around with the UI.
			</p>

			<button class="start" on:click={(d_event) => {
				d_event.stopPropagation();
				localStorage.task = '9';
				$yw_task = 9;
				system_reset();
				goto_screen(Holdings);
			}}>
				Explore the UI
			</button>
		{:else if $yw_task < 0}
			<div class="task-index">
				Task {-$yw_task} of 7
			</div>

			<h3>Well done!</h3>

			<p>
				You successfully completed the task.
			</p>

			<button class="start" readonly={b_awaiting_upload} on:click={(d_event) => {
				d_event.stopPropagation();
				// $yw_task = Math.abs($yw_task) + 1;
				location.reload();
			}}>
				Next task
			</button>
		{/if}


		{#if b_admin}
			{#each a_files as g_file}
				<button class="file" on:click={() => download_and_play(g_file.name)}>
					{g_file.name.slice(0, 5)}: {new Date(g_file.mtime).toUTCString()}
				</button>
			{/each}
		{/if}
	</p>
	
	<hr>

	<div class="help" bind:this={dm_help} />

	<div class="scroll-hint">&nbsp;</div>
</div>