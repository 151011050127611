<script lang="ts">
	import { slide } from 'svelte/transition';

	import { pop, push_screen, yw_account, yw_asset_send, yw_chain, yw_holding_send } from '#/app';

	import type {
		Token,
		Account,
	} from '#/objects';

	import AssetSelect from '#/ui/AssetSelect.svelte';

	import type { Chain } from '#/objects/chain';

	import { amount_to_fiat, format_amount, H_ADDR_TO_CONTACT, H_CHAINS, H_HOLDINGS, H_TOKENS } from '#/sim/data';

	import SX_PERSONAL from '@material-design-icons/svg/outlined/account_box.svg?raw';
	import SX_CONTRACT from '@material-design-icons/svg/outlined/analytics.svg?raw';
	import SX_LOADING from '@material-design-icons/svg/outlined/donut_large.svg?raw';
	import SX_INFO from '@material-design-icons/svg/outlined/info.svg?raw';
	import SX_DROPDOWN from '#/asset/nav/drop-down.svg?raw';

	import {
		Screen,
		Header,
		Field,
		SenderSelect,
		RecipientSelect,
	} from '#/ui';

	import AmountInput from '#/ui/AmountInput.svelte';
	import CheckboxField from '#/ui/CheckboxField.svelte';

	import Execute from './Execute.svelte';


	export let from: Account | null = null;
	export let token: Token | null = null;
	export let to: string = '';

	$: $yw_asset_send = token;

	if($yw_asset_send) {
		$yw_chain = H_CHAINS[$yw_asset_send.def.chainRef];
	}

	const H_ADDRESS_TYPES = {
		none: {
			icon: '<svg></svg>',
			text: '',
		},

		unknown: {
			icon: SX_LOADING,
			text: 'Determining address type...'
		},

		personal: {
			icon: SX_PERSONAL,
			text: 'Personal address',
		},

		contract: {
			icon: SX_CONTRACT,
			text: 'Contract address',
		},
	} as const;

	let si_address_type: keyof typeof H_ADDRESS_TYPES = 'none';
	$: g_address_type = H_ADDRESS_TYPES[si_address_type];

	let s_amount = '';

	$: {
		if(!to) {
			si_address_type = 'none';
		}
		else {
			si_address_type = 'unknown';

			console.info(to);
			setTimeout(() => {
				si_address_type = 'personal';
			}, 1250);
		}
	}

	$: s_balance = $yw_holding_send? format_amount($yw_holding_send.approx(H_TOKENS)): '';

	let p_token = token?.def.iri || '';
	$: {
		if(token && token.def.iri !== p_token) {
			s_amount = '';
			c_show_validations = 0;
			p_token = token.def.iri;
		}
	}

	function use_max() {
		s_amount = $yw_holding_send!.amount(H_TOKENS)+'';
		c_show_validations++;
	}

	$: b_use_max = s_amount === $yw_holding_send?.def.balance+'';

	let x_fee = 0.01;
	

	let b_memo_expanded = false;
	let s_memo = '';

	let b_submitted = false;

	function submit() {
		if(!b_form_valid) {
			c_show_validations++;
			return;
		}
		else {
			if(b_submitted) return false;

			b_submitted = true;

			if($yw_asset_send!.def.native) {
				// push_screen(SendNative, {});
			}
			else {
				push_screen(Execute, {
					contract: $yw_asset_send?.def.address,
					snip20: {
						transfer: {
							recipient: to,
							amount: $yw_asset_send?.denomFromString(s_amount) || '0',
						},
					},
				});
			}
		}
	}

	let s_err_recipient = '';
	let s_err_amount = '';

	$: b_new_address = to && !(to in H_ADDR_TO_CONTACT);
	
	let s_new_contact = '';
	$: s_err_new_contact = b_checked_save_contact && (c_show_validations || true)
		? s_new_contact
			? R_CONTACT_NAME.test(s_new_contact)
				? ''
				: s_new_contact.length > 1024
					? 'That name is way too long'
					: 'Cannot begin with space'
			: 'Enter a contact name to save new address'
		: '';

	$: {
		if(b_checked_save_contact && !c_show_validations) {
			s_err_new_contact = '';
		}
	}

	const R_CONTACT_NAME = /^\S.{0,1023}$/;

	$: b_form_valid = (to
		&& s_amount
		&& !s_err_recipient
		&& !s_err_amount
		&& (!b_new_address || !b_checked_save_contact || (s_new_contact && !s_err_new_contact))
	) || false;

	$: {
		console.log({
			to,
			s_amount,
			s_err_recipient,
			s_err_amount,
		});
	}

	let c_show_validations = 0;

	let b_checked_save_contact = false;
	let b_dead = false;

	function input_new_contact(d_event: Event) {
		s_new_contact = (d_event.target as HTMLInputElement).value;
	}
</script>


<style lang="less">
	@import '../style/util.less';

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	#field-recipient-status {
		:global(&) {
			margin-top: -12px;
			padding-left: 6px;
		}

		.status {
			:global(&.unknown>.icon) {
				animation: spin 1s linear infinite;
			}
			:global(&.contract>.icon) {
				transform: rotate(90deg);
			}
			:global(&.contract>.icon) {
				transform: rotate(90deg);
			}
		}
	}

	.status {
		color: var(--theme-color-graymed);

		>* {
			vertical-align: middle;
		}

		>.icon {
			--proxy-icon-diameter: 20px;
			--icon-color: var(--theme-color-graymed);
		}

		>.text {
			.font(tiny);
		}
	}


	#field-balance {
		:global(&) {
			margin-top: -12px;
		}
	}

	.balance-line {
		.font(tiny, @size: 12px, @weight: 300);
		display: flex;
		justify-content: space-between;

		>.balance {
			>.label {
				color: var(--theme-color-text-med);
			}

			>.amount {
				color: var(--theme-color-text-light);
			}
		}
	}

	.fee-fiat {
		.font(tiny);
		color: var(--theme-color-text-med);
	}

	#field-fee {
		.field-value {
			:global(&) {
				flex: 1;
			}
		}

		.manual-fee {
			:global(&) {
				flex: 2;
			}
		}
	}

	#field-manual-fee {
		:global(&) {
			margin-top: -12px;
		}
	}

	.manual-fee {
		display: flex;
		align-items: center;
		gap: 0.5em;

		>.icon.info {
			--icon-diameter: 18px;
			padding: 2px;
		}
	}

	.memo {
		display: flex;
		flex-direction: column;
		gap: 0.75em;

		.title {
			display: flex;
			gap: 4px;
			position: relative;

			.icon {
				--icon-diameter: 22px;
				--icon-color: var(--theme-color-primary);
			}
	
			>.disclaimer {
				.font(tiny);
				color: var(--theme-color-caution);
				right: 0;
				position: absolute;
				bottom: 0.5ex;
			}
		}

		.dropdown.icon {
			transform: rotate(0deg);
			transition: transform 300ms var(--ease-out-quad);
		}

		&.expanded {
			.dropdown.icon {
				transform: rotate(-180deg);
			}
		}
	}

	.new-address {
		margin-top: 12px;
	}
</style>


<Screen form slides on:submit={(d_submit) => {
	d_submit.preventDefault();
	submit();
}}>
	<Header pops
		name={$yw_asset_send && !$yw_asset_send.def.native? 'Transferring': 'Sending'}
		symbol={$yw_asset_send? $yw_asset_send.def.symbol: ''}
		subname={$yw_chain?.def.label || '?'}
	/>

	<Field short
		key='sender-select'
		name='From'
	>
		<SenderSelect />
	</Field>

	<Field short
		key='recipient-select'
		name='To'
	>
		<RecipientSelect
			bind:error={s_err_recipient}
			bind:address={to}
			showValidation={c_show_validations}
		/>
	</Field>

	<Field short
		key='recipient-status'
		name=''
	>
		<span class="status {si_address_type}">
			<span class="icon">
				{@html g_address_type.icon}
			</span>
			<span class="text">
				{g_address_type.text}
			</span>
		</span>

		{#if b_new_address}
			<div class="new-address">
				<CheckboxField id="save-contact" bind:checked={b_checked_save_contact} >
					Save to contacts
				</CheckboxField>
			</div>
		{/if}
	</Field>

	{#if b_new_address && b_checked_save_contact}
		<Field short slides
			key='new-contact-name'
			name='Contact Name'
		>
			<input id="new-contact-name-value" type="text" on:input={input_new_contact} class:invalid={s_err_new_contact}>

			{#if s_err_new_contact}
				<span class="validation-message">
					{s_err_new_contact}
				</span>
			{/if}
		</Field>
	{/if}

	<hr>

	<Field short
		key='asset-select'
		name='Asset'
	>
		<AssetSelect bind:token={token} />
	</Field>

	<Field short
		key='amount'
		name='Amount'
	>
		<AmountInput
			bind:error={s_err_amount}
			bind:value={s_amount}
			showValidation={c_show_validations}
		/>
	</Field>

	<Field short
		key='balance'
		name=''
	>
		<span class="balance-line">
			{#if $yw_asset_send}
				<span class="balance">
					<span class="label">
						Balance
					</span>
					<span class="amount">
						{s_balance} {$yw_asset_send.def.symbol}
					</span>
				</span>

				<span class="use-max">
					<span class="link clickable" on:click={() => use_max()}>USE MAX</span>
				</span>
			{/if}
		</span>
	</Field>

	<hr>

	<Field short
		key='fee'
		name='Fee'
	>
		<div class="fee-amount">
			{x_fee} SCRT
		</div>

		<div class="fee-fiat">
			{amount_to_fiat(x_fee, $yw_chain.native(H_TOKENS))}
		</div>

		<svelte:fragment slot="post">
			<div class="manual-fee">
				<span class="link">Set fee manually</span>
				<span class="icon info">
					{@html SX_INFO}
				</span>
			</div>
		</svelte:fragment>
	</Field>
<!-- 
	<Field short
		key='manual-fee'
		name=''
	>
		<div class="manual-fee">
			<span class="link">Set fee manually</span>
			<span class="icon info">
				{@html SX_INFO}
			</span>
		</div>
	</Field> -->

	<hr>

	<div class="memo" class:expanded={b_memo_expanded}>
		<div class="title clickable" on:click={() => b_memo_expanded = !b_memo_expanded}>
			<span class="icon dropdown">
				{@html SX_DROPDOWN}
			</span>
			<span class="text">
				Add memo
			</span>

			{#if b_memo_expanded}
				<span class="disclaimer" transition:slide={{duration:350, delay:400}}>
					Caution: Memos are NOT private
				</span>
			{/if}
		</div>

		{#if b_memo_expanded}
			<div class="input" transition:slide={{duration:350}}>
				<textarea bind:value={s_memo}></textarea>
			</div>
		{/if}
	</div>

	<div class="action-line" class:pointer-events_none={b_dead}>
		<button type="button" on:click={() => ((b_dead = true) && pop())}>
			Cancel
		</button>

		<button class="primary" on:click={() => submit()} readonly={!b_form_valid}>
			Next
		</button>
	</div>
</Screen>