<script lang="ts">
	import BigNumber from 'bignumber.js';

	import {
		push_screen,
	} from "#/app";

	import {
		Screen,
		Row,
	} from "#/ui";

	import TokenHoldingView from '#/screen/TokenHoldingView.svelte';

	import type {
		Holding,
		Token,
	} from '#/objects';

	import {
		H_TOKENS, H_VERSUS_USD,
	} from '#/sim/data';


	export let holdings: Holding[];

	const a_holdings = holdings;

	console.log({a_holdings});

	for(const k_holding of a_holdings) {
		if(H_VERSUS_USD[k_holding?.def?.iri]?.value) {
			console.log(k_holding);
		}
	}

	const dintl_usd = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency:'USD',
		currencyDisplay: 'symbol',
	});

	function balance_to_usd(xg_balance: bigint, gd_token: Token.Def): string {
		if(!H_VERSUS_USD[gd_token.iri]) return '...';
		const x_usd = H_VERSUS_USD[gd_token.iri].value * new BigNumber(xg_balance+'').shiftedBy(-gd_token.decimals).toNumber();
		return dintl_usd.format(x_usd);
	}
</script>

<style lang="less">
	.rows {
		margin: 0 !important;
	}
</style>

<div class="rows">
	{#each a_holdings as k_holding}
		{@const {def:gd_token} = H_TOKENS[k_holding.def.tokenRef]}

		<Row
			iconRef={gd_token.iconRef}
			tagRefs={gd_token.tagRefs}
			name={gd_token.symbol}
			detail={gd_token.label}
			lockIcon={gd_token.native}
			amount={k_holding.approx(H_TOKENS).toLocaleString()}
			fiat={balance_to_usd(k_holding.def.balance, gd_token)}
			on:click={() => push_screen(TokenHoldingView, {
				holding: k_holding,
			})}
		>
		</Row>
	{/each}
</div>
