<script lang="ts">
	import { push_screen, yw_account, yw_chain } from "#/app";

	import { A_TXNS, format_amount, H_ADDRESS_TO_TOKEN, H_CONTACTS, H_ICONS, H_TAGS } from "#/sim/data";

	import type {
		Contact,
	} from "#/objects";
	
	import {
		Screen,
		Address,
		Header,
		Portrait,
		Put,
		Row,
	} from '#/ui';

	import TxnList, { TxnContext } from "#/ui/TxnList.svelte";
	import ContactEdit from "./ContactEdit.svelte";
	import Send from "./Send.svelte";
import DeadEnd from "./DeadEnd.svelte";


	export let contact: Contact;
	export const contactId = contact.address($yw_chain);

	const gc_actions = {
		send: {
			label: 'Send',
			trigger() {
				push_screen(Send, {
					to: contact.address($yw_chain),
				});
			},
		},
		edit: {
			label: 'Edit',
			trigger() {
				push_screen(ContactEdit, {
					contact,
				});
			},
		},
		delete: {
			label: 'Delete',
			trigger() {
				push_screen(DeadEnd);
			},
		},
	};

	const sa_contact = contact.address($yw_chain);

	$: a_txns = A_TXNS.filter(k_txn => {
		const gd_txn = k_txn.def;

		if(sa_contact === gd_txn.address) return true;

		const g_bankish = k_txn.bankish($yw_account.address($yw_chain));
		if(g_bankish) {
			return sa_contact === g_bankish.address;
		}

		return false;
	});

</script>

<style lang="less">
	@import '../style/util.less';


	.pfp-gen {
		.font(huge, @size: 30px);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		border-radius: 20%;
		outline: 1px solid var(--theme-color-primary);
		background: radial-gradient(ellipse farthest-side at bottom right, #07080a, #0f1317);
	}
</style>


<Screen nav slides>
	<Header pops search network account
	>
	</Header>

	<Portrait
		title={contact.def.label}
		actions={gc_actions}
		tags={contact.def.tagRefs.map(p_tag => H_TAGS[p_tag])}
	>
		<svelte:fragment slot="pfp">
			{#if H_ICONS[contact.def.iconRef]}
				<Put element={H_ICONS[contact.def.iconRef].render()} />
			{:else}
				<span class="pfp-gen">
					{contact.def.label[0]}
				</span>
			{/if}
		</svelte:fragment>

		<svelte:fragment slot="subtitle">
			<Address copyable address={contact.address($yw_chain)} />
		</svelte:fragment>
	</Portrait>


	<TxnList
		context={TxnContext.CONTACT}
		txns={a_txns}
	>

	</TxnList>
<!-- 
	<div class="txns no-margin">
		{#if !a_txns.length}
			<div>
				No transactions yet
			</div>
		{/if}

		{#each a_txns as k_txn}
			{@const gd_txn = k_txn.def}
			{@const k_token = H_ADDRESS_TO_TOKEN[k_txn.def.address]}
			{@const g_bankish = k_txn.bankish($yw_account.address($yw_chain))}
			{@const a_debug = [g_bankish, k_txn]}
			</!-- {@debug a_debug} --/>

			{#if Txn.Type.RECV === gd_txn.type}
				<Row
					name="Received SCRT"
				>
				</Row>
				</!-- approx(gd_txn.amount) --/>
			{:else if Txn.Type.SEND === gd_txn.type}
				<Row
					name="Sent SCRT"
				>
				</Row>
			{/if}
		{/each}
	</div> -->

</Screen>