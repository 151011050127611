<script lang="ts">
import { goto_screen, push_screen, yw_task } from "#/app";

	import Screen from "#/ui/Screen.svelte";
import Locked from "./Locked.svelte";
	import Mvp_No from "./Mvp_No.svelte";
import Welcome from "./Welcome.svelte";


	const p_high = '/asset/vendor/orb-3-high.png';
	let dm_logo: HTMLElement;
	const dm_img = new Image();
	dm_img.onload = () => {
		if(dm_logo) {
			(dm_logo.closest('.mvp_3') as HTMLElement).style.backgroundImage = `url('${p_high}')`;
		}
	};
	dm_img.src = p_high;

	let n_seconds = 5;
	let s_countdown = '(5s)';

	setInterval(() => {
		n_seconds -= 1;
		s_countdown = `(${n_seconds}s)`;
	}, 1000);
</script>

<style lang="less">
	@import '../style/util.less';

	.thread>section.screen.mvp_3{
		:global(&) {
			align-items: center;
			justify-content: center;
			text-align: center;
			gap: 20px;
			padding-left: 16px;
			padding-right: 16px;
			background-image: url('/asset/vendor/orb-3.png');
			background-repeat: no-repeat;
			background-position: center top;
			background-size: contain;

			padding-top: 50%;

			// @media screen and (max-width: 500px) {
			// 	padding-top: calc(50vh - 160px);
			// }
		}
	}

	.logo {

	}

	.large {
		.font(big);
	}

	p {
		.font(regular);
		padding: 8px 0;
	}

	.line {
		width: calc(100% - 40px);
		height: 1px;
		background: radial-gradient(50% 50% at 50% 50%, #FFC700 0%, rgba(255, 199, 0, 0) 100%);
	}

	.action-line {
		width: 100%;
	}

	.timer {
		position: absolute;
		margin-left: 6px;
	}
</style>

<Screen classNames='mvp_3'>
	<div class="logo" bind:this={dm_logo}>
		<img width=66 src="/asset/vendor/logo-2x-dark.png" />
	</div>

	<p>
		With hundreds of beta testers, it can be difficult to process everyone's feedback.
	</p>

	<p>
		Therefore, we are asking every tester to allow anonymous usage data reporting.
	</p>

	<p>
		Keep in mind, this app is prepopulated with dummy data and is not actually connected to any blockchain network, testnet, or otherwise.
	</p>

	<div class="action-line">
		<button on:click={() => push_screen(Mvp_No)}>
			No thanks
		</button>

		<button class="primary" readonly={n_seconds > 0} on:click={() => {
			$yw_task = 1;
			goto_screen(Locked);
		}}>
			I Agree {n_seconds > 0? s_countdown: ''}
		</button>
	</div>
</Screen>