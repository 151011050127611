<script lang="ts">
	import {
		onMount, SvelteComponent, tick,
	} from 'svelte';

	import Fuse from 'fuse.js';

	import {
		ThreadId,
		H_THREADS,
		K_ROUTER,
		yw_screen_dom,
		initialize,
		push_ref,
		push_screen,
		yw_path,
		yw_pattern,
		yw_params,
		yw_thread_id,
		yw_account,
		goto_ref,
		goto_screen,
		XP_APP_WIDTH,
		hm_arrivals,
		yw_exitting_dom,
yw_chain,
yw_family,
yw_fuse,
yw_task,
	} from '#/app';

	import {
		microtask, oderom, timeout,
	} from '#/util/belt';

	import Nav from '#/ui/Nav.svelte';
	import { A_SEARCHABLE, H_ACCOUNTS, H_CHAINS, H_FAMILIES, K_DEFAULT_ACCOUNT } from '#/sim/data';

	import {
		type NewStateConfig,
		State,
		StateThread,
		StateManager,
	} from '#/state/manager';

	import {
		P_WISPR_ROOT,
	} from '#/state/path';

	import type { Hash } from '#/util/types';
	import { Account } from '#/objects/account';
	import { nextTick } from 'process';
import Progress from '#/ui/Progress.svelte';
import InitCreate from '#/screen/InitCreate.svelte';
import InitCreateEdit from '#/screen/InitCreateEdit.svelte';
import SideMenu from '#/ui/SideMenu.svelte';
import { Chain } from '#/objects/chain';
import Overscroll from '#/ui/Overscroll.svelte';
import Contacts from '#/screen/Contacts.svelte';
import Popup from '#/ui/Popup.svelte';
import VendorMenu from '#/ui/VendorMenu.svelte';
import { Family } from '#/objects/family';
import Search from '#/screen/Search.svelte';
import Mvp_1 from '#/screen/Mvp_1.svelte';
import Holdings from '#/screen/Holdings.svelte';


	const SX_NO_TRANSITION = 'all 0s ease 0s';

	const H_POSITION_PROPERTIES = {
		left: 1,
		top: 1,
	};

	let dm_viewport: HTMLDivElement;
	let dm_threads: HTMLDivElement;
	let dm_content: HTMLDivElement;
	let dm_exitting: HTMLDivElement;

	async function slide(dm_slide: HTMLElement, b_in=false): Promise<void> {
		// // set initial position
		// dm_slide.classList.add('sliding');

		// smoother, allow for previous mods to make element visible
		await timeout(0);

		// // dm_slide.style.left = sx_dest;
		// dm_slide.style.transform = `translateX(${b_in? '0px': 'var(--app-window-width)'})`;

		// wait for transition to complete
		return new Promise((fk_resolve) => {
			dm_slide.addEventListener('transitionend', function transition_end(d_event) {
				if('transform' === d_event.propertyName) {
					// change class
					dm_slide.classList.add('slid');

					fk_resolve();
				}
			});

			// apply transform
			dm_slide.style.transform = `translateX(${b_in? '0px': 'var(--app-window-width)'})`;
		});
	}

	onMount(async () => {
		$yw_exitting_dom = dm_exitting;

		// const k_state_root = new State('/locked', null as unknown as SvelteComponent, '/locked');
		// const k_thread_root = new StateThread(k_state_root);

		initialize(new StateManager({
			router: K_ROUTER,
			container: dm_threads,
			threads: oderom(H_THREADS, (si_thread, dc_screen) => {
				// lookup router node corresponding to screen class
				const k_node = K_ROUTER.lookup_screen(dc_screen);

				// ref path pattern
				const sx_pattern = k_node.path_pattern;

				return {
					// [si_thread]: new StateThread(k_state),
					[si_thread]: (h_params: Hash) => ({
						path: k_node.reverse_resolve(h_params),
						pattern: sx_pattern,
						screen: dc_screen,
						props: h_params,
					}),
				};
			}) as {[k in ThreadId]: () => NewStateConfig},

			async push(this: StateManager, ks_src: State, ks_dst: State) {
				// wait for svelte to render component before querying container
				await tick();

				// query container for last element child
				await slide(ks_dst.dom, true);
			},

			pop(this: StateManager, ks_src: State, ks_dst: State, b_bypass_animation=false) {
				if(!b_bypass_animation) {
					ks_src.dom.style.transform = `translateX(var(--app-window-width))`;
				}
			},

			arrive(this: StateManager, ks_src: State, ks_dst: State, si_thread_src: string, s_transition=''): Promise<void> {
				// maintain scrollTop
				const x_scroll_top = ks_src.dom.scrollTop;

				console.log({
					type: 'arrive',
					ks_src,
					ks_dst,
					si_thread_src,
					s_transition,
				});

				// new MutationObserver((m) => {
				// 	debugger;
				// }).observe(ks_src.dom, {
				// 	attributes: true,
				// 	childList: true,
				// });

				// Object.defineProperty(ks_src.dom, 'scrollTop', {
				// 	get() {
				// 		return x_scroll_top;
				// 	},
				// 	set(x_to: number) {
				// 		console.log(`SET TO: ${x_to}`);
				// 		debugger;
				// 	},
				// });

				// neuter src frame
				ks_src.dom.classList.add('frozen');

				// ensure incoming frame is not frozen
				ks_dst.dom.classList.remove('frozen');

				const gc_params = yw_params.get();
				// const gc_params = {};

				$yw_path = ks_dst.path;
				$yw_pattern = ks_dst.pattern;

				const gc_props = ks_dst.props;

				$yw_params = {
					familyId: gc_props.familyId as string || gc_params.familyId,
					chainId: gc_props.chainId as string || gc_params.chainId,
					accountId: gc_props.accountId as string || gc_params.accountId,
				};

				if(gc_props.familyId) {
					const p_family = Family.refFromId(gc_props.familyId as string);
					if(!H_FAMILIES[p_family]) debugger;
					$yw_family = H_FAMILIES[p_family];
				}

				// if(gc_props.chainId) {
				// 	if(!$yw_family) debugger;
				// 	const p_chain = Chain.refFromFamilyId($yw_family.def.iri, gc_props.chainId as string);
				// 	$yw_chain = H_CHAINS[p_chain] || null;
				// }

				// if(gc_props.accountId) {
				// 	console.warn(`<${$yw_path}> props set accountId = ${gc_props.accountId}`);
				// 	$yw_account = H_ACCOUNTS[Account.refFromId(gc_props.accountId as string)];
				// }

				$yw_screen_dom = ks_dst.dom;

				// trigger component settings
				const fk_arrive = hm_arrivals.get(ks_dst.dom);
				if(fk_arrive) fk_arrive();

				return new Promise(async(fk_resolve) => {
					// ref src state's dom
					let dm_src = ks_src.dom;

					// ref classlist
					const d_class_list = dm_src.classList;

					// short circuit expensive computed style call
					let b_transitions = false;
					if('goto' === s_transition) {
						if(d_class_list.contains('slides')) {
								// changing threads
								if(si_thread_src) {
									dm_src = dm_src.cloneNode(true) as HTMLElement;
									dm_exitting.replaceChildren(dm_src);
								}
								dm_src.style.zIndex = '1001';
								
								await timeout(0);

								// dm_src.style.left = `-${XP_APP_WIDTH}px`;
								dm_src.style.transform = `translate(-${XP_APP_WIDTH}px)`;
								b_transitions = true;
						}
						else {
							const si_exit = dm_src.getAttribute('data-s2-exit');
							switch(si_exit) {
								case 'swipes': {
									dm_src.style.left = `-${XP_APP_WIDTH}px`;
									dm_src.style.zIndex = '1001';
									b_transitions = true;
									break;
								}

								case 'leaves': {
									// changing threads
									if(si_thread_src) {
										dm_src = dm_src.cloneNode(true) as HTMLElement;
										dm_exitting.replaceChildren(dm_src);
									}
									dm_src.style.zIndex = '1001';
									
									await timeout(0);

									// dm_src.style.left = `-${XP_APP_WIDTH}px`;
									dm_src.style.transform = `translate(-${XP_APP_WIDTH}px)`;
									b_transitions = true;
									break;
								}

								case 'reveals': {
									dm_src.classList.add('reveal');
									b_transitions = true;
									break;
								}
							}
						}
					}

					// not changing threads
					if(!si_thread_src) {
						// // src leaves
						// if(d_class_list.contains('leaves')) {
						// 	dm_src.style.left = `-${XP_APP_WIDTH}px`;
						// 	dm_src.style.zIndex = '1001';
						// 	b_transitions = true;
						// }

						// src slides out
						if(d_class_list.contains('slides')) {
							b_transitions = true;
						}
					}
					// changing threads
					else {
						// going to search
						if('/search' === ks_dst.pattern) {
							dm_src.classList.add('sublimate');
						}
						// leaving search
						else if('/search' === ks_src.pattern) {
							ks_dst.dom.classList.remove('sublimate');
						}
					}

					// 
					await microtask();

					// element is transitioning
					if(!s_transition.endsWith('.bypass')) {
						if(b_transitions || SX_NO_TRANSITION !== getComputedStyle(dm_src).transition) {
							// wait for transition to end
							dm_src.addEventListener('transitionend', function transition_end(d_event) {
								// not a position property
								if('transform' !== d_event.propertyName) return;

								// remove self
								dm_src.removeEventListener('transitionend', transition_end);

								// resolve
								fk_resolve();
							});

							// wait for callback
							return;
						}
					}

					fk_resolve();
				});
			},
		}));
	

		const h_search = Object.fromEntries(new URLSearchParams(location.search).entries());

		if(K_DEFAULT_ACCOUNT) {
			console.warn(`set account to DEFAULT`);
			$yw_account = K_DEFAULT_ACCOUNT;
		}
		else {
			throw new Error(`Default account not yet live`);
		}

		const si_goto = h_search['goto'];
		switch(si_goto) {
			case 'task-2': {
				$yw_task = -1;
				break;
			}
			case 'task-3': {
				$yw_task = -2;
				break;
			}
			case 'task-4': {
				$yw_task = -3;
				break;
			}
			case 'task-5': {
				$yw_task = -4;
				break;
			}

			case 'welcome':
			case 'mnemonic':
			case 'init-edit':
			{
				$yw_thread_id = ThreadId.INIT;

				switch(si_goto) {
					case 'mnemonic': {
						await timeout(800);
						goto_screen(InitCreate);
						break;
					}

					case 'init-edit': {
						await timeout(800);
						goto_screen(InitCreateEdit, {
							account: K_DEFAULT_ACCOUNT,
						});
						break;
					}
				}

				break;
			}

			case 'holdings': {
				$yw_thread_id = ThreadId.TOKENS;
				break;
			}

			case 'contacts': {
				goto_screen(Contacts, {});
				break;
			}

			default: {
				// if(localStorage.task) {
				// 	$yw_task = +localStorage.task;

				// 	if($yw_task === 9) {
				// 		goto_screen(Holdings);
				// 	}
				// 	break;
				// }

				$yw_task = localStorage.task = 8;

				// goto_screen(Mvp_1);


				break;
			}
		}


		{
			const gc_opt = {
				keys: [
					'label',
					'detail',
				],
			};

			const y_index = Fuse.createIndex(gc_opt.keys, A_SEARCHABLE);
			$yw_fuse = new Fuse(A_SEARCHABLE, gc_opt, y_index);
		}
	});

</script>


<style lang="less">
	@import '../style/util.less';
	@import '../style/global.less';

	.full(@type) {
		position: @type;
		width: 100%;
		height: 100%;
	}

	.full(absolute) {
		top: 0;
		left: 0;
	}

	.viewport {
		.full(relative);
		overflow: hidden;

		color: var(--theme-color-text-light);
		background-color: var(--theme-color-bg);

		>.content {
			.full(relative);
			overflow: hidden;

			width: 100%;
			height: 100%;

			&.exitting {
				position: absolute;
				top: 0;
				z-index: 1001;
				user-select: none;
				pointer-events: none;
			}

			>.thread {
				:global(&) {
					.full(absolute);
				}
			}
	
			// :global(&>section) {
			// 	position: absolute;
			// 	top: 0px;
			// 	transition: left 0.6s var(--ease-out-quick);
			// }
		}
	}
</style>


<div class="viewport" bind:this={dm_viewport}>
	<div class="content threads" bind:this={dm_threads}>
	</div>
	<div class="content exitting" bind:this={dm_exitting}>
	</div>
	<slot></slot>

	<Overscroll />
	<Progress />
	<Nav />
	<Search />
	<VendorMenu />
	<SideMenu />
	<Popup />
</div>
