<script lang="ts">
	import {
		push_screen,
		pop,
	} from '#/app';

	import {
		Screen,
	} from '#/ui';

	import InitCreateEdit from './InitCreateEdit.svelte';
	import { K_DEFAULT_ACCOUNT } from '#/sim/data';
	import { shuffle, timeout } from '#/util/belt';
	import MnemonicTable from '#/ui/MnemonicTable.svelte';

	export let mnemonic: string[];
	let a_mnemonic = mnemonic;

	let i_current = 0;
	let i_avoid = -1;

	let a_challenge: string[] = [];
	$: {
		const a_fake = a_mnemonic.slice();
		a_fake.splice(i_current, 1);
		const s_current = a_mnemonic[i_current];
		a_challenge = [s_current, ...shuffle(a_fake).slice(0, 3)];

		// // @mvp
		// if(i_current < 3) {
		// 	shuffle(a_challenge);

		// 	// avoid putting the correct word in the cell user just clicked
		// 	if(i_avoid >= 0 && s_current === a_challenge[i_avoid]) {
		// 		a_challenge.reverse();
		// 	}
		// }
	}

	let a_confirmed: string[] = [];

	let dm_challenge: HTMLElement;

	$: b_complete = i_current >= a_mnemonic.length;

	async function answer(s_response: string, i_cell: number) {
		if(s_response === a_mnemonic[i_current]) {
			a_confirmed.push(`${i_current+1}. ${a_mnemonic[i_current]}`);
			a_confirmed = a_confirmed;
			i_current += 1;

			// @mvp
			if(i_current >= 9) i_current += 3;

			i_avoid = -1;

			dm_challenge.childNodes[i_cell].classList.add('flashing');
			// dm_challenge.classList.add('nodding');
			await timeout(200);

			if(dm_challenge) {
				// dm_challenge.classList.remove('nodding');
				dm_challenge.childNodes[i_cell].classList.remove('flashing');
			}
		}
		else {
			dm_challenge.classList.add('shaking');
			await timeout(150);
			a_mnemonic = a_mnemonic;
			i_avoid = i_cell;
			await timeout(150);
			dm_challenge.classList.remove('shaking');
		}
	}
</script>

<style lang="less">
	@import '../style/util.less';

	@keyframes shake {
		0%, 100% {
			transform: translateX(0);
		}

		10%, 20%, 50%, 70%, 90% {
			transform: translateX(-3px);
		}

		20%, 40%, 60%, 80% {
			transform: translateX(3px);
		}
	}

	@keyframes nod {
		0%, 100% {
			transform: translateY(0);
		}

		25% {
			transform: translateY(4px);
		}
	}

	// .solution {
	// 	display: flex;
	// 	flex-flow: row wrap;
	// 	gap: 0.5em;
	// 	list-style: none;
	// 	padding: 0;
	// 	justify-content: flex-start;

	// 	>.word {
	// 		.font(tiny);
	// 		background-color: var(--theme-color-border);
	// 		border-radius: 2em;
	// 		padding: 0.25ex 0.8em;
	// 	}
	// }

	.challenge {
		display: grid;
		grid-template-columns: auto auto;
		text-align: center;
		gap: 1px;
		// flex-basis: 16%;
		flex-basis: calc(100% - 520px);
		max-height: 16.5%;

		&.complete {
			display: none;
		}

		&.shaking {
			>.cell {
				animation-name: shake;
				animation-timing-function: linear;
				animation-iteration-count: 1;
				animation-duration: 300ms;
				pointer-events: none;
			}
		}

		&.nodding {
			>.cell {
				animation-name: nod;
				animation-timing-function: var(--ease-out-cubic);
				animation-iteration-count: 1;
				animation-duration: 200ms;
				// pointer-events: none;
			}
		}

		>.cell {
			padding: 1.25em 0;
			min-height: 00px;
			outline: 1px solid var(--theme-color-border);
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 2px;

			.hint() {
				color: var(--theme-color-primary);
				position: relative;
				display: inline-block;
				width: 0;
			}

			&.hint::before {
				.hint();
				content: '>>';
				left: -20px;
			}

			&.hint::after {
				.hint();
				content: '<<';
			}

			@keyframes flashing {
				0% {
					border-color: transparent;
				}

				100% {
					border-color: var(--theme-color-primary);
				}
			}
			
			&.flashing {
				animation: flashing 100ms linear infinite alternate;
				border: 1px solid transparent;
			}

			&:active {
				border: 1px solid var(--theme-color-primary);
			}

			>.index {
				color: var(--theme-color-text-med);
			}

			&:nth-child(1) {
				border-top-left-radius: 4px;
			}

			&:nth-child(2) {
				border-top-right-radius: 4px;
			}

			&:nth-child(3) {
				border-bottom-left-radius: 4px;
			}

			&:nth-child(4) {
				border-bottom-right-radius: 4px;
			}
		}
	}

</style>

<Screen progress={[3, 4]}>
	<h3>
		Verify mnemonic
	</h3>

	<!-- <ol class="solution">
		{#each a_confirmed as s_confirmed}
			<li class="word">
				{s_confirmed}
			</li>
		{/each}
	</ol> -->

	<MnemonicTable words={mnemonic} reveal={i_current} />

	{#if !b_complete}
		<p>
			Click the correct word at position:
		</p>
	{:else}
		<p class="text-align_center">
			All done!
		</p>
	{/if}

	<div class="challenge user-select_none"
		class:visibility_hidden={b_complete}
		class:shaking={false}
		class:nodding={false}
		class:hidden={b_complete}
		bind:this={dm_challenge}
	>
		{#each a_challenge as s_challenge, i_cell}
			<span class="cell clickable" on:click={() => answer(s_challenge, i_cell)}
				class:hint={s_challenge === a_mnemonic[i_current]}
				class:flashing={false}
			>
				<span class="index">{i_current+1}.</span>
				{s_challenge}
			</span>
		{/each}
	</div>

	<div class="action-line">
		<button on:click={() => pop()}>
			Back
		</button>

		<button disabled={!b_complete} class="primary" on:click={() => push_screen(InitCreateEdit, {account:K_DEFAULT_ACCOUNT})}>
			Continue
		</button>
	</div>
</Screen>