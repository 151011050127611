import {
	type IntraChain,
	ClassType,
	Definable,
	type Labelable,
	type Thing,
} from './_core';

import type {
	WisprResUri,
} from '#/state/path';

import type { Chain } from './chain';
import { Token } from './token';
import type { Icon } from './icon';
import type { Tag } from './tag';

type IbctPath = `${Token.Path}/ibcts/${string}`;

type IbctRef = Token.MemberRef<ClassType.IBCT>;  // `${WisprResUri<IntraChain<ClassType.TOKEN>>}ibct/`;

interface IbctDef extends Token.Member<`ibcts/${string}`> {
	class: ClassType.IBCT,
	homeTokenRef: Token.Ref;
	colonies: Record<Chain.Ref, Token.Ref>;
}

interface IbctConfig {
	homeTokenRef: Token.Ref;
	colonies: Record<Chain.Ref, Token.Ref>;
}

// export interface TokenParam extends Chain.Param {
// 	tokenId: string;
// }

class IbctDef {
	static fromConfig(gc_ibct: IbctConfig): IbctDef {
		return {
			...gc_ibct,
			colonies: gc_ibct.colonies || {},
			iri: Ibct.refFromHomeToken(gc_ibct.homeTokenRef),
			class: ClassType.IBCT,
		};
	}
}


export class Ibct extends Definable<IbctDef> {
	static refFromHomeToken(p_token: Token.Ref): IbctRef {
		return `${p_token}ibcts/.default/` as IbctRef;
	}

	static parseRef(sr_ref: IbctRef) {
		const {
			chainId: si_chain,
			tokenId: si_token,
			rest: sr_path,
		} = Token.parseRef(sr_ref);

		// const a_parsed = parse_path_pair(sr_path, 'ibct');
	
		return {
			chainId: si_chain,
			tokenId: si_token,
			rest: sr_path
		};
	}
}

export namespace Ibct {
	export type Ref = IbctRef;
	export type Def = IbctDef;
	export const Def = IbctDef;
	export type Config = IbctConfig;
	// export type Param = IbctParam;
}
