import {
	ClassType,
	Definable,
	type SearchConfig,
} from './_core';

import type {
	WisprResUri,
} from '#/state/path';

import type { Icon } from './icon';
import { uuid_v4 } from '#/util/dom';
import type { Chain } from './chain';
import type { Holding } from './holding';
import { oderac, oderaf } from '#/util/belt';
import { H_CHAINS } from '#/sim/data';


type AccountRef = WisprResUri<ClassType.ACCOUNT>;

interface AccountDef extends Icon.Refable<`accounts/${string}`> {
	class: ClassType.ACCOUNT,
	id: string;
	pubkey: string;
}

interface AccountConfig {
	id?: string;
	label: string;
	pubkey: string;
	iconRef?: Icon.Ref,
}

class AccountDef {
	static fromConfig(gc_account: AccountConfig): AccountDef {
		const si_account = gc_account.id || uuid_v4();

		return {
			class: ClassType.ACCOUNT,
			id: si_account,
			pubkey: gc_account.pubkey,
			iri: `wispr://root/accounts/${si_account}/`,
			label: gc_account.label,
			iconRef: gc_account.iconRef as Icon.Ref,
			tagRefs: [],
		};
	};
}

export class Account extends Definable<AccountDef> {
	static refFromId(si_account: string): AccountRef {
		return `wispr://root/accounts/${si_account}/`;
	}

	get aggregator(): boolean {
		return '*' === this._g_def.id;
	}

	get label(): string {
		return this.aggregator? 'All accounts': `${this.def.label} - #${this.def.id}`;
	}

	address(k_chain: Chain): string {
		if(!k_chain) debugger;
		return this.aggregator? '*': `${k_chain.def.bechPrefix}1${this._g_def.pubkey}`;
	}

	holdings(H_HOLDINGS: Record<Holding.Ref, Holding>, k_chain: Chain | null=null): Holding[] {
		if(k_chain) {
			return oderaf(H_HOLDINGS, (p_holding, k_holding) => {
				if(this.address(k_chain) === k_holding.def.holderAddr) {
					return [k_holding];
				}

				return [];
			});	
		}

		return oderaf(H_HOLDINGS, (p_holding, k_holding) => {
			return oderaf(H_CHAINS, (p_chain, k_chain) => {
				if(this.address(k_chain) === k_holding.def.holderAddr) {
					return [k_holding];
				}

				return [];
			});
		});
	}

	// search(s_search: string, gc_search: SearchConfig) {
	// 	if(s_search)
	// 	return {
	// 		type: 
	// 	};
	// }
}

export namespace Account {
	export type Ref = AccountRef;
	export type Def = AccountDef;
	export const Def = AccountDef;
	export type Config = AccountConfig;
}

