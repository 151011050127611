<script lang="ts">
	import {
		cubicOut,
	} from 'svelte/easing';

	import type {
		SlideParams,
		TransitionConfig,
	} from 'svelte/transition';

	import type {
		Tag,
	} from '#/objects';

	// import SX_EDIT from '@material-design-icons/svg/filled/edit.svg?raw';
	import SX_ADD from '#/asset/action/add.svg?raw';
	import SX_EDIT from '#/asset/action/edit-small.svg?raw';
import { yw_popup, yw_popup_context } from '#/app';
import PopupTagsSelect from './PopupTagsSelect.svelte';
import type { Hash } from '#/util/types';
import { qsa } from '#/util/dom';
import { onDestroy } from 'svelte';

	export let tags: Tag[];

	export let collapsed = false;
	export let editable = false;
	export let prefixClass = '';
	export let suffixClass = '';

	export let rootStyle = '';

	if(editable) {
		yw_popup_context.subscribe((g_ctx: Hash<any> | null) => {
			if(g_ctx?.tags) {
				tags = g_ctx.tags;
			}
		});

		onDestroy(() => {
			$yw_popup_context = null;
		});
	}

	function show_tag_selector() {
		$yw_popup_context = {
			tags: tags,
		};

		$yw_popup = PopupTagsSelect;
	}

	let dm_cluster: HTMLElement;

	function remove_tag(k_tag: Tag, d_event: MouseEvent) {
		const i_tag = tags.findIndex((k) => k === k_tag);
		// (qsa(dm_cluster, '.delete.icon')[Math.min(tags.length - 1, i_tag + 1)] as HTMLElement).style.display = 'none';
		tags.splice(i_tag, 1);
		tags = tags;
	}


	function sslide(dm_node: Element, {
		delay: xt_delay = 0,
		duration: xt_duration = 400,
		easing: f_easing = cubicOut,
	}: SlideParams = {}): TransitionConfig {
		const d_style = getComputedStyle(dm_node);
		const x_opacity = +d_style.opacity;
		const x_width = parseFloat(d_style.width);
		const x_padding_left = parseFloat(d_style.paddingLeft);
		const x_padding_right = parseFloat(d_style.paddingRight);
		const x_margin_left = parseFloat(d_style.marginLeft);
		const x_margin_right = parseFloat(d_style.marginRight);
		const x_border_left_width = parseFloat(d_style.borderLeftWidth);
		const x_border_right_width = parseFloat(d_style.borderRightWidth);

		return {
			delay: xt_delay,
			duration: xt_duration,
			easing: f_easing,
			css: xt =>
				'--delete-display: none;' +
				'overflow: hidden;' +
				`opacity: ${Math.min(xt * 20, 1) * x_opacity};` +
				`width: ${xt * x_width}px;` +
				`padding-left: ${xt * x_padding_left}px;` +
				`padding-right: ${xt * x_padding_right}px;` +
				`margin-left: ${xt * x_margin_left}px;` +
				`margin-right: ${xt * x_margin_right}px;` +
				`border-left-width: ${xt * x_border_left_width}px;` +
				`border-right-width: ${xt * x_border_right_width}px;`
		};
	}
</script>

<style lang="less">
	@import '../style/util.less';

	.cluster {
		display: inline-flex;
		vertical-align: middle;
		justify-content: center;
		align-items: center;
		gap: 4px;
		flex-flow: row wrap;
		
		&.collapsed {
			margin: var(--tag-cluster-margin, 0);
			margin-top: 5px;
			justify-content: flex-start;
			display: flex;
		}

		&.editable {
			justify-content: flex-start;
			// margin: var(--tag-cluster-margin, 0 var(--ui-padding));

			>.tag {
				padding-right: 2px;
			}

			 margin-top:-10px;
			 margin-bottom:5px;
		}

		>.tag {
			--tag-width: auto;
			--tag-height: 22px;

			display: inline-flex;
			width: var(--tag-width);
			height: var(--tag-height);
			border-radius: 1em;
			padding: 0 1ch;
			font-size: 13px;

			>.label {
				margin-top: 1px;
				text-shadow: -1px 1px 1.3px rgb(0 0 0 / 40%);
			}

			// &:nth-child(n+2) {
			// 	margin-left: 8px;
			// }

			&.collapsed {
				--tag-width: var(--app-tag-diameter);
				--tag-height: var(--app-tag-diameter);
				padding: 0;
			}

			.delete.icon {
				--icon-diameter: 22px;
				transform: rotate(45deg);
				transition: transform 200ms ease-out, filter 200ms ease-out;
				cursor: pointer;
				filter: drop-shadow(0px 0px 0px black);
				display: var(--delete-display, initial);

				&:hover {
					transform: rotate(45deg) scale(1.075);
					filter: drop-shadow(-1px 3px 2px rgba(0, 0, 0, 0.4));
				}
			}
		}

		>.edit {
			cursor: pointer;

			>.icon {
				--icon-color: var(--theme-color-primary);
				--icon-diameter: 22px;
				display: flex;
				background-color: transparent;

				// --icon-color: var(--theme-color-primary);
				// --icon-diameter: 16px;
				// --button-diameter: 22px;
				// display: flex;
				// padding: 2px;
				// background-color: transparent;

				// margin-left: 4px;

				// &::before {
				// 	--offset: 0px;
				// 	content: "";
				// 	outline: 2px solid var(--theme-color-primary);
				// 	border-radius: var(--button-diameter);
				// 	min-width: calc(var(--button-diameter) - (2 * var(--offset)));
				// 	min-height: calc(var(--button-diameter) - (2 * var(--offset)));
				// 	margin-left: -3px;
				// 	margin-top: 1px;
				// 	position: absolute;
				// }
			}
		}

		.prefix,.suffix {
			.font(tiny);
			color: var(--theme-color-text-light);
			display: inline-flex;
			text-align: center;
		}
	}
</style>

<span class="cluster" class:editable={editable} class:collapsed={collapsed} bind:this={dm_cluster} style={rootStyle}>
	{#if $$slots.prefix}
		<span class="prefix {prefixClass}">
			<slot name="prefix"></slot>
		</span>
	{/if}

	{#each tags as k_tag, i_tag}
		<span class="tag" style="background-color:{k_tag.def.color};" class:collapsed={collapsed} out:sslide={{duration: editable? 300: 0}}>
			{#if !collapsed}
				<span class="label">
					{k_tag.def.label}
				</span>
			{/if}

			{#if editable}
				<span class="delete icon clickable" on:click={(d_event) => remove_tag(k_tag, d_event)}>
					{@html SX_ADD}
				</span>
			{/if}
		</span>
	{/each}

	{#if editable}
		<span class="edit clickable" on:click={() => show_tag_selector()}>
			<span class="icon">
				{@html SX_EDIT}
			</span>
		</span>
	{/if}

	{#if $$slots.suffix}
		<span class="suffix {suffixClass}">
			<slot name="suffix"></slot>
		</span>
	{/if}
</span>