<script lang="ts">
import { yw_account, yw_chain } from '#/app';

	import type {
		Nft,
	} from '#/objects';

	import Header from "#/ui/Header.svelte";
	import Screen from "#/ui/Screen.svelte";

	export let nft: Nft;
	export const minterId = nft.def.address;
	export const tokenId = nft.def.id;

</script>

<style lang="less">
	@import '../style/util.less';

	div.icon {
		margin: 0;
		padding: 0 var(--ui-padding);
		box-sizing: border-box;

		img {
			outline: 1px solid var(--theme-color-border);
			border-radius: 0;
		}
	}
</style>

<Screen nav slides
>
	<Header pops network account
	/>

	<div class="icon">
		<img src={nft.def.image} />
	</div>
	
	<div class="title">
		{nft.def.autoLabel} - 
		"{nft.def.label}"
	</div>
</Screen>