<script lang="ts">
	import Screen from "#/ui/Screen.svelte";
import Mvp_2 from "./Mvp_2.svelte";

	import SX_TITLE from '#/asset/vendor/starshell-title-large.svg?raw';
import { push_screen } from "#/app";

	const p_high = '/asset/vendor/orb-1-high.png';
	let dm_logo: HTMLElement;
	const dm_img = new Image();
	dm_img.onload = () => {
		(dm_logo.closest('.mvp_1') as HTMLElement).style.backgroundImage = `url('${p_high}')`;
	};
	dm_img.src = p_high;

	
</script>

<style lang="less">
	@import '../style/util.less';

	.thread>section.screen.mvp_1 {
		:global(&) {
			align-items: center;
			justify-content: center;
			text-align: center;
			gap: 20px;
			padding-left: 16px;
			padding-right: 16px;
			background-image: url('/asset/vendor/orb-1.png');
			background-repeat: no-repeat;
			background-position: center top;
			background-size: contain;

			padding-top: calc(50% - 50px);

			// @media screen and (max-width: 500px) {
			// 	padding-top: calc(50% - 70px);
			// }
		}
	}

	.logo {

	}

	.large {
		.font(big);
	}

	p {
		.font(regular);
		padding: 8px 0;
	}

	.line {
		width: calc(100% - 40px);
		height: 1px;
		background: radial-gradient(50% 50% at 50% 50%, #FFC700 0%, rgba(255, 199, 0, 0) 100%);
	}

	.action-line {
		width: 100%;
	}

	.off-screen {
		position: absolute;
		top: calc(var(--app-window-width) * 100);
	}
</style>

<Screen root classNames='mvp_1' style='overflow:hidden;'>
	<div class="logo" bind:this={dm_logo}>
		<img width=96 src="/asset/vendor/logo-2x.png" />
	</div>

	<div class="title">
		{@html SX_TITLE}
	</div>

	<div class="line">{@html '&nbsp;'}</div>

	<div class="large">
		<div>Welcome to the beta program.</div>
		<div>Thank for you being a tester!</div>
	</div>

	<p>
		The app you are about to test is what is called an "MVP". Our goal is to make sure the UI is intuitive for new users, and that things work the way you'd expect them to.
	</p>

	<div class="action-line">
		<button class="primary" on:click={() => push_screen(Mvp_2)}>
			Next
		</button>
	</div>

	<div class="off-screen">
		<img src="/asset/vendor/orb-2.png" />
		<img src="/asset/vendor/orb-3.png" />
		<img src="/asset/vendor/space.png" />
	</div>
</Screen>