<script lang="ts">

	import { A_TXNS } from '#/sim/data';
import {
		Screen,
		Header,
	} from '#/ui';
import SubHeader from '#/ui/SubHeader.svelte';
import TxnList, { TxnContext } from '#/ui/TxnList.svelte';

	const a_history = A_TXNS.sort((k_a, k_b) => {
		return k_b.def.timestamp - k_a.def.timestamp;
	});

</script>

<style lang="less">
	@import '../style/util.less';

</style>

<Screen nav root
>
	<Header search network account
	>
		<svelte:fragment slot="title">

		</svelte:fragment>
	</Header>

	<SubHeader
		title='History'
		bare
	></SubHeader>

	<p style='font-size:12px'>
		<span style='color:var(--theme-color-caution);'>Disclaimer: </span>
		This temporary history interface does not represent the look and function of the actual history interface currently under development for beta.
	</p>

	
	<TxnList
		txns={a_history}
	>

	</TxnList>
</Screen>
