<script lang="ts">
	import {
		Screen,
		Field,
		Info,
		Row,
		Address,
	} from "#/ui";

	import type {
		Account,
	} from '#/objects';

	import AccountCreate from './AccountCreate.svelte';
	import Header from "#/ui/Header.svelte";
	import { ode } from "#/util/belt";
	import { H_ACCOUNTS, H_SITES } from "#/sim/data";
	import { push_screen, yw_chain } from "#/app";
	import SubHeader from "#/ui/SubHeader.svelte";
	import DeadEnd from "./DeadEnd.svelte";
	import AccountView from "./AccountView.svelte";



</script>

<style lang="less">
	@import '../style/util.less';

	.hd-path {
		:global(&) {
			.font(tiny);
			color: var(--theme-color-text-med);
		}
	}
</style>

<Screen nav root>
	<Header search network account
	>
	</Header>

	<SubHeader bare
		title="Sites"
	/>

	<div class="rows no-margin">
		{#each Object.values(H_SITES) as k_site}
			{@const gd_site = k_site.def}
			<Row
				name={gd_site.label}
				iconRef={gd_site.iconRef}
				on:click={() => push_screen(DeadEnd)}
			>
				<svelte:fragment slot="detail">
					4 allowanced tokens
				</svelte:fragment>
			</Row>
		{/each}
	</div>
</Screen>