import {
	ClassType,
	Definable,
} from './_core';

import {
	type WisprRef,
	type WisprResUri,
	parse_path_pair,
	wispr_path,
} from '#/state/path';

import {
	Icon,
	Token,
	Family,
} from '#/objects';

import type { Plural } from '#/util/types';

type ChainRef = Family.MemberRef<ClassType.CHAIN>;

type ChainPath = `${Family.Path}/chains/${string}`;

interface ChainDef extends Icon.Refable<ChainPath> {
	class: ClassType.CHAIN,
	id: string;
	rpc: string;
	rest: string;
	bechPrefix: string;
	familyRef: Family.Ref;
};


interface ChainConfig {
	id: string;
	label: string;
	iconRef: Icon.Ref;
	bechPrefix?: string;
	familyRef: Family.Ref;
};

class ChainDef {
	static fromConfig(gc_chain: ChainConfig): ChainDef {
		const p_family = gc_chain.familyRef;

		return {
			class: ClassType.CHAIN,
			iri: Chain.refFromFamilyId(p_family, gc_chain.id),
			familyRef: p_family,
			id: gc_chain.id,
			label: gc_chain.label,
			rpc: '',
			rest: '',
			iconRef: gc_chain.iconRef || Icon.BLANK.def.iri,
			bechPrefix: gc_chain.bechPrefix || gc_chain.id.split('-')[0],
			tagRefs: [],
		};
	}

	static parseRef(sr_ref: WisprRef): ChainParam {
		const {
			familyId: si_family,
			rest: sr_path,
		} = Family.Def.parseRef(sr_ref);

		const a_parsed = parse_path_pair(sr_path, 'chains');
	
		return {
			familyId: si_family,
			chainId: a_parsed[0],
			rest: a_parsed[1],
		};
	}
}

const RT_BECH_DATA = /^[a-zA-HJ-NP-Z0-9]{38}$/;

export class Chain extends Definable<ChainDef> {
	static refFromFamilyId(p_family: Family.Ref, si_chain: string): ChainRef {
		return `${p_family}chains/${si_chain}/`;
	}

	verifyAddressLegitimacy(s_address: string) {
		const s_prefix = this._g_def.bechPrefix+'1';
		return s_address.startsWith(s_prefix) && RT_BECH_DATA.test(s_address.slice(s_prefix.length));
	}

	native(H_TOKENS: Record<Token.Ref, Token>): Token {
		return H_TOKENS[Token.refFromChainAddr(this.def.iri, '.native')]
	}
}

interface ChainParam extends Family.Param {
	chainId: string;
}

export namespace Chain {
	export type Ref = ChainRef;
	export type Def = ChainDef;
	export const Def = ChainDef;
	export type Config = ChainConfig;

	export type Path = ChainPath;

	export type Param = ChainParam;

	export type MemberRef<
		s_path extends string=string,
	> = `${ChainRef}${Plural<s_path>}/${string}/`;

	export interface Resource<
		s_path extends string=string,
	> extends Icon.Refable<`${ChainPath}/${s_path}`> {
		chainRef: Ref;
	}

	export interface Asset<
		s_path extends string=string,
	> extends Resource<s_path> {
		address: string;
	}

}
