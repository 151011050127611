<script lang="ts">
	import { pop, ThreadId, yw_account, yw_blur, yw_cancel_search, yw_chain, yw_exitting_dom, yw_header_props, yw_overlay_account, yw_overlay_network, yw_screen_dom, yw_search, yw_thread_id, yw_vendor_menu } from '#/app';

	import ArrowBack from 'svelte-material-icons/ArrowLeft.svelte';
	import SX_SEARCH from '@material-design-icons/svg/filled/search.svg?raw';

	import SX_LOGO from '#/asset/vendor/logo.svg?raw';
	import SX_DROP_DOWN from '#/asset/nav/drop-down.svg?raw';

	import SX_CHECKED from '#/asset/nav/checked-circle.svg?raw';

	import Put from './Put.svelte';
	import { format_fiat, H_ACCOUNTS, H_CHAINS, H_HOLDINGS, H_ICONS, H_TOKENS, H_VERSUS_USD } from '#/sim/data';
	import { createEventDispatcher, onMount, tick } from 'svelte';
	import { microtask, ode, timeout } from '#/util/belt';
	import { qs } from '#/util/dom';
	import Close from './Close.svelte';
	import OverlaySelect from './OverlaySelect.svelte';
	import Row from './Row.svelte';

	import {
		Account,
		Icon,
		Holding,
	} from '#/objects';
import Pfp from './Pfp.svelte';

	export let pops = false;
	export let closes = false;

	/**
	 * If true, does not display the logo in cases where the logo would display
	 */
	export let plain = false;

	export let account = false;
	export let network = false;
	export let search = false;

	export let name = '';
	export let symbol = '';
	export let subname = '';

	

	$: p_account_icon = account? $yw_account?.def?.iconRef: null;

	let dm_header: HTMLElement;

	let dm_search: HTMLElement;

	export let isSearchScreen = false;
	export let search_input = '';

	let s_search = $yw_search;

	const dispatch = createEventDispatcher();

	// onMount(() => {
	// 	if($yw_search) {
	// 		setTimeout(() => {
	// 			console.log('stealing focus for entry search');
	// 			dm_search.focus();
	// 		}, 0);
	// 	}
	// });

	yw_search.subscribe((s_value) => {
		if(isSearchScreen && s_value) {
			console.log('search screen and search text');
			s_search = s_value;
			if(dm_search) {
				setTimeout(() => {
					dm_search.focus();
				}, 0);
			}
		}
		else if(!s_value) {
			s_search = '';
		}
	});

	async function update_search(d_event: Event) {
		// currently in search thread
		if(isSearchScreen) {
			// search is being 
			if(!s_search) {
				$yw_search = '';
				$yw_cancel_search();
			}
			else {
				dispatch('search', s_search);
			}
		}
		// not search screen, but user typed something
		else if(s_search) {
			const si_cache = $yw_thread_id;

			const dm_focus = qs(dm_header, ':focus') as HTMLElement;
			if(dm_focus) {
				dm_focus.blur();
			}

			// dm_header.style.visibility = 'hidden';

			// const dm_clone = $yw_screen_dom.cloneNode(true) as HTMLElement;
			// $yw_exitting_dom.append(dm_clone)

			// $yw_header_props = {
			// 	pops,
			// 	account,
			// 	network,
			// 	search,
			// 	name,
			// 	symbol,
			// 	subname,
			// 	search_input: $yw_search,
			// };

			$yw_cancel_search = () => {
				// dm_header.style.visibility = 'visible';
				// $yw_search = '';
				
				// $yw_thread_id = si_cache;

				s_search = '';

				console.log('stealing focus for cancel search');
				dm_search.focus();
			};


			// save search string
			$yw_search = s_search;
			
			// // set thread
			// $yw_thread_id = ThreadId.SEARCH;

		}
	}

	// async function search_input() {
	// 	await tick();
	// 	debugger;
	// 	if(s_search_input) {
	// 		if(ThreadId.SEARCH !== $yw_thread_id) {
	// 			dm_header.style.visibility = 'hidden';
	// 			// const dm_clone = dm_header.cloneNode(true);
				
	// 			$yw_thread_id = ThreadId.SEARCH;
	// 		}
	// 	} 
	// }

</script>

<style lang="less">
	.header {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&.blur {
			>*:not(.top) {
				filter: blur(2px);
			}
		}

		>*:not(.top) {
			transition: blur 400ms var(--ease-out-cubic);
		}
	}

	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;

		>.back {
			flex: 1;
			color: var(--theme-color-primary);
			cursor: pointer;
			max-width: 24px;
			margin-right: 20px;
		}

		>.logo.icon {
			--icon-diameter: 32px;
			transform: scale(1.425);
			cursor: pointer;
		}

		>.main {
			flex: 3;
			// max-width: fit-content;
			cursor: default;
			margin-right: 1em;
			min-width: 25%;

			>.title {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				>.name {
					font-weight: 500;
					color: var(--theme-color-text-light);
				}

				>.symbol {
					font-weight: 400;
					color: var(--theme-color-text-med);
				}
			}

			>.subtitle {
				font-size: 12px;
				font-weight: 500;
				color: var(--theme-color-text-med);
				white-space: nowrap;
			}
		}

		>.right {
			flex: 5;
			display: flex;
			max-width: max-content;
			align-items: center;
			gap: 1em;

			// // for absolute-positioned overlay child
			// position: relative;

			&.heightless {
				height: 0;
			}

			// >.network {
			// 	flex: 3;
			// 	max-width: fit-content;
			// 	cursor: pointer;
			// 	white-space: nowrap;
			// 	margin-top: -17px;

			// 	>.icon {
			// 		--icon-color: var(--theme-color-primary);
			// 		--icon-diameter: 24px;
			// 		vertical-align: middle;
			// 		margin-left: -4px;
			// 	}
			// }

			>span.network {
				--icon-diameter: 26px;
				--button-diameter: 32px;
				margin-bottom: -1px;
			}

			>.account,>.network {
				--icon-diameter: 32px;

				flex: 1;
				max-width: var(--icon-diameter);
				min-width: var(--icon-diameter);
				margin-bottom: -5px;
				cursor: pointer;

				.face {
					display: inline-block;
					width: var(--icon-diameter);
					height: var(--icon-diameter);
					border-radius: var(--icon-diameter);
					background-color: var(--theme-color-primary);
				}
			}

			.pfp.icon {
				// background-color: var(--theme-color-bg);
				background: radial-gradient(ellipse farthest-side at bottom right, #07080a, #0f1317);
			}
		}
	}

	.search {
		font-weight: 400;
		position: relative;

		>.action {
			--icon-diameter: 20px;
			width: var(--icon-diameter);
			height: var(--icon-diameter);
			fill: var(--theme-color-text-light);

			position: absolute;
			right: 12px;
			top: calc(50% - (var(--icon-diameter) / 2));

			>svg {
				:global(&) {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
</style>

<div class="header" bind:this={dm_header} class:blur={$yw_blur}>
	<div class="top">
		{#if pops}
			<span class="back clickable" on:click={() => pop()}>
				<ArrowBack size='24px' />
			</span>
		{:else if !closes && !plain}
			<span class="logo icon clickable" on:click={() => $yw_vendor_menu = true}>
				{@html SX_LOGO}
			</span>
		{/if}

		<span class="main">
			<div class="title">
				{#if name}
					<span class="name">
						{name}
					</span>
					{#if symbol}
						<span class="symbol">
							- {symbol}
						</span>
					{/if}
				{/if}
			</div>

			<div class="subtitle">
				{subname}
			</div>
		</span>

		<span class="right" class:heightless={!network && closes}>
			{#if network}
				<span class="network clickable" on:click={(d_event) => {
					d_event.stopPropagation();
					$yw_overlay_network = !$yw_overlay_network
				}}>
					<!-- <span class="text">
						{$yw_chain?.def.label}
					</span>

					<span class="icon">
						{@html SX_DROP_DOWN}
					</span> -->

					{#key $yw_chain}
						<Pfp iconRef={$yw_chain.def.iconRef} name={$yw_chain.def.label} genStyle='font-size:21px;' rootStyle='padding:3px;'>
						</Pfp>
					{/key}
				</span>

				{#if $yw_overlay_network}
					<OverlaySelect
						title='Switch Network'
						bind:open={$yw_overlay_network}
					>
						<svelte:fragment slot="rows">
							{#each ode(H_CHAINS) as [p_chain, k_chain]}
								<Row
									name={k_chain.def.label}
									detail='Default Provider'
									iconRef={k_chain.def.iconRef}
									on:click={() => {
										$yw_chain = k_chain;
										$yw_overlay_network = false;
									}}
								>
									<svelte:fragment slot="right">
										{#if $yw_chain.def.iri === p_chain}
											<span class="overlay-select icon">
												{@html SX_CHECKED}
											</span>
										{/if}
									</svelte:fragment>
								</Row>
							{/each}
						</svelte:fragment>
					</OverlaySelect>
				{/if}
			{/if}

			{#if account}
				<span class="account clickable" on:click={(d_event) => {
					d_event.stopPropagation();
					$yw_overlay_account = !$yw_overlay_account;
				}}>
					{#key $yw_account}
						<Pfp iconRef={p_account_icon || ''} name={$yw_account.def.label} genStyle='font-size:21px;'>
						</Pfp>
					{/key}

						<!-- <span class="square pfp icon">
							<Put element={k_icon.render()} />
						</span> -->
				</span>

				{#if $yw_overlay_account}
					<OverlaySelect
						title='Switch Account'
						bind:open={$yw_overlay_account}
					>
						<svelte:fragment slot="rows">
							{#if Object.keys(H_ACCOUNTS).length > 2}
								<Row
									name="All Accounts"
									detail={format_fiat(Object.values(H_ACCOUNTS).reduce((c_sum, k_account) => c_sum + (k_account.aggregator? 0: Holding.usdSum(k_account.holdings(H_HOLDINGS, $yw_chain), H_TOKENS, H_VERSUS_USD)), 0))}
									on:click={() => {
										const p_account_all = Account.refFromId('*');
										$yw_account = H_ACCOUNTS[p_account_all];
										$yw_overlay_account = false;
									}}
								>
									<svelte:fragment slot="right">
										{#if $yw_account.def.iri === Account.refFromId('*')}
											<span class="overlay-select icon">
												{@html SX_CHECKED}
											</span>
										{/if}
									</svelte:fragment>

									<svelte:fragment slot="icon">
										<span class="pfp square icon aggregator" style="display: inline-block; font-size:30px; margin-left:auto; margin-right:auto;">
											A
										</span>

										<!-- <span class="pfp icon stack">
											{#each ode(H_ACCOUNTS).filter(([,k]) => !k.aggregator) as [p_account, k_account]}
												<span class="icon">
													<Put element={H_ICONS[k_account.def.iconRef].render()} />
												</span>
											{/each}
										</span> -->
									</svelte:fragment>
								</Row>
							{/if}

							{#each ode(H_ACCOUNTS).filter(([,k]) => !k.aggregator) as [p_account, k_account]}
								<Row
									name={k_account.def.label}
									detail={format_fiat(Holding.usdSum(k_account.holdings(H_HOLDINGS, $yw_chain), H_TOKENS, H_VERSUS_USD))}
									on:click={() => {
										$yw_account = k_account;
										$yw_overlay_account = false;
									}}
								>
									<svelte:fragment slot="right">
										{#if $yw_account.def.iri === p_account}
											<span class="overlay-select icon">
												{@html SX_CHECKED}
											</span>
										{/if}
									</svelte:fragment>

									<svelte:fragment slot="icon">
										<span class="pfp square icon">
											<Put element={H_ICONS[k_account.def.iconRef].render()} />
										</span>
									</svelte:fragment>
								</Row>
							{/each}
						</svelte:fragment>
					</OverlaySelect>
				{/if}
			{/if}

			{#if closes}
				<Close on:click={() => dispatch('close')} />
			{/if}
		</span>
	</div>

	{#if search}
		<div class="search">
			<input type="text"
				autofocus={isSearchScreen}
				placeholder="Search by token, account, contact..."
				bind:value={s_search}
				on:input={update_search}
				bind:this={dm_search}
			>
			<span class="action">
				{@html SX_SEARCH}
			</span>
		</div>
	{/if}
</div>
