import {
	type Thing,
	ClassType,
	Definable,
} from './_core';

import type {
	WisprResUri,
} from '#/state/path';

import type { Tag } from './tag';

import {
	dd,
	uuid_v4,
} from '#/util/dom';
import { H_ADDRESS_TO_TOKEN, H_ICONS } from '#/sim/data';

type IconRef = WisprResUri<ClassType.ICON>;

enum IconType {
	BLANK = 0,
	IMAGE = 1,
	HTML = 2,
	PAIR = 3,
	COMPOSITE = 4,
}

interface IconDef extends Thing<`icons/${string}`> {
	class: ClassType.ICON,
	type: IconType;
	data: string;
	extra?: {
		attributes?: {},
	};
}

interface IconConfig {
	type: IconType;
	iri: IconRef;
	data: string;
	extra?: IconExtra;
}

export interface IconExtra {
	attributes?: Record<string, string>;
}


function refFromFile(sr_file: string): IconRef {
	return `wispr://root/icons/.${sr_file}/`;
}

function refFromUuid(): IconRef {
	return `wispr://root/icons/.transient/${uuid_v4()}/`;
}

class IconDef {
	static BLANK = IconDef.fromFile('blank.svg');

	static fromFile(sr_file: string): IconDef {
		return IconDef.fromConfig({
			type: IconType.IMAGE,
			data: `/asset/${sr_file}`,
			iri: refFromFile(sr_file),
		});
	}

	static fromConfig(gc_icon: IconConfig): IconDef {
		return {
			class: ClassType.ICON,
			...gc_icon,
		};
	}

	static fromPair(sa_icon_a: string, sa_icon_b: string): IconDef {
		return {
			class: ClassType.ICON,
			type: IconType.PAIR,
			data: [sa_icon_a, sa_icon_b].join('\n'),
			iri: refFromFile(`pair/${sa_icon_a}/${sa_icon_b}`),
		};
	}

	static fromComposite(p_icon_bg: IconRef, p_icon_fg: IconRef): IconDef {
		return {
			class: ClassType.ICON,
			type: IconType.COMPOSITE,
			data: [p_icon_bg, p_icon_fg].join('\n'),
			iri: refFromFile(`composite/${p_icon_bg}/${p_icon_fg}`),
		};
	}
}


export class Icon extends Definable<IconDef> {
	static BLANK = new Icon(IconDef.BLANK);

	static refFromFile = refFromFile;

	static refFromUuid = refFromUuid;

	static none() {
		return {
			iri: 'wispr://root/icons/.none/',
			class: ClassType.ICON,
			path: '',
		};
	}

	// static fromPath(sx_path: string) {
	// 	return new Icon(IconDef.fromConfig({
	// 		type: IconType.,
	// 		data: sx_path,
	// 	}));
	// }

	static fromHtml(sx_html: string, g_attributes: Record<string, string>={}) {
		return new Icon(IconDef.fromConfig({
			type: IconType.HTML,
			iri: Icon.refFromUuid(),
			data: sx_html,
			extra: {
				attributes: g_attributes || {},
			},
		}));
	}

	protected _xc_type = IconType.BLANK;
	protected _sx_data = '';
	protected _g_extra: IconExtra;

	get isHtml(): boolean {
		return IconType.HTML === this._xc_type;
	}

	constructor(g_def: IconDef) {
		super(g_def);

		this._xc_type = g_def.type;
		this._sx_data = g_def.data;
		this._g_extra = g_def.extra || {};
	}

	render(): HTMLElement {
		switch(this._xc_type) {
			case IconType.IMAGE: {
				return dd('img', {
					src: this._sx_data,
				});
			}

			case IconType.HTML: {
				const dm_dummy = document.createElement('span');
				dm_dummy.innerHTML = this._sx_data;
				const dm_icon = dm_dummy.firstElementChild as HTMLElement;
				const h_attrs = this._g_extra?.attributes || {};
				for(const si_attr in h_attrs) {
					dm_icon.setAttribute(si_attr, h_attrs[si_attr]);
				}
				return dm_icon;
			}

			case IconType.PAIR: {
				const [sa_icon_a, sa_icon_b] = this._sx_data.split(/\n/g) as IconRef[];
				const k_icon_a = H_ICONS[H_ADDRESS_TO_TOKEN[sa_icon_a].def.iconRef];
				const k_icon_b = H_ICONS[H_ADDRESS_TO_TOKEN[sa_icon_b].def.iconRef];

				return dd('span', {
					class: 'group',
				}, [
					dd('span', {
						class: 'platform',
					}, [
						// dd('img', {
						// 	src: `/asset/token/secretswap.svg`,
						// }),
					]),

					dd('span', {
						class: 'pair',
					}, [
						k_icon_a.render(),
						k_icon_b.render(),
					]),
				]);

				// const dm_icon = dm_dummy.firstElementChild as HTMLElement;
				// const h_attrs = this._g_extra?.attributes || {};
				// for(const si_attr in h_attrs) {
				// 	dm_icon.setAttribute(si_attr, h_attrs[si_attr]);
				// }

				// return dm_pair;
			}

			case IconType.COMPOSITE: {
				const [p_icon_bg, p_icon_fg] = this._sx_data.split(/\n/g) as IconRef[];
				const k_icon_bg = H_ICONS[p_icon_bg];
				const k_icon_fg = H_ICONS[p_icon_fg];

				return dd('span', {
					class: 'group',
				}, [
					dd('span', {
						class: 'platform',
					}, [
						k_icon_bg.render(),
					]),

					dd('span', {
						class: 'foreground',
					}, [
						k_icon_fg.render(),
					]),
				]);
			}
		}

		return document.createElement('span');
	}

	toHtml() {
		switch(this._xc_type) {
			case IconType.IMAGE: {
				return `<img src="">`
			}
		}
	}
}

export namespace Icon {
	export type Ref = IconRef;

	export type Type = IconType;

	export type Def = IconDef;

	export type Config = IconConfig;

	export const Def = IconDef;

	export type Extra = IconExtra;

	export interface Refable<
		s_path extends string=string,
	> extends Tag.Refable<s_path> {
		iconRef: Ref;
	}
}
