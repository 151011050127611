<script lang="ts">
	import { yw_account, yw_chain } from "#/app";
	import { H_ADDR_TO_COLLECTION, H_NFTS } from "#/sim/data";

	import {
		Screen,
		Header,
	} from '#/ui';

	import GalleryCollection from "#/ui/GalleryCollection.svelte";
	import { ode, oderom } from "#/util/belt";

	function at_least_one_nft(sa_collection: string) {
		return Object.values(H_NFTS).some((k_nft) => {
			return $yw_account.address($yw_chain) === k_nft.def.owner;
		});
	}

	$: a_collections = ode(H_ADDR_TO_COLLECTION)
		.filter(([sa_collection, g_collection]) => g_collection.chainRef === $yw_chain.def.iri && at_least_one_nft(sa_collection));
</script>

<style lang="less">
	@import '../style/util.less';

	.gallery {
		display: flex;
		flex-direction: column;
	}
</style>

<Screen nav root
>
	<Header search network account
	>
		<svelte:fragment slot="title">

		</svelte:fragment>
	</Header>

	<div class="gallery">
		{#each a_collections as [sa_collection, g_collection]}
			<GalleryCollection title={g_collection.label} collectionAddress={sa_collection} />
		{/each}

		{#if !a_collections.length}
			<h3>No NFTs yet</h3>
		{/if}
	</div>

</Screen>
