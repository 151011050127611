import {
	type TagRef,
	type TagDef,
	type TagConfig,
	type TagRefable,
	Tag as TagClass,
	TagDef as TagDefClass,
} from './_core';

export class Tag extends TagClass {}

export namespace Tag {
	export type Ref = TagRef;
	export type Def = TagDef;
	export const Def = TagDefClass;
	export type Config = TagConfig;
	export type Refable<s_path extends string> = TagRefable<s_path>;
}
