<script lang="ts">
	import { push_ref, push_screen, yw_account, yw_chain, yw_family } from "#/app";

	import {
		Tabs,
		Tab,
		TabList,
		TabPanel,
	} from 'svelte-tabs';

	import {
		Screen,
		Header,
		ContactList,
		SubHeader,
	} from "#/ui";

	import SX_ADD from '#/asset/action/add-small.svg?raw';
import { Contact } from "#/objects/contact";
import { ode, oderac, oderaf } from "#/util/belt";
import { H_CHAINS, H_TOKENS } from "#/sim/data";
import type { Addressable } from "#/objects/_core";
import ContactEdit from "./ContactEdit.svelte";

	$: a_tokens = oderaf(H_TOKENS, (p_token, k_token) => {
		if(!k_token.def.native && k_token.def.chainRef === $yw_chain.def.iri) {
			const gd_contact = Contact.Def.fromConfig({
				label: k_token.def.symbol,
				pubkey: k_token.def.address.replace(/^\w+1/, ''),
				familyRef: $yw_chain.def.familyRef,
				type: Contact.Type.CONTRACT,
				iconRef: k_token.def.iconRef,
				tagRefs: k_token.def.tagRefs,
				locked: true,
			});

			return [new Contact(gd_contact)];
		}

		return [];
	}) as Addressable[];

</script>

<style lang="less">
	@import '../style/util.less';


	.screen.contacts div.svelte-tabs>div.svelte-tabs__tab-panel {
		:global(&) {
			margin-top: -1px;
		}
	}
</style>

<Screen nav root classNames='contacts'>
	<Header search network account
	>
	</Header>

	<SubHeader title="Contacts"
		on:add_new={() => push_screen(ContactEdit)}
		buttons={['Export']}
	/>
	
	<Tabs>
		<TabList>
			<Tab>
				All
			</Tab>

			<Tab>
				Humans
			</Tab>

			<Tab>
				Contracts
			</Tab>
		</TabList>


		<!-- All -->
		<TabPanel>
			<ContactList />
		</TabPanel>


		<!-- Humans -->
		<TabPanel>
			<ContactList filter={(k_contact) => {
				return Contact.Type.PERSON === k_contact.def.type
			}} />
		</TabPanel>

		
		<!-- Contracts -->
		<TabPanel>
			<ContactList
				filter={(k_contact) => {
					return Contact.Type.CONTRACT === k_contact.def.type
				}}
			/>
		</TabPanel>

	</Tabs>

</Screen>