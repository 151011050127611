import {
	type IntraChain,
	ClassType,
	Definable,
} from './_core';

import type {
	WisprResUri,
} from '#/state/path';

import type { Chain } from './chain';
import type { Icon } from './icon';
import type { Tag } from './tag';
import BigNumber from 'bignumber.js';
import type { Ibct } from './ibct';
import type { Type } from 'ts-toolbelt/out/Any/Type';
import type { Hash } from 'crypto';
import type { JsonObject, JsonValue } from '#/util/types';


type NftRef = Chain.MemberRef<ClassType.SNIP721>;

type MetadataStruct = Partial<{
	name: string;
	description: string;
	image: string;
	image_data: string;
	external_url: string;
	background_color: string;
	animatino_url: string;
	youtube_url: string;
	attributes: JsonObject[];
	media: JsonObject[];
	protected_attirbutes: string[];
}>;

interface NftDef extends Chain.Asset<`snip721s/${string}`> {
	class: ClassType.SNIP721,

	id: string;
	autoLabel: string;
	image: string;
	symbol: string;
	owner: string;
	extension: JsonValue;
}

interface NftConfig {
	id: string;
	label: string;
	autoLabel: string;
	image: string;
	owner: string;
	address: string,
	symbol?: string;
	extension?: JsonValue;
	chainRef: Chain.Ref;
	iconRef: Icon.Ref;
	tagRefs?: Tag.Ref[];
}

export interface NftParam extends Chain.Param {
	tokenId: string;
}

class NftDef implements Icon.Refable {
	static fromConfig(gc_nft: NftConfig): NftDef {
		return {
			...gc_nft,
			symbol: gc_nft.symbol || '',
			extension: gc_nft.extension || {},
			tagRefs: gc_nft.tagRefs || [],
			iri: Nft.refFromChainOwnerAddrId(gc_nft.chainRef, gc_nft.owner, gc_nft.address, gc_nft.id),
			class: ClassType.SNIP721,
		};
	}
}


export class Nft extends Definable<NftDef> {
	static refFromChainOwnerAddrId(p_chain: Chain.Ref, sa_owner: string, si_addr: string, s_id: string): NftRef {
		return `${p_chain}snip721s/${si_addr}/${s_id}/${sa_owner}/` as NftRef;
	}

	// static parseRef(sr_ref: NftRef) {
	// 	const {
	// 		chainId: si_chain,
	// 		rest: sr_path,
	// 	} = Chain.Def.parseRef(sr_ref);

	// 	const a_parsed = parse_path_pair(sr_path, 'tokens');
	
	// 	return {
	// 		chainId: si_chain,
	// 		tokenId: a_parsed[0],
	// 		rest: a_parsed[1],
	// 	};
	// }
}

export namespace Nft {
	export type Ref = NftRef;
	export type Def = NftDef;
	export const Def = NftDef;
	export type Config = NftConfig;
	export type Param = NftParam;
}
