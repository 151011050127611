<script lang="ts">
	import { yw_account, yw_asset_send, yw_chain } from "#/app";
	import { format_fiat, H_ACCOUNTS, H_HOLDINGS, H_TOKENS, H_VERSUS_USD } from "#/sim/data";

	import StarSelect from "./StarSelect.svelte";
	import type { Account } from "#/objects/account";

	export let account: Account = $yw_account;

	// $: a_holdings = Object.values(H_HOLDINGS)
	// 	.filter(k => k.def.holderAddr === account.address($yw_chain));

	const mk_account = (k_account: Account) => {
		return {
			value: k_account.def.id,
			primary: k_account.def.label,
			secondary: format_fiat(k_account.holdings(H_HOLDINGS, $yw_chain)
				.reduce((c_sum, k_holding) => c_sum + k_holding.toUsd(H_TOKENS, H_VERSUS_USD), 0)),
			// account
			// 	? $yw_asset_send
			// 		? H_HOLDINGS[$yw_asset_send.def.iri]
			// 		: s_usd_total
			// 	: s_usd_total,
		};
	};

	$: a_accounts = Object.values(H_ACCOUNTS).filter(k => '*' !== k.def.id).map((mk_account));

	// let k_account = a_accounts.find(g => g.value === account.def.id) || a_accounts[0]
	let k_account = mk_account(account);

	// $: s_usd_total = format_fiat(account.holdings(H_HOLDINGS, $yw_chain)
	// 			.reduce((c_sum, k_holding) => c_sum + k_holding.toUsd(H_TOKENS, H_VERSUS_USD), 0));
</script>


<style lang="less">
	@import '../style/util.less';
</style>


<div class="sender">
	<StarSelect id="sender-select"
		placeholder="Select account"
		secondaryClass='balance'
		items={a_accounts}
		bind:value={k_account}
	/>
</div>