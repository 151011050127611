import type {
	SvelteComponent,
} from 'svelte';

import {
	type Writable,
	derived,
	writable,
} from 'svelte/store';

import {
	State,
	StateThread,
	StateManager,
	writableSync,
	derivedSync,
	type NewStateConfig,
} from '#/state/manager';

import type {
	WisprRef,
	WisprUri,
} from '#/state/path';

import {
	ResourceType,
	type WisprObject,
	type WisprScreen,
	type ScreenResource,
	type ObjectResource,
	Router,
	type Resolution,
} from '#/state/router';

import Contacts from '#/screen/Contacts.svelte';
import ContactView from '#/screen/ContactView.svelte';
import ContactEdit from '#/screen/ContactEdit.svelte';

import Chains from '#/screen/Networks.svelte';
import ChainView from '#/screen/ChainView.svelte';
import ChainEdit from '#/screen/ChainEdit.svelte';

import Init from '#/screen/Init.svelte';
import InitCreate from '#/screen/InitCreate.svelte';
import InitCreateVerify from '#/screen/InitCreateVerify.svelte';
import InitCreateEdit from '#/screen/InitCreateEdit.svelte';
import Locked from '#/screen/Locked.svelte';
import Welcome from '#/screen/Welcome.svelte';
import Holdings from '#/screen/Holdings.svelte';
import TokenHoldingView from '#/screen/TokenHoldingView.svelte';
import Search from '#/screen/Search.svelte';
import Send from '#/screen/Send.svelte';
import Execute from '#/screen/Execute.svelte';
import Accounts from '#/screen/Accounts.svelte';
import Networks from '#/screen/Networks.svelte';
import NetworkEdit from '#/screen/NetworkEdit.svelte';
import TokenEdit from '#/screen/TokenEdit.svelte';
import Gallery from '#/screen/Gallery.svelte';
import History from '#/screen/History.svelte';
import NftView from '#/screen/NftView.svelte';
import AccountCreate from '#/screen/AccountCreate.svelte';
import AccountEdit from '#/screen/AccountEdit.svelte';
import AccountView from '#/screen/AccountView.svelte';
import Sites from '#/screen/Sites.svelte';
import DeadEnd from '#/screen/DeadEnd.svelte';
import TxnView from './screen/TxnView.svelte';

import type { Hash } from '#/util/types';


import { ode, oderom } from './util/belt';
import type { WisprPath } from './state/path';
import { Account, Chain, Holding, Network, Token } from './objects';
import { H_CHAINS, H_FAMILIES, H_HOLDINGS, H_TOKENS, K_DEFAULT_ACCOUNT, type SearchItem } from './sim/data';
import type Fuse from 'fuse.js';
import type { Searchable } from './objects/_core';

import Mvp_1 from './screen/Mvp_1.svelte';
import Mvp_2 from './screen/Mvp_2.svelte';
import Mvp_3 from './screen/Mvp_3.svelte';
import Mvp_NoSvelte from './screen/Mvp_No.svelte';

export const XP_APP_WIDTH = 360;
export const XP_APP_HEIGHT = 640;

export enum ThreadId {
	DEFAULT='default',
	SEARCH='search',
	INIT='init',
	TOKENS='tokens',
	NFTS='nfts',
	CONTACTS='contacts',
	HISTORY='history',
	NETWORKS='networks',
	ACCOUNTS='accounts',
	TAGS='tags',
	SITES='sites',
}

export const H_THREADS = {
	[ThreadId.DEFAULT]: Locked,
	[ThreadId.SEARCH]: Search,
	[ThreadId.TOKENS]: Holdings,
	[ThreadId.INIT]: Welcome,
	[ThreadId.NFTS]: Gallery,
	[ThreadId.CONTACTS]: Contacts,
	[ThreadId.HISTORY]: History,
	[ThreadId.NETWORKS]: Networks,
	[ThreadId.ACCOUNTS]: Accounts,
	// [ThreadId.Tags]: Tags,
	[ThreadId.SITES]: Sites,
} as const;

export const yw_screen_dom = writableSync<HTMLElement>(null!);

export const yw_path = writableSync('');

export const yw_pattern = writableSync('');

export const yw_thread_id = writableSync(ThreadId.DEFAULT)

export const yw_notifications = writableSync<Array<string | ThreadId>>([]);

export const yw_nav_collapsed = writable(false);

export const yw_nav_visible = writableSync(false);

export const yw_progress = writableSync([0, 0] as [number, number]);

export const yw_uri = derivedSync(yw_path, $yw => `wispr://root/${$yw}`);

export const yw_path_parts = derivedSync(yw_path, $yw => ($yw as string).split('/'));

export const yw_family = writableSync(Object.values(H_FAMILIES)[0]);

export const yw_chain = writableSync(Object.values(H_CHAINS)[0]);

export const yw_search = writable('');

export const yw_cancel_search = writableSync<VoidFunction>(() => {});

export const yw_fuse = writable<Fuse<SearchItem>>();

export const yw_asset_send = writableSync<Token | null>(null);

export const yw_account = writableSync(K_DEFAULT_ACCOUNT);

export const yw_params = writableSync({
	familyId: yw_family.get()?.def.id || '.default',
	chainId: yw_chain.get()?.def.id || '*',
	accountId: yw_account.get().def.id,
});

export const yw_task = writableSync(0);

// export const yw_params = derivedSync([yw_family, yw_chain, yw_account], ([$yw_fam, $yw_chn, $yw_acc]) => ({
// 	familyId: $yw_fam?.def.id || '.default',
// 	chainId: $yw_chn?.def.id || '*',
// 	accountId: $yw_acc.def.id,
// }));

export const yw_help = writableSync([] as HTMLElement[]);

export const yw_header_props = writableSync({} as Hash<any>);

export const yw_exitting_dom = writableSync<HTMLElement>(null!);

export const yw_menu_expanded = writableSync(false);

export const yw_vendor_menu = writableSync(false);

export const yw_overscroll_pct = writableSync(0);

export const yw_popup = writableSync<SvelteComponent | null>(null);

export const yw_popup_account = writableSync<Account | null>(null);

export const yw_popup_context = writableSync<Hash<any> | null>(null);

export const yw_blur = writableSync(false);
export const yw_overlay_account = writableSync(false);
export const yw_overlay_network = writableSync(false);

export const yw_holding_send = derived([yw_asset_send, yw_account, yw_chain], ([$yw_asset, $yw_acc, $yw_ch]) => {
	if($yw_asset && $yw_acc) {
		const p_token = $yw_asset.def.iri;
		const sa_holder = $yw_acc.address($yw_ch);
		const p_holding = Holding.refFromTokenAccount(p_token, sa_holder);
		return H_HOLDINGS[p_holding];
	}

	return null;
});


export const hm_arrivals: WeakMap<HTMLElement, VoidFunction> = new Map();
export function arrival(dm_screen: HTMLElement, fk_arrive: VoidFunction) {
	hm_arrivals.set(dm_screen, fk_arrive);
}

export const K_ROUTER = Router.parse({
	'=': () => '/mvp/1',

	'/locked': {
		$: Locked,

		// '/unlocked': {
		// 	'=': () => '/chains/.default/holdings/.default',
		// },
	},

	'/mvp': {
		'/1':{
			$: Mvp_1,
		},

		'/2':{
			$: Mvp_2,
		},

		'/3':{
			$: Mvp_3,
		},

		'/no': {
			$: Mvp_NoSvelte,
		}
	},

	'/dead-end': {
		$: DeadEnd,
	},

	'/search': {
		$: Search,
	},

	'/welcome': {
		$: Welcome,
	},

	'/init': {
		$: Init,

		'/create': {
			$: InitCreate,

			'/verify': {
				$: InitCreateVerify,
			},

			'/edit': {
				$: InitCreateEdit,
			},
		},
	},

	'/networks': {
		$: Networks,

		// '/view': {
		// 	$: ChainView,
		// },

		'/edit': {
			$: NetworkEdit,
		},
	},

	'/families': {

		'/{familyId}': {

			'/contacts': {
				$: Contacts,

				'/{contactId}': {
					// _: Contact,

					'/view': {
						$: ContactView,
					},
					'/edit': {
						$: ContactEdit,
					},
				},
			},

			'/chains': {
				// $: Chains,

				'/{chainId}': {
					// _: Chain,

					'/view': {
						$: ChainView,
					},
					'/edit': {
						$: ChainEdit,
					},

					'/holdings': {
						// '?'(h_params: Hash) {
						// 	if('.default' === h_params.accountId) {
						// 		return `/chains/${h_params.chainId}/holdings/${Object.values(H_ACCOUNTS)[0].def.id}`;
						// 	}
						// },

						'/{accountId}': {
							$: Holdings,
						},
					},

					'/tokens?account=*': {
						// $: 

						'/{tokenId}': {
							// _: Token,

							'/holdings': {
								'/{accountId}': {
									// _: Holding,

									'/view': {
										$: TokenHoldingView,
									},
								},
							},

							// '/view?account=*': {
							// 	$: TokenView,
							// },

							'/edit?account': {
								$: TokenEdit,
							}
						},
					},

					'/gallery': {
						'/{accountId}': {
							$: Gallery,
						},
					},

					'/nfts?account=*': {
						'/{minterId}': {
							'/{tokenId}': {
								'/{accountId}': {
									'/view': {
										$: NftView,
									},
								},
							},
						},
					},

					'/txns': {
						'/view': {
							$: TxnView,
						},
					},
				}
			},
		},
	},

	'/accounts': {
		$: Accounts,

		'/create': {
			$: AccountCreate,
		},
		
		'/{accountId}': {
			// _: Account,

			'/view': {
				$: AccountView,
			},
			'/edit': {
				$: AccountEdit,
			}
		},
	},

	'/sites': {
		$: Sites,
	},

	'/send': {
		$: Send,
	},

	'/execute': {
		$: Execute,
	},

	'/history': {
		$: History,
	},
});


export function resolve(sr_ref: WisprRef): Resolution {
	// base iri
	const p_base = yw_uri.get() as WisprUri;

	// attempt
	const g_attempt = K_ROUTER.resolve(sr_ref, p_base);

	// failed
	if(!g_attempt) {
		throw new Error(`Failed to resolve ref: <${sr_ref}> using base IRI <${p_base}>`);
	}
	
	return g_attempt;
}

export function resolve_to_screen(sr_ref: WisprRef): Resolution<ScreenResource> {
	const g_resolved = resolve(sr_ref);

	// not a screen
	if(ResourceType.SCREEN !== g_resolved.resource.type) {
		throw new Error(`Refusing to resolve non-screen resource type <${g_resolved.path}>`);
	}
	else {
		return g_resolved as Resolution<ScreenResource>;
	}
}

function new_state_from_ref(sr_ref: WisprRef): NewStateConfig {
	// resolve ref
	const {
		path: sr_path,
		pattern: sx_pattern,
		resource: {screen: dc_screen},
		params: h_params,
	} = resolve_to_screen(sr_ref);

	// update stores
	yw_path.set(sr_path);
	yw_pattern.set(sx_pattern)

	// return new state config
	return {
		path: () => sr_path,
		pattern: sx_pattern,
		props: h_params,
		screen: dc_screen,
	};
}

export function push_ref(sr_ref: WisprRef): State {
	// push and return state
	return K_STATE_MANAGER.push(new_state_from_ref(sr_ref));
}

function new_state_from_screen<
	Params extends {},
	Events extends {},
	Slots extends {},
	Screen extends WisprScreen<Params, Events, Slots>,
>(dc_screen: Screen, h_params?: Params, h_events?: Events, h_slots?: Slots): NewStateConfig {
	// locate router node corresponding to screen
	const k_node = K_ROUTER.lookup_screen(dc_screen as unknown as WisprScreen);
	
	// fetch its pattern
	const sx_pattern = k_node.path_pattern as WisprPath;

	// normalize props
	const gc_props = {
		...yw_params.get(),
		...h_params,
	};

	// push and return state
	return {
		path: (yc_component: SvelteComponent) => {
			// reverse compute props from component instance
			const h_props: Hash = {};
			let w_node = yc_component;
			while(Object !== w_node.constructor) {
				for(const si_key of Object.getOwnPropertyNames(w_node)) {
					if('$' !== si_key[0] && si_key.endsWith('Id') && 'string' === typeof yc_component[si_key] && !(si_key in h_props)) {
						h_props[si_key] = yc_component[si_key];
					}
				}
				w_node = Object.getPrototypeOf(w_node);
			}

			const gc_params = {
				...gc_props,
				...h_props,
			};

			// resolve to path
			const sr_path = k_node.reverse_resolve(gc_params);

			// update stores
			yw_path.set(sr_path);
			yw_pattern.set(sx_pattern);

			return {
				path: sr_path,
				params: gc_params,
			};
		},
		pattern: sx_pattern,
		props: gc_props,
		screen: dc_screen as unknown as WisprScreen,
	};
}

window.onpopstate = function(d_event) {
	pop(true);
};

export function push_screen<
	Params extends {},
	Events extends {},
	Slots extends {},
	Screen extends WisprScreen<Params, Events, Slots>,
>(dc_screen: Screen, h_params?: Params, h_events?: Events, h_slots?: Slots) {
	history.pushState(null, '', location.href);

	return K_STATE_MANAGER.push(new_state_from_screen(dc_screen, h_params, h_events, h_slots));
}

export function pop(b_bypass_animation=false) {
	return K_STATE_MANAGER.pop(b_bypass_animation);
}

export function restart() {
	return K_STATE_MANAGER.restart();
}

export function goto_ref(sr_ref: WisprRef): State {
	// goto and return state
	return K_STATE_MANAGER.goto(new_state_from_ref(sr_ref));
}

export function goto_screen<
	Params extends {},
	Events extends {},
	Slots extends {},
	Screen extends WisprScreen<Params, Events, Slots>,
>(dc_screen: Screen, h_params?: Params, h_events?: Events, h_slots?: Slots): State {
	return K_STATE_MANAGER.goto(new_state_from_screen(dc_screen, h_params, h_events, h_slots));
}

export function peak(): undefined | State {
	// ref current thread history
	const a_history = K_STATE_MANAGER.history

	// previous item in thread
	return a_history[1];
}

let K_STATE_MANAGER!: StateManager;


// subscribe to account changes
let k_account_prev: Account | null = null;
yw_account.subscribe((k_account) => {
	if(k_account !== k_account_prev && k_account_prev) {
		if(K_STATE_MANAGER) {
			setTimeout(() => {
				K_STATE_MANAGER.reset_all_threads();
			}, 0);
		}
	}

	k_account_prev = k_account;
});

// subscribe to network changes
let k_chain_prev: Chain | null = null;
yw_chain.subscribe((k_chain) => {
	if(k_chain !== k_chain_prev && k_chain_prev) {
		if(K_STATE_MANAGER) {
			setTimeout(() => {
				K_STATE_MANAGER.reset_all_threads();
			}, 0);
		}
	}

	k_chain_prev = k_chain;
});

export function reset_all() {
	K_STATE_MANAGER.reset_all_threads();
}


// subscribe to thread changes
yw_thread_id.subscribe((si_thread) => {
	// notify state manager
	if(K_STATE_MANAGER) {
		const b_altered = K_STATE_MANAGER.alter_thread(si_thread, yw_params.get());

		if(b_altered) {
			const {
				path: sr_path,
				pattern: sx_pattern,
			} = K_STATE_MANAGER.state;

			yw_path.set(sr_path);
			yw_pattern.set(sx_pattern);
		}
	}
});

export function initialize(k_manager: StateManager) {
	K_STATE_MANAGER = k_manager;
}


