<script lang="ts">
	import type { Contact } from "#/objects";
	import InlineContactSelection from "./InlineContactSelection.svelte";

	export let getSelectionLabel;
	export let item: {
		value: string;
		contact: Contact;
	};
</script>

<style lang="less">
	.selection {
		text-overflow: ellipsis;
		overflow-x: hidden;
		white-space: nowrap;
		height: 100%;
		padding-left: 8px;

		.manual>.address {
			:global(&) {
				width: calc(100% - 3.5ch);
			}
		}
	}
</style>


<div class="selection">
	<InlineContactSelection contact={item.contact} address={item.value} />
</div>