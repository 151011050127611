<script lang="ts">
	import { F_NOOP, timeout } from "#/util/belt";

	import {
goto_screen,
		push_screen,
		ThreadId,
		yw_menu_expanded, yw_thread_id,
	} from "#/app";

	import {
		Icon,
	} from "#/objects";

	import {
		Put,
	 } from '#/ui';

	import SX_CONTACTS from '@material-design-icons/svg/outlined/supervisor_account.svg?raw';
	import SX_CHAINS from '@material-design-icons/svg/outlined/mediation.svg?raw';
	import SX_ACCOUNTS from '@material-design-icons/svg/outlined/account_circle.svg?raw';
	import SX_TAGS from '@material-design-icons/svg/outlined/bookmarks.svg?raw';
	import SX_CONNECTIONS from '@material-design-icons/svg/outlined/account_tree.svg?raw';
	import SX_SETTINGS from '@material-design-icons/svg/outlined/settings.svg?raw';
	import SX_LOGOUT from '@material-design-icons/svg/outlined/sensor_door.svg?raw';
	import SX_CLOSE from '@material-design-icons/svg/outlined/close.svg?raw';
import DeadEnd from "#/screen/DeadEnd.svelte";

	// $yw_menu_expanded

	interface Item {
		click: VoidFunction;
		label: string;
		icon: Icon;
	}

	const A_ITEMS = [
		{
			label: 'Contacts',
			icon: Icon.fromHtml(SX_CONTACTS),
			click() {
				$yw_menu_expanded = false;
				$yw_thread_id = ThreadId.CONTACTS;
			},
		},
		{
			label: 'Accounts',
			icon: Icon.fromHtml(SX_ACCOUNTS),
			click() {
				$yw_menu_expanded = false;
				$yw_thread_id = ThreadId.ACCOUNTS;
			},
		},
		{
			label: 'Networks',
			icon: Icon.fromHtml(SX_CHAINS),
			click() {
				$yw_menu_expanded = false;
				$yw_thread_id = ThreadId.NETWORKS;
			},
		},
		{
			label: 'Tags',
			icon: Icon.fromHtml(SX_TAGS),
			async click() {
				$yw_menu_expanded = false;
				push_screen(DeadEnd);
			},
		},
		{
			label: 'Sites',
			icon: Icon.fromHtml(SX_CONNECTIONS),
			click() {
				$yw_menu_expanded = false
				$yw_thread_id = ThreadId.SITES;
			},
		},
		{
			label: 'Settings',
			icon: Icon.fromHtml(SX_SETTINGS),
			click: () => {
				$yw_menu_expanded = false;
				push_screen(DeadEnd);
			},
		},
	];

	const A_SESSION_ITEMS = [
		{
			label: 'Log out',
			icon: Icon.fromHtml(SX_LOGOUT),
			click: F_NOOP,
		},
	];
</script>

<style lang="less">
	@import '../style/util.less';

	:root {
		--bar-width: 78.8%;
		--animation-duration: 1s;
		--animation-easing: var(--ease-out-quick);
	}

	@keyframes fade {
		0% {
			background-color: transparent;
		}

		100% {
			background-color: rgba(0, 0, 0, 0.8);
		}
	}

	@keyframes slide {
		0% {
			right: calc(0% - var(--bar-width));
		}

		100% {
			right: 0%;
		}
	}

	@keyframes offscreen {
		0% {
			top: 0;
		}

		100% {
			top: var(--app-window-height);
		}
	}

	.absolute(@dims: '') {
		position: absolute;
		top: 0;
		left: 0;

		width: if('' = @dims, var(--app-window-width), @dims);
		height: if('' = @dims, var(--app-window-height), @dims);
	}

	.side-menu {
		--item-padding: 30px;

		.absolute();
		.font(regular);
		z-index: 1001;
		user-select: none;

		>.backdrop {
			.absolute(100%);
			background-color: rgba(0, 0, 0, 0.8);
			transition: background-color var(--animation-duration) var(--ease-out-expo);
		}

		>.bar {
			position: absolute;
			top: 0;
			width: var(--bar-width);
			height: 100%;
			background-color: var(--theme-color-bg);
			right: 0%;
			transition: right var(--animation-duration) var(--animation-easing);

			>.menu {
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				height: 100%;

				ul {
					margin: 0;
					padding: 0;
					display: flex;
					flex-direction: column-reverse;

					>li {
						list-style: none;
						padding: 13px 0;
						padding-left: var(--item-padding);
						cursor: pointer;

						>* {
							vertical-align: middle;
						}

						>.icon {
							--icon-diameter: 24px;
							padding: 0;
							padding-right: calc(var(--item-padding) / 2);
						}
					}

					&.items {
						.icon {
							--icon-color: var(--theme-color-primary);
						}
					}

					&.session {
						padding: calc(var(--item-padding) / 2) 0;

						.icon {
							--icon-color: var(--theme-color-text-med);
						}
					}
				}

				>.main {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					padding-top: 15%;
					padding-bottom: 15%;
				}

				>.bottom,>.top {
					flex: 0;
				}

				>.top {
					padding-top: 15%;
				}
			}
		}

		&.collapsed {
			pointer-events: none;
			top: 0;
			animation: offscreen var(--animation-duration) steps(2, jump-none) both;
			
			>.backdrop {
				background-color: rgba(0, 0, 0, 0);
			}

			>.bar {
				right: calc(0% - var(--bar-width));
			}
		}


		hr {
			margin: 0 var(--item-padding);
			border: none;
			border-top: 1px solid var(--theme-color-border);
		}

		.close {
			position: absolute;
			top: 0;
			right: 0;
			margin: 10px;
			padding: 12px;
			cursor: pointer;
			--icon-diameter: 24px;
			--icon-color: var(--theme-color-primary);

			outline: 1px solid var(--theme-color-border);
			border-radius: 0px;
			transition: border-radius 650ms var(--ease-out-expo);
			pointer-events: all;

			&::before {
				--occlusion-thickness: 4px;

				content: '';
				position: absolute;
				top: calc(var(--occlusion-thickness) / 2);
				left: calc(var(--occlusion-thickness) / 2);
				width: calc(100% - var(--occlusion-thickness));
				height: calc(100% - var(--occlusion-thickness));
				outline: var(--occlusion-thickness) solid var(--theme-color-bg);
				box-sizing: border-box;
				pointer-events: none;
			}

			&:hover {
				border-radius: 22px;
			}
		}
	}
</style>

<div
	class="side-menu"
	class:collapsed={!$yw_menu_expanded}
>
	<div class="backdrop clickable"
		on:click={() => $yw_menu_expanded = false}
	/>

	<div class="bar">
		<div class="close icon clickable" on:click={() => $yw_menu_expanded = false}>
			{@html SX_CLOSE}
		</div>

		<div class="menu">
			<div class="top">
				<ul class="session">
					{#each A_SESSION_ITEMS as g_item}
						<li>
							<span class="icon">
								<Put element={g_item.icon.render()} />
							</span>
							<span class="text">
								{g_item.label}
							</span>
						</li>
					{/each}
				</ul>

				<hr>
			</div>

			<div class="main">
				<ul class="items">
					{#each A_ITEMS as g_item}
						<li class="clickable" on:click={() => g_item.click()}>
							<span class="icon">
								<Put element={g_item.icon.render()} />
							</span>
							<span class="text">
								{g_item.label}
							</span>
						</li>
					{/each}
				</ul>
			</div>
		</div>
	</div>
</div>