<script lang="ts">
	import {
		Screen,
		Field,
		Info,
		Row,
		Address,
	} from "#/ui";

	import type {
		Account,
	} from '#/objects';

	import Header from "#/ui/Header.svelte";
	import { ode } from "#/util/belt";
	import { H_ACCOUNTS } from "#/sim/data";
	import { push_screen, yw_chain } from "#/app";
	import SubHeader from "#/ui/SubHeader.svelte";
	import DeadEnd from "./DeadEnd.svelte";
	import AccountEdit from "./AccountEdit.svelte";

	export let account: Account;

</script>

<style lang="less">
	@import '../style/util.less';

	.hd-path {
		:global(&) {
			.font(tiny);
			color: var(--theme-color-text-med);
		}
	}
</style>

<Screen nav slides>
	<Header pops
		name="Add account"
	>
	</Header>

	<hr>

	<p>
		Create a new account, import an existing one, or connect to a hardwarware wallet.
	</p>

	<div class="action-wall">
		<button class="primary" on:click={() => push_screen(AccountEdit)}>
			Create new StarSehll account
		</button>

		<button on:click={() => push_screen(DeadEnd)}>
			Import with private key
		</button>

		<button on:click={() => push_screen(DeadEnd)}>
			Connect to hardware
		</button>
	</div>
</Screen>