<script lang="ts">
	import { yw_popup, yw_popup_context } from "#/app";

	import type { Tag } from "#/objects";

	import { H_TAGS } from "#/sim/data";
	import { ode, oderaf, oderom } from "#/util/belt";
import CheckboxField from "./CheckboxField.svelte";

	import Row from "./Row.svelte";
import SubHeader from "./SubHeader.svelte";
	import Tags from "./Tags.svelte";

	let h_tags_sel = oderom(H_TAGS, (p_tag, k_tag) => ({
		[p_tag]: {
			object: k_tag,
			selected: ($yw_popup_context!.tags as Tag[]).includes(k_tag),
		},
	}));

	// ($yw_popup_context!.tags as Tag[]);

	function select_tag(k_tag: Tag) {
		$yw_popup_context!.tags = [...$yw_popup_context!.tags, k_tag];
		$yw_popup = null;
	}

	function toggle_tag(p_tag: Tag.Ref) {
		h_tags_sel[p_tag].selected = !h_tags_sel[p_tag].selected;
		h_tags_sel = h_tags_sel;
	}

	function apply_tags() {
		$yw_popup_context!.tags = oderaf(h_tags_sel, (p_tag, g_tag) => {
			if(g_tag.selected) {
				return [g_tag.object];
			}

			return [];
		});

		$yw_popup = null
	}
</script>

<style lang="less">
	@import '../style/util.less';


	.rows {
		margin-left: calc(0px - var(--ui-padding));
		margin-right: calc(0px - var(--ui-padding));
		margin-top: 1em;
		margin-bottom: 1em;
	}

	.float {
		--anti-padding: calc(0px - var(--ui-padding));
		position: sticky;
		width: 100%;
		background-color: var(--theme-color-bg);
		margin-left: var(--anti-padding);
		margin-right: var(--anti-padding);
		padding-left: var(--ui-padding);
		padding-right: var(--ui-padding);
		padding-top: var(--ui-padding);
		bottom: var(--anti-padding);
		background: linear-gradient(0deg, var(--theme-color-bg) 0%, var(--theme-color-bg) 80%, transparent);
	}
</style>

<section class="screen">
	<SubHeader title="Select Tag(s)" bare>
	</SubHeader>

	<div class="rows">
		{#each ode(h_tags_sel) as [p_tag, {object:k_tag, selected:b_selected}]}
			<Row
				rootStyle="--app-icon-diameter:12px;"
				iconClass='align-self_center'
				name={k_tag.def.label}
				on:click={() => toggle_tag(k_tag.def.iri)}
			>
				<Tags collapsed tags={[k_tag]} slot="icon" />

				<svelte:fragment slot="right">
					<CheckboxField id="tag-${k_tag.def.color}" bind:checked={b_selected} disableHandler />
				</svelte:fragment>
			</Row>
		{/each}
	</div>

	<div class="float">
		<div class="action-line">
			<button class="primary" on:click={() => apply_tags()}>
				Apply
			</button>
		</div>
	</div>
</section>
