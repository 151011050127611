<script lang="ts">
import { pop, push_screen } from "#/app";

	import Screen from "#/ui/Screen.svelte";

	

	let n_seconds = 10;
	let s_countdown = '(10s)';

	setInterval(() => {
		n_seconds -= 1;
		s_countdown = `(${n_seconds}s)`;
	}, 1000);
</script>

<style lang="less">
	@import '../style/util.less';

	.thread>section.screen.mvp_no {
		:global(&) {
			align-items: center;
			justify-content: center;
			text-align: center;
			gap: 20px;
			padding-left: 16px;
			padding-right: 16px;
			padding-top: 50%;
			background-image: url('/asset/vendor/space.png');
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	.logo {

	}

	.large {
		.font(big);
	}

	p {
		.font(regular);
		padding: 8px 0;
	}

	.line {
		width: calc(100% - 40px);
		height: 1px;
		background: radial-gradient(50% 50% at 50% 50%, #FFC700 0%, rgba(255, 199, 0, 0) 100%);
	}

	.action-line {
		width: 100%;
	}

	.timer {
		position: absolute;
		margin-left: 6px;
	}
</style>

<Screen classNames='mvp_no'>
	<div class="logo">
		<img width=66 src="/asset/vendor/logo-2x-dark.png" />
	</div>

	<p>
		Ok, no worries.
	</p>

	<p>
		Thanks for stopping by!
	</p>


	<div class="action-line">
		<button on:click={() => pop()}>
			Back
		</button>
	</div>
</Screen>